.footer {
  position: relative;
  background-color: var(--color-1);
  margin-top: clamp(10rem, 15vw, 15rem);
  margin-left: var(--header-width);
  color: var(--white);
  font-size: rem(25);
  padding: 0 1rem 0 1rem;
  &::before {
    opacity: 1;
  }
}

.footer__container {
  display: grid;
  grid-template-columns:
    minmax(300px, 350px) minmax(170px, 225px) minmax(200px, 250px)
    minmax(200px, 300px);
  grid-auto-flow: dense;
  gap: 2rem;
  align-items: stretch;
}

// --------------------------------------------------------- items
.footer__item {
  i {
    color: var(--color-1);
  }
}

// --------------------------------------------------------- title
.footer__title {
  display: block;
  font-size: 1.6rem;
  font-family: var(--font-titre);
}

// --------------------------------------------------------- logo
.footer__item--logo {
  img {
    display: block;
    margin: 1rem auto;
  }
  p {
    padding-right: 1rem;
  }

  .join__signature {
    margin-top: 1rem;
    text-align: center;
    span {
      display: block;
    }
  }

  a.btn {
    font-size: 1.2rem;
    color: var(--white);
    border-color: var(--white);
  }
}

//================================================================================== NAVIGATION
.footer__item--menu {
  //------------------------------------------------------ li
  li {
    // margin-left: rem(10);
  }

  //------------------------------------------------------ a
  a {
    display: block;
    font-size: 1rem;
    // text-transform: uppercase;
    // line-height: 1.2;
    transition: all 200ms;
    // padding: 2px 0;
  }
}

//================================================================================== SOCIAL

.footer__item--social {
  .item__social {
    margin-bottom: 2rem;
  }

  li {
    display: inline-flex;
    margin-right: 5px;
  }
  i {
    font-size: rem(45);
    color: var(--white);
  }
}

//================================================================================== A PROPOS

.footer__item--apropos {
  p {
    font-size: 0.8rem;
  }
}

//================================================================================== REVIEWS

.footer__reviews {
  .header__reviews {
    position: unset;
    margin: 2rem 0 0 0;
    padding: 0;
  }

  .reviews__rating {
  }
  .stars i {
    color: var(--star);
  }
}

//================================================================================== MENTIONS
.footer__mentions {
  text-align: center;
  padding: rem(10) 0;
  margin-top: 2rem;
  border-top: 1px solid white;

  p {
    font-size: 0.8rem;
    text-transform: uppercase;
    line-height: 1.5;
  }
  a:hover {
  }
}

// .footer__title::before {
//   display: inline-flex;
//   font-family: var(--font-awe);
//   content: "\f004";
// }

//================================================================================== PAIEMENT / NEWSLETTER

.footer__item--paiement-newsletter {
  .acym_module {
    margin-top: 1rem;
  }
  .acysubbuttons .btn {
    margin-top: 0.5rem;
    color: var(--light);
    border-color: var(--white);
    max-width: 180px !important;
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
  .footer__container {
    gap: 15px;
  }
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
}

@include breakpoint(1080) {
}

@include breakpoint(980) {
  .footer__container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "logo   logo    logo"
      "nav1 nav2 social";
  }
  .footer__item--logo {
    grid-area: logo;
    text-align: center;
    margin-bottom: 1.5rem;
    img {
      max-width: 40%;
      margin: 0 auto 1rem auto;
    }
  }
  .footer__item--menu--main {
    grid-area: nav1;
  }
  .footer__item--menu--second {
    grid-area: nav2;
  }
  .footer__item--social {
    grid-area: social;
  }
  .footer__item--social i {
    font-size: 2rem;
  }
}

@include breakpoint(780) {
}

@include breakpoint(550) {
  .footer {
    padding: 1rem 0.5rem;
  }
  .footer__container {
    display: block;
    text-align: center;
    padding: 0;
  }
  .footer__item {
    margin-top: 1rem;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
