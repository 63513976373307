.hika__contact__title {
  display: block;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 500;
}
#hikashop_contact_name_altbody {
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 15px;
  margin-bottom: 5px;
}

#hikashop_contact_altbody {
  margin-bottom: 1rem;
}

@include breakpoint(550) {
  .hika__contact__title {
    font-size: 1.5rem;
  }
  #hikashop_contact_name_altbody {
    font-size: rem(12);
    margin-left: 10px;
  }
}
