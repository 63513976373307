.header__shipping {
  background-color: var(--color-1);
  color: var(--white);
  text-align: center;
  padding: 0.5rem 1rem;
  // box-shadow: 5px 10px 21px var(--gray-3);
}

.header__top {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 0;
}

// ==================================================================== SEARCH

.header__search {
  // max-width: 380px;

  &.header__search--mobile {
    display: none;
  }

  .search__container {
    position: relative;
  }
  .search__input {
    width: 100%;
    border-radius: 10px;
    background-color: var(--opacity-1);
    border: 0;
    // color: var(--color-1);
    font-size: 1.1rem !important;

    &::placeholder {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
  .search__submit {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 50px;
    height: 100%;
    background: var(--color-1);
    border-radius: 0 10px 10px 0;
    // color: var(--white);
    path {
      fill: white;
    }
  }
  .search__clear {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
  }
}

// ==================================================================== NAVIGATION

.header__top__nav {
  .menu__top ul {
    background-color: var(--gray-6);
    box-shadow: 10px 20px 41px var(--gray-1);
    display: inline-flex;
    align-items: center;
    border-radius: 0 0 7px 7px;
  }
  .menu__item {
    position: relative;

    .soon {
      position: absolute;
      padding: 2px 5px;
      transform: translateX(32px);
      bottom: -10px;
      background-color: var(--gray-8);
      color: var(--white);
      font-size: rem(9);
      text-transform: uppercase;
    }
  }
  .menu__link {
    position: relative;
    text-transform: uppercase;
    font-size: rem(14);
    font-weight: bold;
    display: flex;
    padding: rem(5) 0 rem(5) rem(10);
    align-items: center;
    transition: color 0.15s;

    // ============================ icons
    svg {
      margin-right: rem(5);
    }

    &::after {
      content: "";
      display: block;
      background-color: var(--gray-7);
      width: 1px;
      height: 25px;
      margin-left: rem(10);
    }
  }
  .menu__link--how {
    display: none;
  }
  // ============================ last item
  .menu__item:last-child a {
    padding-right: rem(10);
    &::after {
      display: none;
    }
  }

  // ============================ hover
  .menu__link:hover {
    color: var(--color-2);
  }
}

// ==================================================================== AVANTAGES

.header__top__avantages {
  padding: rem(30) 0 0 0;
  display: inline-flex;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;

    svg {
      margin-right: 0.5rem;
    }

    .item__title {
      display: block;
      text-transform: uppercase;
      font-size: rem(15);
      font-weight: bold;
    }
  }
}

// ==================================================================== SERVICES
.header__top__services {
  text-align: center;
  @include flexbox;
  @include align-items(center);
  @include justify-content(right);
  gap: 2rem;
}

// ============================ connexion / favoris

.header__connexion,
.header__favoris {
  .btn__connexion {
    display: block;
  }

  svg {
    // margin: 0 auto 0.5rem auto;
    width: 45px;
    max-height: 45px;
    height: auto;
    path {
      fill: var(--dark);
    }
    &#icon-connexion--active {
      path {
        fill: var(--color-1);
      }
    }
  }
  .item__title {
    display: block;
    font-size: rem(16);
    font-weight: 500;
  }
  .item__title--logged {
    color: var(--color-1);
  }
}

.btn__cart--empty,
.header__favoris--empty {
  .cart__qty {
    display: none;
  }
}

.header__favoris {
  a {
    position: relative;
    display: block;
  }
  .cart__qty {
    right: 15px;
    top: 26px;
  }
}

// ================================================================================================== RESPONSIVE

@include breakpoint(1700) {
  // ============================ avantages
  .header__top__avantages .item svg {
    width: 32px;
    height: auto;
    margin-right: rem(5);
  }
  .header__top__avantages .item .item__title {
    font-size: rem(14);
  }
}

@include breakpoint(1440) {
  // ============================ navigation
  .header__top__nav .menu__link svg {
    width: 14px;
    height: auto;
  }
  .header__top__nav .menu__item .soon {
    transform: translateX(24px);
  }
  .header__top__nav .menu__link {
    font-size: rem(12);
    padding: 3px 5px;
  }
  // ============================ avantages

  .header__top__avantages {
    padding-top: 1.2rem;
  }
  .header__top__avantages .item {
    margin-right: 1rem;
  }
  .header__top__avantages .item svg {
    width: 28px;
  }
  .header__top__avantages .item .item__title {
    font-size: rem(13);
  }

  .header__connexion .item__title,
  .header__favoris .item__title,
  .cart__title {
    font-size: rem(15);
  }
  .cart__qty {
    top: 19px;
    width: 24px;
    height: 24px;
  }
  .header__favoris .cart__qty {
    top: 23px;
  }
}

@include breakpoint(1180) {
  .header__top {
    // display: block;
    display: grid;
    grid-template-columns: 160px 1fr;
    gap: 1rem;
  }

  .header__top__services {
    gap: 1rem;
  }
  .header__top__nav {
    display: none;
  }

  .header__top__avantages {
    @include hidden;
  }

  .header__connexion .item__title,
  .header__favoris .item__title,
  .cart__title {
    display: none;
  }

  .search__submit svg {
    max-width: 30px;
    height: auto;
  }
  .btn__cart #icon-panier {
    top: -2px;
  }

  .header__connexion svg,
  .header__favoris svg,
  .btn__cart #icon-panier {
    width: 40px;
    max-height: 40px;
  }
  .header__favoris .cart__qty {
    top: 19px;
    right: -7px;
  }
}

@include breakpoint(750) {
  .header__top__services {
    gap: 0.8rem;
  }
  .header__top__avantages {
    align-items: baseline;
    .item {
      display: block;
      text-align: center;
      align-items: baseline;
      width: 160px;
      margin-right: 0;
      svg {
        display: block;
        margin: auto;
        width: auto;
        height: 28px;
        margin-bottom: rem(8);
      }
      .item__title {
        font-weight: 500;
        font-size: rem(12);
        line-height: 1.2;
        span {
          display: none;
        }
      }
    }
  }
}

@include breakpoint(680) {
  .page-home .header__top {
    // display: block;
  }
  .header__top {
    display: block;
  }
}

@include breakpoint(550) {
  .mode__light-dark--pctablet {
    // display: none;
  }
  .header__search.header__search--mobile {
    display: block;
  }
  // .header__search {
  //   display: none;
  // }
  .header__search.header__search--mobile {
    // display: block;
  }
  .header__top {
    display: grid;
    grid-template-columns: 70px 1fr;
    align-items: center;
    @include align-items(center);
    gap: 1rem;
    border-bottom: 1px solid var(--opacity-1);
  }
  .header__top__services {
    gap: 0.5rem;
  }
  .header__top__avantages {
    padding-top: rem(26);
    .item {
      width: 140px;
      svg {
        height: 25px;
      }
      .item__title {
        font-size: rem(11);
      }
    }
  }

  .search__submit svg {
    max-width: 25px;
    height: auto;
  }
}

@include breakpoint(440) {
  .header__top__avantages {
    .item {
      width: 120px;
      svg {
        height: 25px;
        margin-bottom: rem(6);
      }
      .item__title {
        font-size: rem(9);
      }
    }
  }
  .header__top .header__top__services {
    height: 70px;
  }
}

@include breakpoint(360) {
  .header__top__avantages {
    .item {
      .item__title {
        font-size: rem(7);
      }
    }
  }
}
