.page-administration {
  .header {
    position: fixed;
    width: var(--admin-header-width);
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 101;
    background-color: var(--admin-color-light);
    box-shadow: 0 20px 41px rgba($color: #000000, $alpha: 0.04);
  }
}

.admin__header__logo {
  svg,
  img {
    display: block;
    margin: 1rem auto 0 auto;
    height: auto;
    width: 190px;
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  .admin__header__logo {
    svg,
    img {
      width: 150px;
    }
  }
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  .page-administration .header {
    padding: 0 1rem;
    width: 100%;
    overflow: initial;
    max-height: 80px;
    box-shadow: 10px 20px 41px rgb(0 0 0 / 10%);
  }
  .admin__header__logo {
    position: relative;
    top: 5px;
    svg,
    img {
      max-height: 70px;
      width: auto;
      margin: 0;
    }
  }
}

@include breakpoint(980) {
}

@include breakpoint(768) {
  // width 100% element
}

@include breakpoint(550) {
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
