// ========================================================= EDITION ADRESSE

.hikashop_address_edition {
  max-width: 550px;
  padding: 1rem 2rem;
  border: 1px solid var(--gray-2);
  border-radius: 10px;

  h3 {
    margin-bottom: 2.5rem;
    color: var(--color-1);
  }

  .hkc-sm-8 {
    width: 100%;
  }

  .hikashop_checkout_address_cancel_button {
    background-color: var(--color-2);
  }

  #hikashop_address_address_title {
    // margin-top: 2rem;
    label {
      display: block;
    }
    .hkradio {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
}

@include breakpoint(550) {
  .hikashop_address_edition {
    padding: 1rem;
    border: none;
    border-radius: none;
  }
}

// ========================================================= EO EDITION ADRESSE

#hikashop_address_listing {
  #hikashop_user_addresses_default {
    @include flexbox;
  }
  .hikashop_default_shipping_address {
    margin-right: 1rem;
  }
  .hikashop_default_billing_address {
    @include order(2);
  }
  .hika_options {
    // max-width: 380px;
    width: 100%;
  }

  #hikashop_user_addresses_show {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // @include align-items(center);
    // gap: 15px;
    .hk-row-fluid {
      @include justify-content(space-between);
      @include align-items;
    }
    .hkc-md-6 {
      padding: 1rem 0;
      border-radius: 10px;
      text-align: center;
      width: 48%;
      background-color: var(--light);
      box-shadow: 4px 8px 16px var(--gray-1);

      &:first-child {
        @include order(2);
      }
      &:last-child {
        margin-right: 2%;
      }
    }
    .hikashop_user_address {
      position: relative;
      //   text-transform: uppercase;
      font-size: 1rem;
      background-color: var(--gray-2);
      padding: 0.5rem 1rem 0.5rem 1rem;
      margin: 0 0.5rem 1rem 0.5rem;
      border-radius: 8px;
    }
    .hika_edit {
      position: absolute;
      top: 0%;
      right: 0;
      margin-top: 0.5rem !important;
      text-transform: capitalize;

      .btn--outlined--danger.btn.btn--small {
        display: none;
      }
    }
    .hika__address__footer__btn {
      @include flexbox;
      @include flex-flow(row wrap);

      gap: 8px;
    }
    .hika__address__type {
      // position: absolute;
      // top: 4px;
      // right: 4px;
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 500;
      @include flexbox;
      @include align-items(center);
      @include flex-flow(row wrap);
      gap: 5px;
    }
  }
}

@include breakpoint(880) {
  #hikashop_address_listing #hikashop_user_addresses_default,
  #hikashop_address_listing #hikashop_user_addresses_show .hk-row-fluid {
    display: block;
  }
  #hikashop_address_listing .hika_options,
  #hikashop_address_listing #hikashop_user_addresses_show .hkc-md-6 {
    width: 100%;
    margin: 1rem 0;
  }
  #hikashop_address_listing #hikashop_user_addresses_show .hika_edit {
    position: relative;
  }
}

@include breakpoint(550) {
  #hikashop_address_listing #hikashop_user_addresses_show {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
