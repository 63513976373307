// Default variables
:root {
  --font_size: 18px;
  --font_size_small: 12px;

  --input_border_radius: 5px;
  --input_height: 42px;
  --input_height_small: 36px;
  --dropdown_padding: 18px;

  --gray_dark: var(--dark);
  --gray: var(--dark);
  --gray_light: var(--gray-2);
  --gray_lighter: var(--gray-1);
  --primary_light: var(--gray-3);
  --arrow_color: var(--color-1);
}

[data-theme="dark"] {
  // --gray_dark: #444;
  // --gray: #999;
  // --gray_light: #e8e8e8;
  // --gray_lighter: #f6f6f6;
  // --primary_light: var(--gray);
  // --arrow_color: var(--gray);
}

// Style the dropdown
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-radius: var(--input_border_radius);
  border: 1px solid var(--gray_light);
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: var(--font_size);
  font-weight: normal;
  height: var(--input_height);
  line-height: var(--input_height);
  outline: none;
  padding-left: var(--dropdown_padding);
  padding-right: calc(var(--dropdown_padding) + 22px);
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  min-width: 150px;
  @include flexbox;
  @include align-items(center);
  &:hover {
    // border-color: darken(var(--gray_light), 5%);
  }
  &:active,
  &:focus {
    border-color: var(--primary_light);
  }
  // Arrow
  &:after {
    border-bottom: 2px solid var(--arrow_color);
    border-right: 2px solid var(--arrow_color);
    content: "";
    display: block;
    height: 14px;
    margin-top: -10px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 14px;
  }

  &.open {
    @extend :active;
    &:after {
      transform: rotate(-135deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }
  &.disabled {
    // border-color: lighten(var(--gray_light, 2%);
    color: var(--gray);
    pointer-events: none;
    &:after {
      // border-color: lighten(var(--arrow_color, 20%));
    }
  }

  // Modifiers
  &.wide {
    width: 100%;
    .list {
      left: 0 !important;
      right: 0 !important;
    }
  }
  &.right {
    float: right;
    .list {
      left: auto;
      right: 0;
    }
  }
  &.small {
    font-size: var(--font_size_small);
    height: var(--input_height_small);
    line-height: var(--input_height_small);
    &:after {
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: var(--input_height_small);
      min-height: var(--input_height_small);
    }
  }

  // List and options
  .list {
    background-color: var(--light);
    border: 1px solid var(--primary_light);
    border-radius: var(--input_border_radius);
    box-shadow: 0 0 0 1px rgba(var(--gray_dark, 0.11));
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(var(--input_height));
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9;
    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
  }
  .option {
    cursor: pointer;
    font-weight: 400;
    line-height: var(--input_height);
    list-style: none;
    min-height: var(--input_height);
    outline: none;
    padding-left: var(--dropdown_padding);
    padding-right: var(--dropdown_padding);
    text-align: left;
    transition: all 0.2s;
    &:hover,
    &.focus,
    &.selected.focus {
      background-color: var(--gray_lighter);
    }
    &.selected {
      font-weight: bold;
    }
    &.disabled {
      background-color: transparent;
      color: var(--gray);
      cursor: default;
    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }
  &.open {
    .list {
      display: block;
    }
  }
}
