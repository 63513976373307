.hikashop_filter_main_div {
  // position: sticky;
  // top: 20px;
}

.filter__wrapper {
  background-color: var(--light);
  border: 1px solid var(--opacity-1);
  padding: 1rem;
  border-radius: 10px;
}

.hikashop__reset--top {
  margin: 1rem 0;
}

.hikashop_filter_main_Trier_33 {
  // position: absolute;
  // @include flexbox;
  // @include align-items(center);
  // @include justify-content(end);
  position: relative;
  margin-bottom: 1rem;
  z-index: 10;
  top: 0;
  right: 0;
  max-width: 300px;
  border-top: none !important;
  padding: 0 !important;

  .hikashop_filter_Trier_33 {
    @include flexbox;
    @include align-items(center);
    @include justify-content(end);
    gap: 1rem;
  }
  .hikashop_filter_title {
    white-space: nowrap;
  }

  .hikashop_filter_title::after {
    content: none;
  }
}

// ------------------------------------------------ title Filter & Trier

.filter__title {
  display: block;
  // margin-bottom: 1rem;
  font-weight: 500;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  i {
    margin-right: rem(7);
  }
}

.close__filter {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--color-2);
  color: var(--white);
  font-size: 1.8rem;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
}

// ------------------------------------------------- filtrer / trier mobile

.filter__mobile {
  display: none;
  position: fixed;
  text-align: center;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  bottom: 1rem;
}

.btn__filter__mobile {
  width: 100%;
  padding: 0.8rem 1.5rem;
  background-color: var(--color-2);
  color: white;
  font-size: 1.1rem;
  border-radius: 50px;
  box-shadow: 5px 10px 21px var(--opacity-3);
  border: 1px solid var(--white);
  i {
    margin: 0 8px;
  }
}

// ------------------------------------------------- eo filtrer / trier mobile

// ================================================================== list filtre

.hikashop_filter_main {
  width: 100% !important;
  float: none !important;
  min-height: auto !important;
  border-top: 1px solid var(--gray-2);
  padding: 0.5rem 0;

  &:first-of-type {
    border: none;
  }

  br {
    display: none;
  }

  .filter_values_container {
    width: 100%;
  }
}

// ---------------------------- titre
.hikashop_filter_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-size: clamp(0.85rem, 1vw, 1rem);
  font-weight: 600;
  &::after {
    @include icon("\f139");
    color: var(--color-1);
    font-size: 1.5rem;
  }

  &.active--down {
    &::after {
      transform: rotate(180deg);
    }
  }
}
// ---------------------------- eo titre

// ---------------------------------- activation ou non du filtre
.hikashop_filter_test2_32 {
  .filter_values_container {
    display: none;
  }
  .hikashop_filter_title {
    &::after {
      transform: rotate(180deg);
    }
    &.active--down::after {
      transform: rotate(0deg);
    }
  }
}

// ---------------------------------- item
.hikashop_filter_checkbox {
  display: block;
  margin: 4px 0;
  cursor: pointer;

  input {
    position: absolute;
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
  }

  label {
    position: relative;
    font-size: 1rem;
    font-size: clamp(0.8rem, 1vw, 1rem);
    cursor: pointer;
    @include flexbox;
    @include align-items(center);
    gap: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 2px 6px;
    border-radius: 6px;
    margin: 0;

    &::before {
      content: "";
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      background-size: cover;
      background-position: center;
    }
  }

  input:checked + label {
    content: "";
    background-color: var(--gray-7);
    box-shadow: 4px 8px 17px var(--gray-5);
    color: white;
    &::after {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      @include icon("\f058");
    }
  }

  &.active::after {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    @include icon("\f058");
  }
}

// ---------------------------------- eo item

// ------------------------------------------------- PAS DE PRODUITS
.hika_no_products {
  margin-top: 80px;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 0 !important;
  background-color: transparent !important;
  color: var(--error);
  border: 2px solid var(--error) !important;
  text-align: center;

  border-radius: 7px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: rem(18);
  line-height: 1.7;
  // max-width: 500px;
}

// // ---------------------------- btn valider / appliquer

.hikashop_filter_button_inside {
  display: none;
  float: none !important;
  margin: 0 !important;
  .btn {
    // display: none;
    // margin-top: 2rem;
    height: auto;
    padding: rem(10) 0;
    font-size: rem(16);
    width: 100%;
    height: auto !important;
    box-shadow: 0 -10px 21px var(--gray-1);
    background-color: var(--color-1);
    color: var(--white);

    &:hover {
      opacity: 0.8;
    }
  }
}

// // ------------------------------------------------- bouton réinitialiser
.hikashop_reset_button_inside {
  float: none !important;
  text-align: center;
  margin-top: 1.5rem;
  .btn {
    color: var(--error);
    border: 2px solid var(--error);
    padding: 4px 16px;
    border-radius: 50px;
    transition: all 0.15s;
    font-size: unset;
    font-weight: normal;
    &:hover {
      color: white;
      background-color: var(--error);
    }
  }
}

// ------------------------------------------------- PRIX
.hikashop_products_listing_main {
  .noUi-horizontal {
    height: 16px;
    border-radius: 50px;
    margin: 2.5rem auto 1rem auto;
    background-color: rgba($color: #000000, $alpha: 0.25);
    border: 0;
    max-width: 90%;
    .noUi-handle {
      width: 22px;
      height: 22px;
      top: -3px;
      cursor: e-resize;
      border-radius: 50px;
      border: 0;
      box-shadow: 0px 0px 0px 3px var(--gray-1);
      &::before,
      &::after {
        content: none;
      }
    }

    .noUi-tooltip {
      background-color: var(--dark);
      font-size: 0.85rem;
      color: var(--light);
      border-radius: 50px;
      padding: 2px 8px;
      font-weight: 500;
    }
  }

  .noUi-connect {
    background-color: var(--color-1) !important;
  }
}

// ------------------------------------------------- EO PRIX
//   // ----------------------------- cacher reset input
//   .hikashop_filter_checkbox {
//     label {
//       margin-left: 8px;
//     }
//     a {
//       display: none;
//     }
//   }
// }

// // ------------------------------------------------- POIDS
// .hikashop_filter_main .noUi-target.noUi-ltr.noUi-horizontal {
//   margin: rem(34) auto 0 auto !important;
//   width: 90% !important;
// }

// // -------------------- barre progression
// .noUi-connect {
//   background-color: var(--color-2) !important;
// }

// // -------------------- barre dans rond glisser
// .noUi-handle:after,
// .noUi-handle:before {
//   content: none !important;
// }

// // -------------------- barre horizontale
// .noUi-horizontal {
//   border: 1px solid var(--gray-4);
//   border-radius: 5px;
// }

// // -------------------- nombre container
// .noUi-horizontal .noUi-tooltip {
//   // transform: translate(0, 0) !important;
//   // bottom: 0 !important;
//   // left: 0;
//   // position: relative;
//   border: 0;
//   box-shadow: 3px 6px 12px rgba($color: #000000, $alpha: 0.08);
// }

// // -------------------- nombre texte
// .noUi-tooltip {
//   font-size: rem(14);
//   font-weight: 500;
//   padding: 0 rem(5);
//   color: var(--color-2);
// }

// // -------------------- Range slider
// .noUi-handle {
//   height: 30px !important;
//   width: 30px !important;
//   border-radius: 50%;
//   top: -9px !important;
//   border: 1px solid var(--gray-6);
//   box-shadow: 2px 4px 8px rgba($color: #000000, $alpha: 0.2);
// }

// // .hikashop_checkout_loading_elem,
// // .hikashop_checkout_loading_spinner {
// //   display: none !important;
// // }

// // ================================================================= RESPONSIVE

@include breakpoint(1080) {
  .hikashop_filter_main_div {
    position: initial;
    top: initial;
  }

  .hikashop_filter_main_Trier_33 {
    position: relative;
    @include justify-content(start);
    padding: 0.5rem 0 !important;
  }

  .filter__wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    left: 0;
    // opacity: 0;
    overflow: auto;
    padding: 2rem 1rem;
    transform: translateY(100%);

    box-shadow: none;
    border-radius: 0;
  }

  //   .filter__content {
  //     max-height: calc(100vh - 100px);
  //     height: calc((var(--vh, 1vh) * 100) - 100px);
  //     overflow: auto;
  //   }

  .filter__wrapper--active {
    z-index: 99999;
    transform: translateY(0);
    transition: all 0.2s;
  }

  .filter__title {
    border-bottom: 1px solid var(--gray-7);
    padding-bottom: 8px;
  }

  .filter__mobile {
    display: block;
  }
  .hikashop_filter_button_inside {
    display: block;
    margin-top: 2rem !important;
  }

  .close__filter {
    display: block;
  }

  .btn.btn__mobile--filter--active {
    position: fixed;
    left: 0;
    z-index: 101;
    bottom: 0;
    margin: 0;
    border-radius: 0;
    padding: 12px 0;
  }

  .body--active {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
  .hikashop_filter_main .noUi-target.noUi-ltr.noUi-horizontal {
    margin: 2rem 0 0 1.5rem !important;
    width: calc(100% - 60px) !important;
  }
}

// @include breakpoint(550) {
//   .hikashop_reset_button--top {
//     margin: 8px 0;
//   }

//   .btn--reset {
//     padding: 3px 12px;
//     font-size: 0.9rem;
//   }

//   .btn__filter__mobile {
//     border-radius: 0;
//     padding: 8px 0;
//     font-size: 0.9rem;
//   }
//   .btn.btn__mobile--filter--active {
//     padding: 12px 0;
//   }

//   .filter__title {
//     font-size: 1rem;
//   }

//   .hikashop_filter_Trier_1 .hikashop_filter_title,
//   .hikashop_filter_TrierMobile_4 .hikashop_filter_title,
//   .hikashop_filter_Parproducteurs_2::before {
//     font-size: 0.9rem;
//   }

//   .close__filter {
//     width: 36px;
//     height: 36px;
//     line-height: 36px;
//     font-size: 1.4rem;
//   }

//   // ------------------------------------------------ spinner / loading
//   .filter_refresh_div.hikashop_checkout_loading {
//     .hikashop_checkout_loading_elem {
//     }
//     .hikashop_checkout_loading_spinner {
//       top: 40% !important;
//     }
//   }
// }

// @include breakpoint(440) {
// }

// @include breakpoint(360) {
// }
