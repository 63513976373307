.pagination {
  margin: 3rem;
  text-align: center;
  .limit,
  .counter,
  .hikashop_results_counter {
    display: none;
  }

  .page-link,
  li {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 5px;
    box-shadow: 2px 4px 8px var(--gray-1);
    margin: 4px;
  }

  .pagenav.page-link.pagenav_text,
  span.pagenav {
    box-shadow: none;
    border: 0;
    background-color: var(--light);
    color: inherit;
    opacity: 0.2;
  }

  span.pagenav.page-link {
    color: var(--white);
    background-color: var(--color-2);
  }

  //   -------------------------- chevron
  .pagenav_start_chevron,
  .pagenav_previous_chevron,
  .pagenav_next_chevron,
  .pagenav_end_chevron {
    display: none;
  }
}

.hikamarket_pagination {
  text-align: center;
  .list-footer {
    margin-bottom: 1rem;
  }
}
