::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: var(--gray-7);
}

::-webkit-scrollbar-thumb {
  background: var(--light);
  border: 4px solid var(--gray-7);
  border-radius: 7px;
}
