.home-moment {
  padding-bottom: var(--section);
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    .item {
      position: relative;
      box-shadow: 10px 20px 41px var(--gray-2);
      border-radius: 3px;
      overflow: hidden;

      .item__link {
        display: block;

        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 0.2s;
    }

    .item__content {
      position: absolute;
      z-index: 20;
      bottom: 2rem;
      left: 2rem;
    }

    .item__title {
      display: block;
      font-family: var(--font-titre);
      color: var(--white);
      font-size: rem(50);
      line-height: 1;
    }

    .item__soon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($color: #ffffff, $alpha: 0.8);
      font-size: rem(35);
      font-weight: 500;
      width: 100%;
      height: 100%;
      z-index: 10;
      top: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        52deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(62, 61, 61, 1) 100%
      );
      opacity: 0.8;
    }

    .item__newsletter {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      color: var(--white);
      padding: 6px;

      .item__inscription {
        display: inline-block;
        font-size: rem(20);
        margin: 0 8px 4px 0;
      }
    }
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
  .home-moment .items .item__title {
    font-size: rem(45);
  }
  .home-moment .items .item__soon {
    font-size: rem(25);
  }
  .home-moment .items .item__newsletter .item__inscription {
    font-size: 1rem;
  }
}

@include breakpoint(1440) {
  .home-moment .items .item__content {
    bottom: 1rem;
    left: 1rem;
  }
  .home-moment .items .item__title {
    font-size: rem(40);
  }

  .home-moment .items .item__soon {
    font-size: rem(23);
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  .home-moment .items .item__title {
    font-size: rem(35);
  }

  .home-moment .items .item__soon {
    font-size: rem(21);
  }
}

@include breakpoint(980) {
  .home-moment .items .item__newsletter .item__inscription {
    font-size: 0.9rem;
  }
}

@include breakpoint(780) {
  .home-moment .items {
    grid-gap: 10px;
  }
  .home-moment .items .item__content {
    bottom: 0.6rem;
  }
  .home-moment .items .item__title {
    font-size: rem(32);
  }

  .home-moment .items .item__soon {
    font-size: rem(20);
  }
  .home-moment .items {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(550) {
  .home-moment .items .item__content {
    bottom: 0.4rem;
  }
  .home-moment .items .item__title {
    font-size: rem(28);
  }

  .home-moment .items .item__soon {
    font-size: rem(18);
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
  .home-moment .items .item__newsletter .item__inscription {
    font-size: 0.8rem;
  }
}
