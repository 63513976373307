.page-contact #maincontent {
  // padding-top: 10rem;
  text-align: center;
  margin-top: var(--section);
  padding-top: 1rem;
}

.contact__content {
  position: relative;

  .title__main {
    margin-bottom: 0;
  }
}

.contact__media {
  display: none;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  img {
    display: block;
    border-radius: 15px;
    // min-height: 500px;
    object-fit: cover;
    object-position: center;
  }
}
.contact__infos {
  background-color: var(--gray-6);
  padding: 6rem 1rem;
}

// -------------------------------------------- FOX CONTACT

.page-contact {
  .fox-container {
    text-align: center;
    background: white;
    max-width: 400px;
    box-shadow: 0 20px 41px var(--gray-2);
    border-radius: 15px;
    margin: auto;

    .fox-form {
      padding: 2rem 1rem 0 1rem;
    }
  }
}

// ------------------------ btn envoyer
.fox-item.fox-item-submit-fields .controls {
  width: 100% !important;

  .btn {
    width: 100%;
    padding: rem(10) 0;
    height: 60px !important;
    font-size: 1.3rem;
    margin-right: 0 !important;
  }
}

// -------------------------------------------- MAP
#map {
  width: 100%;
  height: 500px;
}

/* ======================================================================  RESPONSIVE ============================= */

@include breakpoint(700) {
  #map {
    height: 300px;
  }
}

@include breakpoint(550) {
  .contact__media {
    display: none;
  }

  .fox-container {
    box-shadow: none;
    max-width: 95%;
    padding: 1rem 0;
    // overflow: hidden;
    border-radius: 0;
  }
}
