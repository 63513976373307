.guides {
  max-width: 980px;
  ul {
    li {
      margin-left: 1rem;
      padding: 0.5rem 0;

      &::before {
        @include icon("\f0da");
        color: var(--color-2);
        margin-right: 7px;
      }
    }
  }

  .guides__intro {
    font-size: 1.3rem;
    font-weight: 500;
    background-color: var(--gray-7);
    padding: 1rem;
    border-radius: 7px;
    margin-bottom: 2rem;
  }

  .guides__end {
    font-weight: 500;
  }

  .guides__shipping {
    margin: 3rem 0;
    .shipping__add {
      margin: 2rem 0;
      background-color: var(--light);
      padding: 1rem;
      border-radius: 7px;
    }
  }

  .guides__infos {
    margin: 2rem 0;
    .btn {
      margin-top: 1rem;
    }
  }
}

@include breakpoint(550) {
  .guides .guides__intro {
    font-size: 1.1rem;
  }
  .guides ul li {
    padding: 0.3rem 0;
  }
}
