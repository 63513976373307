// A REVOIR

.sitemap {
  margin: 10rem auto 15rem auto;
  padding: 2rem;

  h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
  }

  span,
  a {
    &:hover {
      color: var(--color-1) !important;
    }
  }
}
