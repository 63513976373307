.sourcecoast {
  // display: none;

  // & + .login__separator {
  //   display: none;
  // }
}
.social-login.facebook {
  display: none;
}

#jfbc_loginregister {
  margin: 5rem 0;
  text-align: center;

  .login__jfb__title {
    display: none;
  }

  legend {
    font-size: 2.5rem;
    font-weight: initial;
    margin-bottom: 0;
  }

  .login__jfb__infos {
    margin: 0.5rem 0 2rem 0;
    font-size: 14px;
    span {
      font-weight: bold;
      //   text-transform: uppercase;
    }
  }
}

#jfbc_loginregister_existinguser,
#jfbc_loginregister_newuser {
  display: none !important;
}

.login__jfb__content {
  max-width: 500px;
  margin: auto;
  box-shadow: 4px 8px 16px var(--gray-1);
  padding: 1rem 2rem 2rem 2rem;
  text-align: center;
  border-radius: 10px;
  #jfbc_loginregister_existinguser {
    border: 0 !important;
    box-shadow: 4px 8px 16px var(--gray-1);
    padding: 1rem 2rem 2rem 2rem;
    text-align: center;
    border-radius: 10px;
    margin: 2rem 0 2rem 0 !important;
  }

  .sourcecoast.login .row-fluid {
    display: block !important;
  }
}
@include breakpoint(550) {
  .login__jfb__content {
    box-shadow: none;
    #jfbc_loginregister_existinguser {
      padding: 1rem;
    }
  }
}
