.home-category {
  --category-height: 300px;
  @extend %bg-home;

  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    max-width: 980px;
    padding: 0 1rem;
    margin: auto;
  }

  .item {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: var(--category-height);
  }

  .item--avis {
    border: 1px solid var(--gray-3);

    .item__title {
      text-shadow: none;
      color: var(--color-1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .item__title {
    position: absolute;
    width: 100%;
    //   background: rgba($color: #c769bd, $alpha: 0.7);
    left: 0;
    text-align: left !important;
    font-family: var(--font-titre);
    z-index: 10;
    bottom: 0;
    padding: 5px 0 5px 15px;
    color: var(--white);
    //   text-transform: uppercase;
    font-weight: 500;
    font-size: clamp(1.3rem, 2.2vw, 2.2rem);
    text-shadow: 0 2px 4px rgba($color: #000000, $alpha: 1);
    // text-shadow: var(--shadow-text);
  }
}

@include breakpoint(1280) {
  .home-category .items {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
}

@include breakpoint(550) {
  .home-category {
    --category-height: 225px;
    .items {
      display: grid;
      gap: 0.5rem;
      padding: 0 0.5rem;
      grid-template-columns: repeat(auto-fit, minmax(154px, 1fr));
    }
    .item__title {
      padding-left: 8px;
    }
  }
}
