.hikashop_product {
  // width: 275px !important;
  width: 100% !important;
  // height: 460px;
  // overflow: hidden;
  border-radius: 8px;
  padding: 0;
  transition: none;
  position: relative;
  background-color: var(--light);
  padding: 4px;
  border-radius: 10px;
  border: 1px solid var(--gray-1);
  // transition: all 0.15s;
  // max-width: 260px;
  margin: 4px;
  &:hover {
    // box-shadow: var(--shadow-2);
  }

  .hikashop_container {
    margin: 0 !important;
  }

  .hikashop_subcontainer {
    height: auto;
    min-height: auto;
  }

  .card {
  }

  .card__category {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 0 10px 0;
    background-color: var(--color-1);
    color: var(--white);
    font-size: rem(13);
    padding: 2px 10px;
    display: none;
  }

  .card__reduction {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-1);
    color: var(--white);
    padding: 8px;
  }

  // ------------------------------------- image
  .hikashop_product_image {
    // display: none;
    margin-bottom: 0.2rem;
    height: initial !important;
    .hikashop_product_image_subdiv {
      width: 100% !important;
    }
    img {
      display: block;
      border-radius: 6px;
      margin: auto;
    }
  }

  // ------------------------------------- eo image

  .card__body {
    padding: 0 5px;
  }

  .hikashop_product_name {
    // display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // text-align: left !important;
    font-size: rem(17);
    font-size: clamp(0.95rem, 1vw, 1rem);
    color: var(--color-1);
    // font-weight: 500;
    margin-top: 1rem;
    margin-top: clamp(0.5rem, 1.2vw, 1.2rem);

    a {
      display: block;
      text-align: center;
      height: clamp(2.2rem, 3vw, 3rem);
    }
    // max-height: 125px; pour firefox et autres sinon ne faire qu'un seule paragraphe
  }
  // .hikashop_product_code_list {
  //   display: none;
  // }

  // ------------------------------------- vote

  // ----> _vote.scss

  .card__price-review {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    margin: 1rem 0;
    min-height: 30px;
    display: none;
  }

  // ------------------------------------- prix

  .card__price {
    text-align: right;
  }

  .hikashop_product_discount_amount {
    position: absolute;
    text-align: center;
    top: 0;
    right: 5%;
    font-weight: 600;
    font-size: 1.15rem;
    background-color: var(--color-1);
    color: var(--white);
    height: 67px;
    line-height: 67px;
    width: 60px;
    border-radius: 0 0 50px 50px;
  }

  .hikashop_product_price_before_discount {
    display: block;
    text-decoration: line-through;
    font-size: rem(16);
    text-align: right !important;
    line-height: 1;
  }

  .hikashop_product_price {
    font-size: rem(22);
    font-size: clamp(1rem, 1.4vw, 1.4rem);
    font-weight: 600;
    line-height: 1;
    // color: var(--color-1);
  }

  .hikashop_product_price_per_unit {
    display: none;
  }
  .hikashop_product_discount_amount {
    // display: none;
  }

  // -------------------------------------  wisht / add to cart

  .card__whish-add {
    width: 100%;
    // padding: 0 15px 10px 5px;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    min-height: 50px;
    margin-top: 2rem;
    .notifyjs-wrapper {
      display: none;
    }

    .card__whish {
      @include flexbox;
    }
  }

  .btn--add-cart {
    width: 50px;
    height: 50px;
    padding: 0;
    padding: 0;
    border-radius: 50%;
    &::before {
      bottom: 0;
      left: 1px;
      width: 110%;
      height: 110%;
    }
    &:hover::before {
      z-index: -1;
      transition: all 0.55;
      background-color: var(--color-1);
      opacity: 0;
    }
    &:hover {
      background-color: var(--color-1);
    }
    &:hover path {
      fill: var(--light);
    }
    span {
      display: block;
      margin: 0 0 2px 2px;
    }
  }
  .btn--waitlist {
    // display: none !important;
  }

  .qty_max_in_cart {
    position: absolute;
    width: 100px;
    text-align: right;
    font-size: 0.7rem;
    font-size: clamp(0.7rem, 0.9vw, 0.9rem);
    right: 0.5rem;
    color: var(--color-1);
    line-height: 1;
    display: none;
  }

  // --------------------------- stock

  .hikashop_product_stock_count {
    position: absolute;
    pointer-events: none;
    z-index: 10;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.7rem;
    display: none;
  }

  .hika__stock {
    display: block;
    background-color: var(--success);
    color: var(--white);
    padding: 1px 7px;
    font-size: 0.7rem;
    border-radius: 7px 7px 0 0;

    span {
      font-weight: bold;
      font-size: 0.9rem;
    }

    &.hika__stock--warning {
      background-color: var(--warning);
    }

    &.hika__stock--danger {
      background-color: var(--error);
    }

    &.hika__stock--null {
      display: none;
      background-color: var(--error);
    }
  }

  .hika__stock--empty {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: var(--error);
    color: var(--white);
    padding: 4px 9px 4px 12px;
    // box-shadow: 4px 8px 16px rgba($color: #000000, $alpha: 0.16);
    border-radius: 7px 0 0 0;
  }
}

@include breakpoint(1440) {
  .hikashop_product {
    // width: 250px !important;
    // height: 420px;

    .hikashop_product_name {
      font-size: 0.8rem;
    }

    .card__whish {
      svg {
        width: 25px;
        height: auto;
      }
    }

    .btn--add-cart {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }
  .hikashop_product .card__whish-add {
    min-height: 40px;
  }
}

@include breakpoint(1180) {
  .hikashop_product {
    // width: 225px !important;
    // height: 390px;
    margin: 0.5rem;

    .hikashop_product_discount_amount {
      font-size: 0.8rem;
      height: 47px;
      line-height: 47px;
      width: 40px;
    }
  }
}

@include breakpoint(550) {
  .page-produits .hikashop_product {
    // max-width: 50%;
  }

  .hikashop_product {
    // width: 160px !important;
    // height: 280px;
    margin: 0 0.2rem;
    // width: 50%;
    &:hover {
      box-shadow: none;
    }

    .hikashop_product_stock_count {
      display: none;
    }

    .hikashop_product_discount_amount {
      font-size: 0.7rem;
      height: 40px;
      line-height: 40px;
      width: 34px;
    }

    .hikashop_product_name {
      font-size: 0.7rem;

      a {
        height: 34px;
      }
    }
    .card__price-review {
      margin: 0.5rem 0;
    }
    .hk-rating.hk-rating--list i {
      font-size: 8px;
    }

    .hk-rating.hk-rating--list .rating__title {
      font-size: 0.7rem;
    }
    .hikashop_product_price_before_discount {
      font-size: 0.8rem;
    }
    .hikashop_product_price {
      font-size: 1rem;
    }

    .card__whish {
      svg {
        width: 20px;
        height: auto;
      }
    }

    .btn--add-cart {
      width: 30px;
      height: 30px;
      border-width: 1px;

      svg {
        width: 15px;
        height: auto;
      }
    }
  }
  .hikashop_product .card__whish-add {
    min-height: 30px;
  }
}

@include breakpoint(380) {
  .hikashop_product {
    // width: 155px !important;
    // height: 280px;
  }
}

@include breakpoint(359) {
  .hikashop_product {
    // width: 100% !important;
    // height: auto;
  }
}

@include breakpoint(320) {
  .hikashop_product {
    margin: 0.5rem 0;
    max-width: 100%;
  }
}

// --------------------------------------------- PAGE LISTE DES PRODUITS
.page-produits {
  .hikashop_product {
    // width: calc(33% - 2rem) !important;
    // margin: 0 0rem 1.5rem 0;
    // max-width: 280px;
    // min-width: 10vw;
  }
}

@include breakpoint(1440) {
  .page-produits {
    .hikashop_product {
      // width: calc(33.333% - 1rem) !important;
    }
  }
  .hikashop_product .hikashop_product_name a {
    // height: 42px;
  }
}

@include breakpoint(800) {
}

@include breakpoint(650) {
  .page-produits {
    .hikashop_product {
      // width: calc(50% - 1rem) !important;
    }
  }
}

@include breakpoint(440) {
  .page-produits {
    .hikashop_product {
      // width: calc(50% - 0.5rem) !important;
      // margin: 0 0 0.5rem 0;
    }
  }
}

// --------------------------------------------- EO PAGE LISTE DES PRODUITS
