.hikam_table {
  width: 100%;
  thead th {
    padding: 15px 6px;
  }
  tbody tr:nth-child(odd) {
    background-color: var(--gray-6);
    padding: 6px;
  }
  tbody td {
    padding: 12px 6px;
  }
}

@include breakpoint(1368) {
  .hikam_table {
    thead th {
      padding: 10px 6px;
    }
    tbody tr:nth-child(odd) {
      background-color: var(--gray-6);
      padding: 6px;
    }
    tbody td {
      padding: 6px;
      font-size: 0.85rem;
    }
  }
}

@include breakpoint(880) {
  .hikam_table {
    .hikam_table {
      thead th {
        display: none;
      }
      tbody td {
        display: block;
        text-align: center;
        padding: 6px;
        font-size: 0.85rem;
      }
    }
  }
}
