.home-carte-cadeau {
  position: relative;
  z-index: 10;
  margin-bottom: var(--section);
  display: none;

  //   ------------------------------- eo deco

  //   ------------------------------- titre
  .title__chalk--h1 {
    display: inline-block;
    left: 40%;
    &::before {
      content: url("/images/arrow-carte-cadeau.png");
      position: absolute;
      top: 0;
      right: 105%;
      transform: rotate(-180deg);
    }
  }
  //   ------------------------------- eo titre

  //   ------------------------------- grid
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    padding: 0 1rem;
    align-items: center;
    max-width: 1200px;
    margin: 1rem auto 0 auto;
  }

  //   ------------------------------- carte
  .carte-cadeau__img {
    display: block;
  }

  .item__card {
    text-align: center;
  }
  .item__link {
    display: block;
    margin: auto;
    max-width: 500px;
    border-radius: 22px;
    transition: all 0.2s;
    box-shadow: 10px 20px 41px var(--gray-3);
    &:hover {
      // transform: scale(1.03);
    }
  }
  //   ------------------------------- content
  .item__content {
    p,
    .item__title,
    li {
      font-family: var(--font-chalk);
      color: var(--white);
    }
    p {
      font-size: rem(36);
      line-height: 1.4;
      font-weight: bold;
    }
    .item__title {
      display: block;
      font-size: rem(40);
      margin-top: 1rem;
      // color: $color-2;
      font-weight: bold;
    }
    ul {
      margin: rem(8) 0 rem(16) 0;
    }
    li {
      font-size: rem(33);
      padding: 2px 0;
      span {
        font-weight: bold;
        font-size: 1.5rem;
        margin-left: 8px;
      }
    }
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
  .home-carte-cadeau .title__chalk--h1::before {
    transform: rotate(-180deg) scale(0.8);
    top: -12px;
    right: 101%;
  }
  .home-carte-cadeau .item__content p {
    font-size: rem(32);
  }
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
}

@include breakpoint(980) {
  .home-carte-cadeau .item__content p {
    font-size: rem(28);
  }
  .home-carte-cadeau .item__content .item__title {
    font-size: rem(32);
  }
  .home-carte-cadeau .item__content li {
    font-size: rem(28);
  }
}

@include breakpoint(780) {
  .home-carte-cadeau {
    padding: 4rem 0 6rem 0;
  }
  .home-carte-cadeau .title__chalk--h1 {
    left: initial;
    display: block;
  }
  .home-carte-cadeau .items {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .home-carte-cadeau .item__content {
    margin-bottom: 3rem;
    text-align: center;
  }
  .home-carte-cadeau .carte-cadeau__title::before {
    content: none;
  }
  .home-carte-cadeau .item__card {
    order: 2;
    margin: 1rem 0;
  }

  .home-carte-cadeau .item__content p {
    font-size: rem(26);
  }
  .home-carte-cadeau .item__content .item__title {
    font-size: rem(30);
  }
  .home-carte-cadeau .item__content li {
    font-size: rem(26);
  }
}

@include breakpoint(550) {
  .home-carte-cadeau {
    padding: 3rem 0 5rem 0;
  }
  .home-carte-cadeau .item__content {
    margin-bottom: 2rem;
  }

  .home-carte-cadeau .item__content p {
    font-size: rem(26);
  }
  .home-carte-cadeau .item__content .item__title {
    font-size: rem(30);
  }
  .home-carte-cadeau .item__content li {
    font-size: rem(26);
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
