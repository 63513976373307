.hk-rating {
  .hk-rate-star {
    background-size: 100% auto;
    // background-image: url("/images/icons/star.gif") !important;
  }

  .hk-rate-star.state-half {
    background-position: 0 20%;
  }
  .hk-rate-star.state-empty {
    background-position: 0 40%;
  }
}

// =========================================================== List

.hk-rating.hk-rating--list {
  margin: 0;
  display: block;
  text-align: left;

  i {
    color: var(--star);
    font-size: 14px;
  }

  .rating__score {
    display: none;
    font-size: rem(14);
    font-weight: bold;
  }

  .rating__title {
    display: block;
    font-size: rem(12);
    color: var(--color-1);
    padding-left: 4px;
    line-height: 1;
    text-decoration: underline;
    font-weight: 500;
    text-align: left !important;
  }
}

// =========================================================== Détails

.hk-rating.hk-rating--details {
  margin: 1rem 0;
  display: block;

  i {
    color: var(--star);
    font-size: 18px;
  }

  .rating__score {
    font-size: rem(18);
    font-weight: bold;
  }

  .rating__title {
    margin-left: 6px;
    font-size: rem(14);
    font-weight: 500;
    color: var(--color-1);
    text-decoration: underline;

    .title {
      margin: 0 2px;
    }
  }

  .hk-rating a:hover {
    .title {
      border-bottom: 1px solid var(--color-2);
    }
  }
}

// ==================================================================== HEADER

.header__reviews {
  margin: 1rem 0 0 1rem;
  padding: 1rem;
  position: absolute;
  bottom: 5rem;
}

// ==================================== MOBILE

.reviews--mobile {
  display: none;
  transition: transform 0.3s;
}

.reviews--active {
  transform: translateY(100px);
}

// ==================================== EO MOBILE

.reviews__content {
  @include flexbox;
  @include align-items(center);
  gap: 0.5rem;
}

.reviews__rating {
  position: relative;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 70px;
  background-color: var(--background-dark);
  border: 2px solid var(--star);
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    border: 1px solid var(--star);
    border-radius: 70px;
  }
  i {
    display: block;
    margin-top: 10px;
    font-size: 1.2rem;
    color: var(--star);
  }

  .rating__value {
    color: var(--star);
    font-weight: 500;
    font-size: 1.2rem;
  }
}

.reviews__stars {
  .stars {
    @include flexbox;
    @include align-items(center);
  }
  i {
    font-size: 1.2rem;
    color: var(--star);
  }
  .reviews__value {
    border-bottom: 2px solid var(--star);
    font-size: 0.9rem;
  }
}

@include breakpoint(1440) {
  .header__reviews {
    margin: 0;
  }
}

@include breakpoint(1280) {
  .header__reviews {
    padding: 0.5rem 1rem;
  }
}

@include breakpoint(1180) {
  .reviews__rating {
    width: 60px;
    height: 60px;
  }
  .reviews__stars i {
    font-size: 1rem;
  }
  .reviews__stars .reviews__value {
    font-size: 0.8rem;
  }
  .reviews__rating i {
    font-size: 1rem;
  }
  .reviews__rating .rating__value {
    font-size: 0.9rem;
  }

  .header__reviews {
    display: none;
  }

  .reviews--mobile {
    display: block;
    // position: fixed;
    // z-index: 100;
    // bottom: 1rem;
    // left: 1rem;
  }
}

// ====================================================================== EO HEADER

// ================================================ DERNIERS AVIS HOME

.home__reviews {
  @extend %bg-home;
  text-align: center;

  .card__review {
    text-align: initial;
    width: 245px;
    // height: 350px;
    background-color: var(--light);
    border-radius: 10px;
    overflow: hidden;
    .review__value {
      margin-top: 1rem;
      text-align: center;
      font-size: 1rem;
      color: var(--star);
    }

    .review__name {
      display: block;
      text-align: center;
      margin: 0.5rem 0 0rem 0;
      font-family: var(--font-titre);
    }

    .review__body {
      position: relative;
      margin: 3rem 0 4rem 0;
      padding: 0 1rem;

      i {
        position: absolute;
        z-index: 0;
        display: block;
        color: var(--gray-1);
        font-size: 3rem;
        top: -3rem;
        left: 1rem;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.5;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        min-height: 110px;
      }
    }

    .review__product {
      position: relative;
      z-index: 1;
      @include deco-before(30px);
      background-color: var(--color-1);
      @include flexbox;
      @include align-items(center);
      gap: 0.5rem;
      padding: 0 1rem 1rem 0.8rem;

      &::before {
        opacity: 1;
      }

      img {
        display: block;
        width: 40px;
        min-width: 40px;
        height: 40px;
        object-fit: cover;
        object-position: center;
        border-radius: 50px;
      }

      .review__product__name {
        color: var(--light);
        font-size: 0.8rem;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .btn {
    margin-top: 4rem;
  }
}

// ================================================ EO DERNIERS AVIS HOME

@include breakpoint(680) {
  .reviews--mobile {
    position: fixed;
    z-index: 100;
    bottom: 1rem;
    left: 1rem;
    background: var(--light);
    padding: 0 1.5rem 0 0;
    border-radius: 50px;
    box-shadow: 0 10px 21px var(--gray-2);
  }
  .page-produits .reviews--mobile {
    display: none;
  }
}
