.breadcrumb {
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // margin: 2rem 0;

  //   ---------------------------- arrow
  .divider i {
    margin: 0 rem(8);
    font-size: rem(12);
  }

  li {
    text-transform: capitalize;
    font-size: 0.95rem;
  }

  .arian__title {
    display: none;
  }

  .pathway:hover {
    transition: all 0.2s;
    color: var(--color-2);
    // border-bottom: 1px solid var(--color-2);
  }

  .active {
    font-weight: bold;
  }
}

// ================================================================= Page list

.breadcrumb--list {
  margin: 0.5rem 0 2rem 0.5rem;
}

// ================================================================= Page détails

.breadcrumb--details {
  margin: 1rem 0 2rem 0;
  padding: 12px 0 12px 38px;
  // border-width: 1px 0 1px 0;
  // border-style: solid;
  // border-color: var(--gray-3);
  background-color: var(--opacity-1);
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
  .breadcrumb--list {
    margin: 0 0 2rem 10px;
  }
}

@include breakpoint(1180) {
  .breadcrumb--details {
    margin-top: 75px;
    font-size: 0.9rem;
  }
}

@include breakpoint(1180) {
  .breadcrumb--list {
    margin: 0 0 1rem 10px;
  }
}

@include breakpoint(980) {
  .breadcrumb--details {
    margin-top: 60px;
    font-size: 0.8rem;
  }
}

@include breakpoint(780) {
  .breadcrumb--details {
    margin-top: 40px;
    padding: 5px 2rem;
  }
}

@include breakpoint(550) {
  .breadcrumb--list {
    margin: 0 0 0.5rem 5px;
    font-size: 0.8rem;
  }
  .breadcrumb--details {
    padding: 5px 0.5rem;
  }
  .breadcrumb .divider i {
    margin: 0 0.2rem;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
