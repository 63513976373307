.popover {
  display: none !important;
}

%form {
  display: block;
  position: relative;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  max-width: 100% !important;
  width: 100% !important;
  //----------------------------- STYLE
  font-family: var(--font-normal);
  background-color: var(--light);
  color-scheme: light;
  box-shadow: none;
  color: var(--dark);
  border: 1px solid var(--gray-4);
  border-radius: 8px;
  font-size: rem(16) !important;
  // padding: 0 rem(50) 0 rem(16);
  padding-left: 15px;
  padding-top: 4px;
  transition: all 0.15s;

  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 0.05);
    // border: 1px solid var(--color-1);
  }

  &:focus {
    // box-shadow: 4px 8px 16px rgba($color: #000000, $alpha: 0.08);
    border-color: var(--color-2);
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-background-clip: text;
  font-size: rem(16) !important;
  color: var(--dark);
}

input:-webkit-autofill::first-line {
  font-size: rem(16) !important;
  color: var(--dark);
}

input {
  @extend %form;
  height: 60px !important;
}

// ---------------------------------------------------- PASSWORD

// --- JS => login

.form__password {
  position: relative;

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    z-index: 10;
    right: rem(15);
    bottom: 0;
    font-size: 1.4rem;
    cursor: pointer;
  }
}

// ------------------------------------------------ checkbox

input[type="checkbox"] {
  position: relative;
  // background-color: var(--gray-7);
  display: inline-flex;
  vertical-align: middle;
  border: 1px solid var(--gray-2);
  padding: 0 !important;
  box-shadow: none;
  width: 22px !important;
  height: 22px !important;
  border-radius: 3px;
  cursor: pointer;

  &:checked {
    &::after {
      content: "✔";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 22px;
      text-align: center;
      font-size: 1rem;
      color: var(--color-2);
      font-weight: bold;
    }
  }
  &:hover {
    // border: 0;
  }
  &:focus {
    // border: 0;
    // background-color:var(--light);
  }
}

// ------------------------------------------------ radio

input[type="radio"] {
  position: relative;
  // background-color: var(--color-2);
  display: inline-flex;
  vertical-align: middle;
  border: 0;
  padding: 0 !important;
  box-shadow: none;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid var(--gray-4);

  &:checked {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40%;
      height: 40%;
      border-radius: 50px;
      background-color: var(--color-2);
      text-align: center;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
  &:hover,
  &:focus {
  }
}

// ----------------------- radio hikashop

.control-group .hkradio label {
  position: initial;
  height: initial;
  top: 0;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  pointer-events: initial !important;
}

// ------------------------------------------------ eo radio

// ======================================================================== FOX CONTACT
// ------------------------ suppression des asterix
.required::after,
.asterisk::after {
  content: none !important;
}

// ------------------------ suppression des floats
.fox-item .controls {
  float: none !important;
}

// ======================================================================== EO FOX CONTACT

.hikam_options {
  // background: white;
  // padding: 1rem 1rem 0.5rem 1rem;
  // margin-bottom: 1rem;
  // box-shadow: 0 10px 21px rgba($color: #000000, $alpha: 0.08);
  // border-radius: var(--radius);
}

.form-group,
.control-group,
.hkform-group {
  position: relative;
  margin-bottom: 1.5rem !important;

  label {
    position: absolute;
    z-index: 1;
    // pointer-events: none;
    left: 0;
    margin-left: 12px;
    top: -8px;
    height: 20px;
    line-height: 1;
    background-color: var(--light);
    font-size: rem(15);
    // color: $gray-8;
    // font-weight: 300;
    text-align: left !important;
    padding: 0 10px !important;
    width: auto !important;
  }

  input {
    padding-left: 15px;
  }
}

dl.hika_options.large > dd,
dd {
  margin-bottom: 1.5rem;
  margin-left: 0 !important;
}

.hika_options.large dt,
dt {
  margin-bottom: 6px;
  width: 100% !important;
  display: block;
  float: none;
  overflow: unset !important;
  text-align: left;
  text-overflow: unset;
  white-space: unset;
  line-height: initial;
  clear: unset;
  label {
    // text-transform: uppercase;
    // font-size: 0.8rem;
    margin-left: 5px;
    font-weight: 500;
  }
}

.fox-column {
  min-height: initial !important;
}

fieldset {
  border: 0;
}

legend {
  font-family: var(--font-titre);
  line-height: 1;
  font-size: rem(34);
  margin-bottom: 2rem;
  background-color: var(--light) !important;
}

label {
  margin-left: 7px;
}

// ----------------------- jquery validate

label.error {
  position: absolute;
  bottom: 2px !important;
  top: initial !important;
  padding: 0 5px !important;
  background: transparent;
  left: 0;
  margin: 0;
  color: var(--error);
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  height: initial;
  text-transform: initial;
}

// ----------------------- eo jquery validate

#hikashop_address_address_title label {
  position: relative;
}

.star,
.optional,
.field_required {
  display: none;
}

.btn--submit {
  width: 100%;
}

// --------------------------- SELECT - supprimer arrow sur ie
select::-ms-expand {
  display: none;
}
select {
  @extend %form;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: rem(60);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMXJlbSIgd2lkdGg9IjFyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTggMTEuNWExIDEgMCAwMS0uNzEtLjI5bC01LTVhMSAxIDAgMDExLjQyLTEuNDJMOCA5LjA5bDQuMjktNC4zYTEgMSAwIDExMS40MiAxLjQybC01IDVhMSAxIDAgMDEtLjcxLjI5eiIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 1.3rem;
  padding-right: 1.6rem;
  background-position: 98% center;
}

textarea {
  @extend %form;
  padding-top: 10px !important;
  max-width: 100%;
  max-height: 250px;
  line-height: 1.5 !important;
  resize: none;
}

// --------------------------- btn calendar
#product_sale_start_btn,
#product_sale_end_btn {
  padding: 5px 10px;
}

.icon-calendar::before {
  // @include icon("\f274");
  // font-size: 1.3rem;
  content: "Choisir une date";
}
// --------------------------- eo btn calendar

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-1);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-1);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-1);
}

.hikashop_field_required_label,
.hkform-group.control-group.hikashop_registration_required_info_line {
  display: none !important;
}

@include breakpoint(1440) {
  %form {
    border-radius: 8px;
  }
  input,
  select {
    height: 50px !important;
  }
}

@include breakpoint(550) {
  %form {
    font-size: rem(14) !important;
  }
  legend {
    font-size: 1.7rem;
  }
  input,
  select {
    height: 45px !important;
  }
  label {
    margin-left: 0;
  }
  .form-group,
  .control-group,
  .hkform-group {
    label {
      font-size: 0.7rem;
      letter-spacing: 0;
      top: -5px;
    }
  }
  .form__password i {
    font-size: 1.1rem;
  }
}
