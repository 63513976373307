.page-home {
  .article {
    #system-message-container {
      display: none !important;
    }
  }
}

//   ------------------------------------------------------------------------------------------------------- HERO
.home-hero {
  position: relative;

  .swiper-wrapper {
    width: 100%;
    height: 550px;
    // margin-bottom: rem(30);
  }

  .swiper__slide {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      //   display: block;
    }
  }

  .hero__content {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
    width: 50%;
    // background-color: rgba($color: var(--light), $alpha: 0.4);
    color: var(--white);
  }

  .hero__title {
    display: block;
    font-size: rem(25);
  }

  .hero__description {
  }

  // ------------------------------------------------------------- navigation
  .swiper-button-prev,
  .swiper-button-next {
    color: var(--white);
    font-weight: bold;
    transform: scale(1.1);

    &:hover {
      color: var(--color-1);
      // text-shadow: 0 15px 15px rgba($color: #000000, $alpha: 1);
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 30px;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 30px;
  }

  // ------------------------------------------------------------- pagination

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    // bottom: calc(30px - 8px);
  }

  .swiper-pagination-bullet {
    margin: 0 rem(10) !important;
    width: 16px;
    height: 16px;
    background-color: var(--light);
    opacity: 0.6;
    // box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);

    &.swiper-pagination-bullet-active {
      width: 20px;
      height: 20px;
      background-color: var(--color-1);
      opacity: 1;
    }
  }
}
//   ------------------------------- EO HERO

//   ------------------------------------------------------------------------------------------------------- ACTUS
.page-home .article {
  // max-width: 1000px;
  margin: 4rem auto;

  .blog__title {
    text-align: center;
    margin-bottom: 2rem;
  }
}
.page-home .blog-featured {
  display: grid;
  grid-template-columns: repeat(auto-fit, 325px);
  grid-auto-flow: dense;
  grid-gap: 20px;
  justify-content: center;
  //   ---------------------- item
  .items-row {
    position: relative;
    @include shadow-1;
    min-height: 450px;
    border-radius: 12px;
    overflow: hidden;
    padding-bottom: 1rem;
  }
  //   ---------------------- icon modifier
  .icons {
    position: absolute;
    top: 0;
    left: 0;
  }
  //   ---------------------- image
  .item-image {
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  //   ---------------------- image
  .item-title {
    font-size: rem(18);
    padding: 0 1rem;
    color: var(--color-1);
    font-weight: 500;
  }
  //   ---------------------- texte
  p {
    padding: 7px 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@include breakpoint(550) {
  .page-101 .blog-featured {
  }
}
//   -------------------------------------------------------------------------------------------------------  EO ACTUS

//   ------------------------------------------------------------------------------------------------------- REALISATIONS

.home-realisations {
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
    height: 550px;
  }
  .swiper-slide {
    width: 300px;
    height: 400px;
    background-color: salmon;
  }
}

//   ------------------------------------------------------------------------------------------------------- EO REALISATIONS
