.hikashop__checkout__payement__header {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-flow(row wrap);

  img {
    max-width: 40px;
  }
}
.hikashop_checkout_payment {
  margin-bottom: 2rem;
  legend {
    display: none;
  }
  tbody td {
    position: relative;
    display: block;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 7px;
  }
  label {
    margin: 0;
  }

  .hikashop_checkout_payment_name {
    @include flexbox;
    @include align-items(center);
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    &::before {
      margin-right: 7px;
      font-size: 1.5rem;
    }
  }
  .hikashop_checkout_payment_name::before {
    @include icon("\f09d");
  }

  .hikashop_checkout_payment_description {
    margin-top: 0.5rem;
    padding-right: 0;
  }

  .hika__checkout__paiement__footer {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-flow(row wrap);

    span {
      color: var(--gray-8);
      text-transform: uppercase;
    }
  }

  .btn--paiement {
    display: none;
  }
}

.hikashop_checkout_page_step3 {
  .hika__address__delivery,
  .hika__address__order {
    box-shadow: none;
    border: 1px solid var(--gray-6);
  }

  .hikashop_checkout_shipping_address_block,
  .hikashop_checkout_billing_address_block {
    legend {
      margin-bottom: 1rem;
      margin-left: 0;
    }
    label {
      display: none;
    }
  }
}

input.hikashop_checkout_payment_radio {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent !important;
  border: 1px solid var(--gray-3);
  // border: 2px solid var(--color-2);
  border-radius: 7px;
  // pointer-events: none;

  &:checked {
    border: 1px solid var(--color-1);
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    // border: 1px solid var(--gray-2);
    border-radius: 7px;
  }
  &:checked::before {
    // box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.08);
  }
  &:checked::after {
    // display: none;
    @include icon("\f058");
    background-color: transparent;
    width: 30px !important;
    height: 30px;
    line-height: 30px !important;
    text-align: center;
    bottom: 0px !important;
    right: 0px !important;
    top: initial !important;
    left: initial !important;
    color: var(--color-2);
    font-size: 2rem;
  }
}

@include breakpoint(980) {
  .hikashop_checkout_page_step3 .hikashop_checkout_address {
    margin-bottom: 2rem;
  }
}
