.page-login {
  .header__top {
    display: none;
  }
  .main {
    padding: 0;
    margin: 0;
  }

  .header,
  .footer {
    display: none;
  }
  // --------------------------------------------------- RETOUR
  .btn--back {
    position: absolute;
    right: 1rem;
    top: 10px;
  }
}

// --------------------------------------------------------------------------------------- CREER UN COMPTE

.hikashop_user_registration_page {
  .login__container {
    height: 100vh;
  }
}

.hika__registration {
  .hkform-group {
    display: block !important;
  }
  label {
  }

  .hkc-sm-8 {
    width: 100% !important;
  }
  // --------------------------------------------------- civilité
  .hikashop_registration_address_title_line {
    // max-width: 100px;

    .hkc-sm-8 {
      @include flexbox;
      gap: 15px;
    }

    .hkcontrol-label {
      position: initial;
      height: initial;
      top: 0;
      left: 0;
      padding: 0 !important;
      margin: 0 0 0 10px !important;
    }

    span {
      margin-left: 4px;
    }
  }
}

// --------------------------------------------------- LOGO
.login__logo {
  display: none;
  position: absolute;
  z-index: 100;
  left: 1rem;
  top: 10px;

  img {
    max-width: 150px;
    height: auto;
  }
}

.login__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

// --------------------------------------------------- LOGIN
.login__wrapper {
  position: relative;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  // height: 100vh;
}

.login__form {
  max-width: 550px;
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  margin: 3rem 0;

  .btn {
    width: 100%;
  }

  p {
    text-align: center;
    margin-bottom: 2rem;
  }
}

// login popup
// .login__form {
//   width: 500px;
// }
.login__form--connexion {
  height: 90vh;
  margin: 0;
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(center);
  max-height: 90vh;
  overflow: auto;
}

//------------------------------------------------------------------ TITRE SE CONNECTER

.title__h2 {
  text-align: center;
}

.reset__password {
  display: block;
  padding: 3px 0;
  font-size: rem(16);
  text-decoration: underline;
  margin-left: rem(10);
}

// ------------------------------------------------------------------------------------------------- PAGE INIT MDP

// ------------------------------------------------------------------------------------------------- PAGE LOGIN

//------------------------------------------------------------------ SEPARATOR
.login__separator {
  position: relative;
  text-align: center;
  margin: 1rem 0;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 1px;
    background-color: var(--gray-7);
  }
}

.separator__title {
  position: relative;
  background-color: var(--light);
  padding: 0 rem(20);
  text-align: center;
  font-size: rem(16);
  font-weight: 500;
  // color: $gray-8;
}

//------------------------------------------------------------------ LOGIN SOCIAL

.sourcecoast.login {
  // margin-bottom: 1rem;
  .row-fluid {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    display: block;
  }
}

.social-login,
.login__link {
  margin-bottom: 1rem;
  border-radius: 50px;
  border: 2px solid transparent;
  box-shadow: 0 10px 21px var(--gray-1);
  transition: all 150ms;
  img {
    max-width: 28px;
  }

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

//--------------------------------------- LOGIN LINK

.login__link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  text-align: center;
  padding: 0 10px;
  height: 55px;
}
//--------------------------------------- LOGIN TITLE
.social-login a::after {
  font-size: 0.82rem;
  // font-weight: 500;
  margin-left: rem(7);
  transition: all 150ms;
  font-weight: 500;
}

//--------------------------------------- Google

.social-login {
  a {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    padding: 0 10px;
    height: 55px;
  }
  &.google a::after {
    content: "Continuer avec Google";
    color: var(--dark);
  }
  &.facebook a::after {
    content: "Continuer avec Facebook";
    color: var(--white);
  }
}

.social-login.google {
  background-color: transparent;
  border: 1px solid var(--gray-2);

  &::before {
    // background-image: url("/images/icons/icon-google.png");
  }

  &:hover,
  &:focus {
    border: 1px solid var(--gray-6);
  }
}
//--------------------------------------- Facebook

.social-login.facebook {
  background-color: #1b74e4 !important;

  &::before {
    // background-image: url("/images/icons/icon-facebook.svg");
  }

  &:hover,
  &:focus {
    // border: 2px solid #1a6cd1;
    background-color: #1860b9;
    box-shadow: none;
  }
}

// --------------------------------------------------- IMAGE
.login__img {
  position: fixed;
  width: 50%;
  top: 0;
  right: 0;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

//--------------------------------------- creer un compte

.login__account {
  background-color: transparent;
  border: 1px solid var(--gray-2);

  .login__link__title {
    color: var(--dark);
  }

  i {
    font-size: rem(30);
    color: var(--dark);
  }

  &:hover,
  &:focus {
    // background-color: rgb(29, 27, 27);
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1440) {
}

@include breakpoint(1180) {
}

@include breakpoint(980) {
  .login__form--connexion {
    height: initial;
    margin: 3rem 0;
  }
  .login__container {
    display: block;
    height: auto;
  }
  .login__img {
    @include hidden;
  }
  .login__form {
    max-height: initial;
    overflow: initial;
  }
}

@include breakpoint(780) {
}

@include breakpoint(550) {
  .sourcecoast.login .row-fluid {
    display: block !important;
  }
  .login__form {
    padding: 0 1rem;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
