.admin__nav__top {
  border-bottom: 1px solid var(--gray-7);
  padding: 8px 0;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-flow(row wrap);
  gap: 10px;
  a {
    i {
      margin-right: 7px;
    }
  }
  .link__guide {
    // color: var(--color-2);
    // font-weight: bold;
    // text-transform: uppercase;
    // text-decoration: underline;
    display: none;
  }
}

@include breakpoint(550) {
  .admin__nav__top a {
    font-size: 0.9rem;
  }
}

@include breakpoint(440) {
  .admin__nav__top {
    gap: 5px;
  }
  .admin__nav__top a {
    font-size: 0.8rem;
    i {
      margin-right: 4px;
    }
  }
}
