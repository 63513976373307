.blog__items {
  max-width: 980px;
}

.blog__item {
  margin-bottom: 1rem;
  border: 1px solid var(--gray-2);
  border-radius: 8px;
  padding: 0.5rem;
  backdrop-filter: blur(8px);
}

.blog__link {
  @include flexbox;
  display: grid;
  grid-template-columns: minmax(140px, 325px) minmax(350px, 1fr);
  gap: 1rem;
}

.blog__img {
  // min-width: 325px;
  // max-height: 275px;
  img {
    display: block;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
}

.blog__title {
  font-family: var(--font-normal);
  font-weight: 500;
  font-size: 1.25rem;
  font-size: clamp(1.1rem, 1.5vw, 1.5rem);
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.blog__time {
  font-weight: 300;
  @include flexbox;
  @include align-items(center);
  gap: 1rem;
}

.blog__created {
  @include inline-flex;
  @include align-items(center);
  gap: 0.5rem;
}

.blog__modified {
  position: relative;
  color: var(--gray-7);
  padding-left: 1rem;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: var(--gray-3);
  }
}

.blog__intro {
  margin-top: 1.5rem;
}

@include breakpoint(650) {
  .blog__link {
    display: block;
  }
  .blog__img {
    margin-bottom: 1rem;
  }
  .blog__img img {
    max-height: 250px;
  }
  .blog__intro {
    margin-top: 0.5rem;
  }
}

// // ------------------------------------------------------------------------------------ blog

// .page-blog h1 {
//   margin: 2rem 0;
// }

// .page-blog .blog {
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-gap: 20px;

//   .items-row {
//   }

//   .item,
//   .item img {
//     height: 100%;
//   }

//   .item {
//     display: grid;
//     grid-template-columns: 0.8fr 1fr;
//     grid-gap: 20px;
//     overflow: hidden;
//     margin-bottom: 3rem;
//     @include shadow-1;
//     border-radius: 7px;
//   }

//   h2 {
//     color: var(--color-1);
//   }

//   .item img {
//     display: block;
//     width: 100%;
//     object-fit: cover;
//     object-position: center;
//   }

//   .item__body {
//     padding: 1rem;

//     p {
//       // display: -webkit-box;
//       // overflow: hidden;
//       // -webkit-line-clamp: 4;
//       // -webkit-box-orient: vertical;
//     }
//   }
// }

// @include breakpoint(980) {
//   .page-blog .blog {
//     .item {
//       grid-template-columns: 1fr;
//       grid-gap: 0;
//     }
//     .item {
//       height: auto;
//     }
//     .item img {
//       height: 250px;
//     }
//     .item__body {
//       padding: 0 1rem 1rem 1rem;
//       p {
//         -webkit-line-clamp: 2;
//       }
//     }
//   }
// }

// // ------------------------------------------------------------------------------------ détails
// .page-blog {
//   // ------------------------------------- header
//   .article__header {
//     position: relative;
//     margin-bottom: 2rem;

//     // ----------------------- titre
//     .page-header {
//       position: absolute;
//       z-index: 10;
//       width: 100%;
//       text-align: center;
//       padding: 0 4rem;
//       top: 50%;
//       transform: translateY(-50%);
//     }

//     .page-header h2 {
//       color: var(--white);
//       font-size: rem(50);
//       text-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.7);
//     }

//     // ----------------------- image
//     .item-image {
//       position: relative;
//     }

//     .item-image::before {
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       z-index: 1;
//       width: 100%;
//       height: 100%;
//       background-color: rgba($color: #000000, $alpha: 0.2);
//     }
//     .item-image img {
//       display: block;
//       width: 100%;
//       height: 400px;
//       object-fit: cover;
//       object-position: center;
//     }
//   }
//   // ------------------------------------- body
//   .article__body {
//   }
// }
