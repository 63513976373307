// ----------- page listing reviews

.avis__warning {
  text-align: center;
  span {
    font-style: italic;
    font-size: 0.9rem;
    font-size: clamp(0.7rem, 0.85vw, 0.85rem);
    font-weight: 500;
  }
}

.page-reviews .main {
  overflow: hidden;
}

.reviews__listing {
  position: relative;
  z-index: 10;
  .cards {
    max-width: 980px;
    margin: auto;
  }

  .card {
    position: relative;
    // background-color: var(--light);
    // box-shadow: var(--shadow-2);
    margin-bottom: 2rem;
    border-radius: 15px;
    padding: 1rem;
    text-align: center;
    border: 1px solid var(--gray-1);
    // background-color: var(--gray-1);
    backdrop-filter: blur(8px);

    .fa-quote-right {
      position: absolute;
      z-index: 1;
      font-size: clamp(1.2rem, 3vw, 3rem);
      top: 0.5rem;
      left: 0.5rem;
      color: var(--gray-1);
    }
  }

  .card__name {
    font-family: var(--font-titre);
    font-size: 1.6rem;
  }
  .card__value {
    color: var(--star);
    font-size: 1.3rem;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    gap: 2px;
    margin: 0.5rem 0;
  }
  .card__body {
    margin: 2rem 0;
    p {
      font-size: clamp(1rem, 1.15vw, 1.15rem);
    }
  }

  .card__product {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    gap: 1rem;
    margin-bottom: 1rem;

    img {
      width: 75px;
      height: 75px;
      display: block;
      object-fit: cover;
      object-position: center;
      border-radius: 50px;
    }
  }

  .card__product__name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .card__date {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    color: var(--gray-5);
  }
}
