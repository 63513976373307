.espace-client__menu {
  // display: none;
  margin-bottom: 2rem;
  .item-125.parent,
  .nav-child {
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    gap: 10px;
  }

  .nav-child li.active a {
    color: var(--color-2);
    border: 1px solid var(--color-2);
    // border-bottom: 1px solid var(--color-2);
  }

  a:hover {
    color: var(--color-2);
  }

  a {
    font-size: rem(16);
    @include flexbox;
    @include align-items(center);
    padding: 8px 16px;
    text-transform: uppercase;
    // background-color: var(--gray-1);
    border: 1px solid var(--gray-6);
    border-radius: 7px;
  }

  a::before {
    @include icon;
    display: inline-block;
    width: 30px;
    text-align: center;
    margin-right: 2px;
  }

  // ----------------- espace client
  .item-125.parent > a::before {
    content: "\f00a";
  }
  // ----------------- dashboard
  .item-125 > a::before {
    content: "\e066";
  }
  // ----------------- profil
  .item-128 a::before {
    content: "\f4fe";
  }
  // ----------------- boutique
  .item-236 a::before {
    content: "\f54e";
  }
  // ----------------- commandes
  .item-238 a::before {
    content: "\f291";
  }
  // ----------------- adresses
  .item-239 a::before {
    content: "\f3c5";
  }
  // ----------------- favoris
  .item-407 a::before {
    content: "\f004";
  }
}

@include breakpoint(1280) {
  .espace-client__menu a {
    font-size: rem(14);
    padding: 8px 8px;
  }
}

@include breakpoint(980) {
  .espace-client__menu a {
    font-size: rem(12);
    padding: 6px;
  }
}

@include breakpoint(768) {
  .espace-client__menu {
    display: none;
  }
}
