// hide adresse livraison et facturation dernière étape

#hikashop_checkout_address_3_1 {
  display: none;
}

.hikashop_checkout_page_step2 {
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }

  .sourcecoast.login {
    width: 100%;
    margin-bottom: 2rem;

    .row-fluid {
      max-width: 500px;
      margin: auto;
    }
  }

  .buttons__top {
    // display: none;
  }

  .hika__checkout__block {
    // margin-top: 3rem;
  }

  .hika__checkout__block--active {
    .hika__checkout__wrapper {
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 1px solid var(--gray-6);
    }
  }
}

// ==================== étape 2, cache les livraisons pour laisser uniquement les messages d'erreurs si la personne change de pays

#hikashop_checkout_shipping_2_5 {
  // @include hidden;
  padding: 0;
  border: 0;
  .shipping__header,
  .hikashop_shipping_group {
    display: none;
  }
}

// ------------------------------------------------------------------------------------------ ADRESSES

// ------------------------------ NOUVELLE ADRESSE
.hikashop_checkout_address_same {
  .hkc-sm-4 {
    display: none;
  }
  label {
    position: relative;
    pointer-events: initial;
    font-size: 0.9rem;
  }
  input {
    margin-right: 5px;
  }
}

.address__btn__bottom {
  @include flexbox;
  @include align-items(center);
  gap: 25px;
  @include justify-content(center);
}

// ------------------------------ EO NOUVELLE ADRESSE

// ------------------------------ MODE DE LIVRAISON

.shipping__header {
  @include flexbox;
  @include align-items(center);
  @include flex-flow(row wrap);
  @include justify-content(space-between);
  gap: 0.5rem;
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 0;
  }

  .btn--show-shipping {
    i {
      margin: 0 4px;
    }
  }
}

// ========================================================= EDITION ADRESSE

.hikashop_checkout_checkout_address_block {
  max-width: 550px;
  position: relative;
  margin: 0 auto 1rem auto;
  border: 1px solid var(--gray-6);
  padding: 1rem 2rem 0 2rem;
  border-radius: 15px;

  legend {
    text-align: center;
    margin-bottom: 2rem;
    margin-left: 0 !important;
    font-size: 3rem;
    background-color: var(--light);
    padding: 0 1rem;
  }

  .hkform-horizontal .hkform-group {
    display: block;
  }
  .hkc-sm-8 {
    @include flexbox;
    @include align-items(center);
    gap: 1rem;
    margin-top: 0.5rem;
    width: 100% !important;
  }
}

// ========================================================= EO EDITION ADRESSE

.hikashop_checkout_shipping {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 2px solid var(--gray-6);

  .hikashop_shipping_group {
    display: none;
    box-shadow: 4px 8px 16px var(--gray-1);
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    border-radius: 10px;
  }

  legend {
    display: none;
  }

  .hika__checkout__shipping__header {
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    @include justify-content(space-between);
    gap: 0.5rem;
    // margin-bottom: 1rem;
  }

  .hikashop_shipping_group_name {
    font-family: var(--font-titre);
    font-size: 2rem;
    line-height: 1;
    svg {
      // display: none;
    }

    .vendor__title {
      font-family: var(--font-normal);
      font-size: 1.2rem;
    }
  }

  .hikashop_shipping_products {
    @include flexbox;
    @include align-items(center);
    gap: 10px;
    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      // image-rendering: pixelated;
      object-fit: cover;
      object-position: center;
      box-shadow: 4px 8px 16px var(--gray-1);
    }
  }
}

// ----------------------- choix de livraison
.hika__shipping__method {
  .hikashop_checkout_shipping_radio,
  .hikashop_checkout_shipping_name {
    display: none;
  }
  label {
    margin: 0;
  }
  .hikashop_checkout_shipping_cost {
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

// ------------------------------ EO MODE DE LIVRAISON

.hikashop_checkout_address,
#hikashop_user_addresses_show {
  .hk-container-fluid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    &::before,
    &::after {
      content: none;
    }
  }
  .hkc-sm-6 {
    padding: 1rem !important;
    border-radius: 10px;
    margin: 0;
    width: 100%;
    // ------------------------------- bouton supprimer adresse

    .btn--danger {
      display: none;
    }

    // ------------------------------- eo bouton supprimer adrese
  }

  // ------------------------------- livraison
  .hika__address__delivery {
    background-color: var(--light);
    box-shadow: 4px 8px 16px var(--gray-1);
    @include order(1);
    border: 1px solid var(--gray-1);
  }
  // ------------------------------- facturation
  .hika__address__order {
    // border: 1px solid var(--gray-6);
    @include order(2);
  }

  fieldset {
  }

  .hika_address_field {
    legend {
      // margin-bottom: 1rem;
      margin-left: 15px;
    }

    .form-group {
      margin-bottom: 1rem;
    }
  }

  // -------------------------------

  .address__title {
    text-transform: uppercase;
  }

  .hika_address_display {
    background-color: var(--gray-1);
    padding: 10px 0 10px 15px;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 0.9rem;
    // font-weight: 500;
    line-height: 1.7;
  }

  // ------------------------------- boutons edition
  .hika_edit {
    margin-top: 1rem;
    text-align: left;

    .btn {
      margin: 0 8px 8px 0;
    }
  }
}

// ------------------------------------------------------------------------------------------ LOGIN

.hikashop_checkout_login {
  //   display: none;
  //   margin-right: 2rem;

  .hk-container-fluid {
    @include align-items(stretch);
  }

  .hkc-lg-4,
  .hkc-lg-8 {
    width: calc(50% - 1rem) !important;
    box-shadow: 4px 8px 16px var(--gray-1);
    padding: 1rem 2rem 0 2rem;
    text-align: center;
    border: 1px solid var(--gray-1);
    border-radius: 10px;
  }
  .hkc-lg-4 {
    margin-right: 2rem;
  }
  .hkc-sm-8 {
    width: 100%;
    padding: 0;
  }

  .hikashop_login_remember_line {
    display: none !important;
  }

  .hikashop_login_password_line,
  .hikashop_login_forgot_password_line {
    margin-bottom: 0;
  }
  .hikashop_login_forgot_username_line {
    display: none !important;
  }
  .btn {
    margin-top: 1rem;
    width: 100%;
  }
}

@include breakpoint(1140) {
  .hikashop_checkout_page_step2
    .hika__checkout__block--active
    .hika__checkout__wrapper {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }
  .hikashop_checkout_shipping {
    margin-top: 3rem;
  }
}

@include breakpoint(880) {
  .hikashop_checkout_page_step2 .hika__checkout__block {
    margin-top: 1rem;
  }
  .hikashop_checkout_login .hkc-lg-4,
  .hikashop_checkout_login .hkc-lg-8 {
    width: 100% !important;
    margin: 1rem 0 1rem 0 !important;
  }
}

@include breakpoint(768) {
  .hikashop_checkout_address .hk-container-fluid {
    grid-template-columns: 1fr;
  }
  .hikashop_checkout_shipping {
    margin-top: 1rem;
  }
  .hikashop_checkout_shipping .hikashop_shipping_group_name {
    font-size: 1.5rem;
  }
  .hikashop_checkout_shipping .hikashop_shipping_group_name .vendor__title {
    font-size: 1rem;
  }
  .hika__shipping__method .hikashop_checkout_shipping_cost {
    font-size: 1.3rem;
  }
}

@include breakpoint(550) {
  .hikashop_checkout_shipping .hikashop_shipping_group_name .vendor__title {
    display: block;
    margin-bottom: 8px;
  }
  .hikashop_checkout_checkout_address_block {
    padding: 0 0.5rem;
  }
  .hikashop_checkout_checkout_address_block legend {
    font-size: 2rem;
  }
}

@include breakpoint(440) {
  .hikashop_checkout_address .hika_edit .btn {
    margin: 0 2px 8px 0;
  }
}
