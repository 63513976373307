.modal {
  position: fixed;
  opacity: 0;
  z-index: -1;
  // opacity: 1;
  // z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal__wrapper {
  position: absolute;
  max-width: 1000px;
  max-height: 80vh;
  overflow: auto;
  width: 100%;
  // max-height: 50vh;
  // overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: var(--light);
  padding: 2rem 1rem 1rem 1rem;
  border-radius: 15px;
  box-shadow: 5px 20px 41px var(--gray-3);
  transition: all 300ms;
  border: 1px solid var(--gray-4);

  .form-group {
    margin-bottom: 1rem;
  }

  .title__main {
    margin-bottom: 0;
  }
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  @include flexbox;
  @include align-items(center);
  font-size: rem(14);
  font-weight: 500;
  cursor: pointer;
  color: var(--color-1);

  i,
  svg {
    margin-left: rem(4);
    font-size: 1.5rem;
  }
}

// -------------------------------------------- HIKA POPUP

.hika__popup--cart {
  .modal__product {
    @include flexbox;
    @include flex-flow(row wrap);
    max-width: 550px;
    display: grid;
    gap: 2rem;
    grid-template-columns: 100px 1fr;
    text-align: left;
    margin: 2rem auto;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
    }

    .name {
      display: block;
      color: var(--color-1);
      font-size: clamp(0.9rem, 1.05vw, 1.05rem);
    }
    .qty {
      display: block;
      font-weight: 500;
      font-size: 0.85rem;
    }
    .price {
      font-weight: bold;
      font-size: clamp(1.1rem, 1.4vw, 1.4rem);
    }
    .modal__price__value {
      min-height: 35px;
    }

    // .modal__price__value,
    // .modal__img__value {
    //   display: block;
    //   opacity: 1;
    //   transition: 0.3s;

    //   &.active {
    //     opacity: 1;
    //   }
    // }
  }
  .modal__action {
    // @include flexbox;
    // @include align-items(center);
    // @include justify-content(center);
    background-color: var(--gray-1);
    padding: 1rem;
    border-radius: 15px;
    .action__infos {
      // @include flexbox;
      // @include align-items(center);
      // @include justify-content(center);
      // gap: 4px;
      margin-bottom: 1rem;
      color: var(--color-1);
      font-size: clamp(1rem, 1.2vw, 1.2rem);
      display: none;
    }
    .btn {
      margin: 0.5rem 1rem;
    }
  }

  .modal__total-qty,
  .modal__total-price {
    position: relative;
    display: inline-block;
    height: 28px;
    line-height: 24px;
    vertical-align: middle;
    margin: 0 2px;
    overflow: hidden;
    transition: 0.2s;
    font-weight: bold;
    border-radius: 50px;

    &.active {
      background-color: var(--gray-2);
    }

    &.active::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: "";
    }
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .modal__total-qty {
    min-width: 90px;
  }
  .modal__total-price {
    min-width: 90px;
  }
}

// ============================================= LOADER
// .modal__loader {
//   position: absolute;
//   z-index: -1;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background-color: var(--light);
//   opacity: 0;
//   transition: 0.2s;
// }
// .modal--loading.modal__loader {
//   opacity: 0.7;
//   z-index: 10;
// }

// .nb-spinner {
//   display: block;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
//   width: 50px;
//   height: 50px;
//   margin: -25px 0 0 -25px;
//   background: transparent;
//   border-top: 4px solid var(--color-1);
//   border-right: 4px solid transparent;
//   border-radius: 50%;
//   -webkit-animation: 1s spin linear infinite;
//   animation: 1s spin linear infinite;
// }

// @-webkit-keyframes spin {
//   from {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   to {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

// @keyframes spin {
//   from {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   to {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
// ============================================= EO LOADER
// -------------------------------------------- EO HIKA POPUP

//------------------------------------- MODAL ACTIVE
.body__modal--active {
  height: 100vh;
  overflow: hidden;
}

.modal--active {
  opacity: 1;
  z-index: 10000;
  transition: all 200ms;
  background-color: rgba($color: #000000, $alpha: 0.7);

  .modal__wrapper {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@include breakpoint(800) {
  .modal__wrapper {
    width: 95%;
    max-height: 90vh;
    // padding: 3rem 1rem 2rem 1rem;
    border-radius: 8px;
  }
}

@include breakpoint(550) {
  .hika__popup--cart .modal__product {
    grid-template-columns: 80px 1fr;
    gap: 1rem;
    margin: 1rem 0;
  }
  .hika__popup--cart .modal__product img {
    max-width: 80px;
    max-height: 80px;
  }
}
