.page-bijoux {
  .main {
    overflow: hidden;
  }

  #maincontent {
    max-width: 1200px;
    margin: auto;
  }

  .main__center {
    display: grid;
    grid-template-columns: minmax(400px, 680px) minmax(220px, 1fr);
    gap: 2rem;
  }

  .hikashop_products_listing {
    max-width: 1200px;
    margin: 0 auto 4rem auto;
  }

  .bijoux__content {
    display: none;
  }
}

.bijoux-content {
  text-align: center;
  margin: 4rem auto var(--section) auto;

  h1 {
    margin-bottom: 2rem;
  }

  .bijoux__infos {
    display: block;
    margin: 0 auto 3rem auto;
    font-size: 1.3rem;
    font-size: clamp(1.3rem, 2vw, 2rem);
    font-weight: 500;
    text-transform: uppercase;
    &::after {
      content: "";
      display: block;
      width: 80px;
      height: 4px;
      background-color: var(--color-1);
      margin: auto;
    }
  }

  .cards {
    margin-top: 2rem;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    @include flex-flow(row wrap);
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    max-width: 980px;
    margin: auto;
  }

  .card {
    max-width: 280px;
    // height: 350px;
    border: 2px solid var(--gray-1);
    box-shadow: 10px 20px 41px var(--gray-2);
    border-radius: 10px;

    a {
      display: block;
      padding: 2rem 1rem;
    }
  }

  .card:hover {
    border: 2px solid var(--gray-6);
    box-shadow: 10px 20px 41px var(--gray-4);
  }

  .card__img {
    &::before {
      content: "";
      display: block;
      background-color: var(--color-1);
      margin: auto;
      width: 150px;
      height: 150px;
      border-radius: 100px;

      background-size: cover;
      margin-bottom: 0.3rem;
    }
  }
  .card--femme .card__img::before {
    background-image: url("../../../images/categories/icons/femme.png");
  }
  .card--homme .card__img::before {
    background-image: url("../../../images/categories/icons/homme.png");
  }

  .card--enfant .card__img::before {
    background-image: url("../../../images/categories/icons/enfant.png");
  }

  .card__title {
    display: block;
    margin: 1.5rem auto 0rem auto;
    font-size: 1rem;
    font-size: clamp(0.8rem, 1.3vw, 1.3rem);
    font-weight: 500;
    // letter-spacing: 0.08rem;
    text-transform: uppercase;
    color: var(--color-1);
  }

  .card__name {
    display: block;
    // color: var(--white);
    // background-color: var(--gray-6);
  }
  .btn {
  }
}

@include breakpoint(780) {
  .page-bijoux .main__center {
    display: block;
  }

  .bijoux-content .card a {
    padding: 1.5rem 1rem;
  }

  .bijoux-content .card__img::before {
    width: 100px;
    height: 100px;
  }
}

@include breakpoint(650) {
  .bijoux-content .card a {
    padding: 1rem 0.5rem;
  }

  .bijoux-content .card__img::before {
    width: 80px;
    height: 80px;
  }
}

@include breakpoint(440) {
  .bijoux-content .cards {
    // display: block;
    gap: 0.5rem;
  }
  .bijoux-content .card {
    max-width: 100%;
  }
  .bijoux-content .card__img::before {
    width: 60px;
    height: 60px;
  }
  .bijoux-content .card__title {
    margin: 1rem auto 0 auto;
  }
}
