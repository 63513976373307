#hikamarket_plugin_form {
  max-width: 780px;
  h1 {
    display: none;
  }

  h3:first-of-type {
    display: none;
  }

  //   -------------------------------------------------------------  NAME & DESCRIPTION
  .hikamarket_field_shipping_name label::after,
  .hikamarket_field_shipping_description label::after {
    content: "(Non visible pour le client)";
    margin-left: 5px;
  }

  #jform_articletext_ifr {
    max-height: 150px;
  }
  //   -------------------------------------------------------------  PRIX
  .hikamarket_field_shipping_price label::after {
    content: "(Net)";
    margin-left: 5px;
  }
  dd.hikamarket_field_shipping_price {
    width: 150px;
    @include flexbox;
    @include align-items(center);
    &::after {
      content: "€";
      margin-left: 5px;
      font-weight: bold;
    }
    #datashippingshipping_currency_id {
      display: none;
    }
  }
  //   -------------------------------------------------------------  RESTRICTION
  #plugin_add_restriction_value {
    margin-bottom: 1rem;
  }

  #hikamarket_shipping_restrictions {
    margin-bottom: 3rem;
  }

  //   --------------------------- sélection
  .namebox {
    @include inline-flex;
    @include align-items(center);
    padding: 5px 10px;
    box-shadow: 0 4px 8px rgba($color: #000000, $alpha: 0.08);
    background-color: var(--light);
    border-radius: 7px;
    margin: 4px;

    &#data_product_categories-21 {
      // display: none;
    }

    .closebutton {
      color: var(--white);
      background-color: var(--error);
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      font-size: 0.7rem;
      font-weight: 500;
      margin-left: 6px;
    }
  }

  .oTreeNode {
    .node {
      font-size: 1.2rem;
    }
  }

  //   --------------------------- eo sélection

  //   --------------------------- catégory

  #data_shipping_shipping_zone_namekey {
    position: relative;

    .nametext {
      max-width: 380px;
      height: 60px;
      position: relative;
      cursor: pointer;
      margin-top: var(--gap);
    }
  }
  #data_shipping_shipping_zone_namekey_otree {
    .oTreeNode {
      position: relative;
    }

    .oTreeNode {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      @include flexbox;
      @include align-items(center);
      border-bottom: 1px solid var(--gray-7);
      padding-bottom: 0.2rem;
    }

    .node {
      font-size: 1rem;
      margin-left: 5px;
    }

    #data_shipping_shipping_zone_namekey_d1 {
      margin-left: 15px;

      a.node {
        &::before {
          content: none;
        }
      }
    }
  }

  //   --------------------------- eo catégory

  //   --------------------------- prix
  .hikamarket_shipping_cat_price.hikamarket_field_shipping_params_shipping_virtual_included,
  .hikamarket_shipping_cat_price.hikamarket_field_shipping_params_shipping_price_use_tax {
    display: none;
  }
}
