#page__checkout__stripe__paiement {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include flex-flow(row wrap);
  min-height: 100vh;
  &::before {
    height: 100%;
    width: 50%;
    background: #fff;
    position: fixed;
    z-index: 1;
    content: " ";
    top: 0;
    right: 0;
  }

  .stripe__paiement__bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--gradient-1);
  }

  .content {
    position: relative;
    margin-top: -1rem;
    z-index: 10;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-flow(row wrap);
    max-width: 920px;
    width: 100%;
    padding: 2rem 0;
  }

  #stripe-payment-form,
  .stripe__paiement__infos {
    width: 380px;
    max-width: 380px;
  }

  // =========================================== LEFT
  .stripe__paiement__infos {
    // text-align: center;

    .paiement__back {
      .btn--back {
        color: var(--white);

        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        gap: 15px;
      }

      svg {
        width: 325px;
        height: auto;
        path,
        rect {
          fill: white !important;
        }
      }
    }
    .paiement__total {
      margin-top: 1rem;
      color: var(--white);
      text-align: left;
    }

    .total__title {
      display: block;
      font-weight: 300;
      font-size: 1.5rem;
    }
    .total__value {
      display: block;
      font-size: 2.5rem;
      font-weight: bold;
    }
  }

  // =========================================== EO LEFT

  // =========================================== RIGHT

  #stripe-payment-form {
    label {
      display: block;
      margin-left: 0;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--color-1);
    }
    .StripeElement {
      margin: 2rem 0;
      border: 1px solid var(--gray-7);
      padding: 1rem;
      border-radius: 8px;
    }
    .StripeButton {
      width: 100%;
    }

    #hk_co_p_c_STRIPEC_errors_13 {
      display: block;
      margin-bottom: 1rem;
      color: var(--error);
    }
  }
}

@include breakpoint(980) {
  #page__checkout__stripe__paiement {
    display: block;
  }

  #page__checkout__stripe__paiement .stripe__paiement__bg {
    display: none;
  }

  #page__checkout__stripe__paiement .content {
    padding: 0;
    display: block;
    max-width: initial;
    text-align: center;
  }

  #page__checkout__stripe__paiement #stripe-payment-form,
  #page__checkout__stripe__paiement .stripe__paiement__infos {
    width: 100%;
    max-width: initial;
  }

  #page__checkout__stripe__paiement .stripe__paiement__infos {
    background: var(--gradient-1);
    padding: 1rem 0;
    width: calc(100% + 3rem) !important;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
  }
  #page__checkout__stripe__paiement .stripe__paiement__infos .paiement__back {
    @include justify-content(center);
    .btn--back {
      @include justify-content(center);
    }
  }

  #page__checkout__stripe__paiement .stripe__paiement__infos .paiement__total {
    text-align: center;
  }

  #page__checkout__stripe__paiement::before {
    content: none;
  }
  #stripe-payment-form {
    max-width: 380px !important;
    margin: 2rem auto;
  }
}

@include breakpoint(550) {
  #page__checkout__stripe__paiement .stripe__paiement__infos {
    width: calc(100% + 1rem) !important;
  }

  #page__checkout__stripe__paiement .stripe__paiement__infos svg {
    max-width: 65%;
    height: auto;
    display: block;
  }

  #page__checkout__stripe__paiement .stripe__paiement__infos .total__title {
    font-size: 1.2rem;
  }
  #page__checkout__stripe__paiement .stripe__paiement__infos .total__value {
    font-size: 1.8rem;
  }

  #page__checkout__stripe__paiement #stripe-payment-form .StripeElement {
    margin: 1.5rem 0;
  }

  #page__checkout__stripe__paiement #stripe-payment-form .StripeButton {
    padding: 1rem 2rem;
  }
}
