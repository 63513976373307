.home-how {
  position: relative;
  z-index: 10;
  margin-top: var(--section);
  margin-bottom: var(--section);

  //   ------------------------------- deco
  @include deco-before;
  @include deco-after;
  //   ------------------------------- eo deco

  .items {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 350px));
    gap: 50px;
    justify-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 2rem;

    img {
      display: block;
      margin: 0 auto 1rem auto;
      width: auto;
      height: 100px;
    }

    .icon__how--active {
      path {
        // animation: stroke 5.5s;
        fill: var(--light);
      }
    }

    //-------------------------- SVG ANIMATION
    @keyframes stroke {
      0% {
        fill: transparent;
        stroke: rgba($color: #ffffff, $alpha: 0.1);
        stroke-dashoffset: 20%;
        stroke-dasharray: 0 50%;
        stroke-width: 1;
      }

      100% {
        fill: var(--light);
        stroke: rgb(255, 255, 255);
        stroke-dashoffset: -50%;
        stroke-dasharray: 50% 0;
        stroke-width: 0;
      }
    }
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
  .home-how .items {
    gap: 35px;
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
}

@include breakpoint(980) {
}

@include breakpoint(780) {
}

@include breakpoint(550) {
  .home-how .items {
    grid-template-columns: 1fr;
    gap: 50px;
    padding: 0 rem(8);
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
