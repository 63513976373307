.btn__back {
  margin: 0 0 1rem 0.5rem;
  i {
    margin-right: 0.4rem;
  }
}

// ----------------------------------------------------------- MOBILE SCROLL HELP
.left__tablet__wrapper {
  @include flexbox;
  @include align-items(strech);
  gap: 1rem;
}

.left__tablet__scroll-help {
  display: none;
  a {
    position: relative;
    display: block;
    background-color: var(--color-1);
    color: var(--white);
    padding: 1rem;
    border-radius: 8px;
  }
  &::after {
    // @include icon("\f309");
    // font-size: 1.7rem;
    // color: var(--color-1);
  }
}

// ----------------------------------------------------------- EO MOBILE SCROLL HELP

// ----------------------------------------------------------- ZOOM IMAGE
.zoom__wrapper {
  border-radius: 7px;
}

.box__product__zoom {
  position: absolute !important;
  z-index: 10 !important;

  .eb-dialog {
    position: absolute;
    bottom: 1rem;
    // border-radius: 8px;
    // left: 50%;
    // transform: translate(-50%, 0px) !important;
  }

  .ebox-yn-text {
    margin-bottom: 0.5rem !important;
  }

  .ebox-ys-btn {
    background-color: var(--color-1) !important;
    border-radius: 50px !important;
    cursor: pointer !important;
  }
}

// ----------------------------------------------------------- EO ZOOM IMAGE

// ----------------------------------------------------------- FULL PAGE IMAGE

#sb-loading-inner {
  span {
    display: none;
  }
  &::after {
    content: "chargement...";
  }
}

#sb-info {
  position: absolute;
  top: 3rem;
  right: 2rem;
  overflow: visible;
}

#sb-nav {
  height: unset;
  padding: unset;
  width: unset;
}

#sb-nav a {
  background-image: none !important;
  font-size: 2rem;
  margin-left: 1rem;

  &::before {
    color: var(--color-2);
    text-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.8);
  }
  &#sb-nav-close::before {
    @include icon("\f057");
  }
  &#sb-nav-next::before {
    @include icon("\f051");
  }
  &#sb-nav-previous::before {
    @include icon("\f048");
  }
}

#sb-counter {
  display: none;
}

// ----------------------------------------------------------- EO FULL PAGE IMAGE

// ---------------------------------------------------------------------------------- BASE
.hikashop_product_page {
  --details-width: 670px;
  padding-bottom: 5rem;

  .hikashop_previous_product_btn,
  .hikashop_next_product_btn {
    display: none;
  }

  .hk-row-fluid {
    display: grid;
    grid-template-columns: minmax(400px, 1fr) minmax(280px, 1fr);
    &::before,
    &::after {
      content: none;
    }
  }

  // ------------------- titre
  .item__title {
    display: block;
    font-family: var(--font-titre);
    font-size: rem(40);
    text-align: center;
    margin-bottom: 1rem;
  }

  // ---------------------------------------------------------------------------------- WRAPPER
  .hikashop_product_left_part,
  #hikashop_comment_form {
    max-width: var(--details-width);
    border-right: 1px solid var(--gray-2);
    padding-right: 1rem;
  }

  // ---------------------------------------------------------------------------------- LEFT

  .hikashop_product_left_part,
  #hikashop_comment_form {
    // padding: 0 2rem 0 0;
  }

  #hikashop_main_image_div {
    a {
      cursor: crosshair;
    }
  }

  .hikashop_product_left_part {
    width: 100%;
    #hikashop_main_image_div {
    }

    .hikashop_product_main_image_subdiv img {
      border-radius: 7px;
      margin: 0 !important;
    }
    .hikashop_product_main_image_thumb {
      height: auto !important;
    }
    #hikashop_small_image_div {
      // margin-top: -13px;
      img {
        max-width: 70px;
        max-height: 70px;
        border-radius: 4px;
      }
      a {
        border: 2px solid transparent !important;
        border-radius: 4px;
      }
      a .hikashop_child_image_active {
        border: 2px solid var(--color-2) !important;
      }
    }

    // ------------------------------ nav

    .hika__details__nav {
      position: -webkit-sticky;
      position: sticky;
      top: 10px;
      margin: 1rem 0 0 0;
      ul {
        @include flexbox;
        @include align-items(center);
        @include flex-flow(row wrap);
        gap: 1rem;
      }
    }

    // ------------------------------ description
    .hikashop_product_description_main {
      margin-bottom: 3rem;
      margin-left: 0.5rem;

      #le-bijou {
        // border-top: 1px solid var(--gray-2);
      }

      .item__description {
        @include flexbox;
        @include align-items(center);
        // margin-left: 1rem;
        gap: 15px;
        margin-bottom: 1rem;
      }
      p {
        // font-size: clamp(1rem,1.2vw,1.2rem);
        margin-bottom: 0.7rem;
      }
    }
    .producteur__products {
      text-align: center;
      margin-top: 1rem;
    }
  }

  #hikashop_product_custom_info_main {
    h4 {
      display: none;
    }
  }

  // -------------------------------------- caractéristiques
  #le-bijou,
  #caracteristiques {
    padding-top: 3rem;
  }

  .caracteristiques__wrapper {
    padding: 2rem;
    background-color: var(--gray-1);
    border-radius: 8px;
    border: 1px solid var(--gray-1);
    li {
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      padding: 0.3rem 0;
      // background-color: var(--light);

      &:hover {
        background-color: var(--gray-1);
      }
    }
    .item__name {
      position: relative;
      font-size: 1.1rem;
      font-size: clamp(0.9rem, 1.1vw, 1.1rem);
      border-bottom: 1px dashed var(--gray-3);
    }
    .item__value {
      font-weight: 600;
      font-size: 1.15rem;
      font-size: clamp(0.9rem, 1vw, 1rem);
    }
  }

  // -------------------------------------- eo caractéristiques

  // -------------------------------------- produits du producteur

  .swiper__details {
    display: none !important;
    padding-bottom: 5rem;
    .swiper-slide {
      max-width: 150px;
      // box-shadow: 25px 20px 41px rgba($color: #000000, $alpha: 0.16);
      border: 1px solid var(--gray-4);
      border-radius: 7px;
      overflow: hidden;

      .item__link {
        display: block;
        height: 180px;
        text-align: center;
      }

      img {
        display: block;
        width: 100%;
        height: 100px;
        object-fit: cover;
        object-position: center;
      }

      .item__name {
        display: block;
        margin: var(--gap);
        color: var(--color-2);
        font-size: 0.9rem;
        // height: 40px;
        line-height: 1.3;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .item__price {
        display: none;
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-weight: bold;
        font-size: 1.2rem;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: var(--color-2);
      font-size: 2rem !important;
      top: initial !important;
      bottom: 0;
      font-weight: bold;
      transform: scale(1);

      &::after {
        @include icon("\f054");
      }

      &:hover {
        color: var(--color-2);
        // text-shadow: 0 15px 15px rgba($color: #000000, $alpha: 1);
      }
    }
    // ------------- NAVIGATION
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: calc(50% - 50px);

      &::after {
        content: "\f053";
      }
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: calc(50% - 50px);
    }
  }

  // -------------------------------------- prev / next btn
  .prev__next__btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
    display: none;
  }

  .hikashop_previous_product_btn,
  .hikashop_next_product_btn {
    padding: 3px 9px;
    margin-left: 1rem;
    p {
      font-size: 1rem;
    }
  }

  .hikashop_previous_product,
  .hikashop_next_product {
    display: flex;
    align-items: center;
  }

  .hikashop_previous_product {
    i {
      margin-right: 7px;
    }
  }
  .hikashop_next_product {
    i {
      margin-left: 7px;
    }
  }

  // ---------------------------------------------------------------------------------- RIGHT
  // -------------------------------------- product infos -- fixed

  .hikamarket_show_edit {
    // display: none;
  }

  .hikashop_product_right_part--mobile {
    display: none;
  }
  .hikashop_product_right_part {
    width: 100%;
    padding: 0;
    margin: 0 0 0 1rem;
  }
  .hika__product__right {
    position: sticky;
    z-index: 100;
    top: 20px;
    max-height: 90vh;
    overflow: auto;
    // box-shadow: 5px 10px 21px var(--gray-2);
    border: 1px solid var(--gray-1);
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 10px;
  }

  .hika__product__right .hika__product__infos {
    transition: all 0.15s;
    top: 0;
    // background-color: var(--light);
    // margin-left: 1rem;
    padding: 0 1rem;
    max-width: 550px;
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: transparent;
  }

  .hika__product__right--fixed .hika__product__infos {
    position: fixed;
    max-height: 90vh;
    overflow: auto;
    width: auto;
    padding: 1rem;
    z-index: 100;
    transform: translateY(20px);
    border-radius: 15px;
    @include shadow-1;
    border: 1px solid var(--gray-2);
  }

  // -------------------------------------- titre
  .hikashop_product_name_main {
    display: block;
    font-size: rem(25);
    font-weight: 600;
    line-height: 1.3;
    margin: 4px 0;
  }

  // -------------------------------------- code

  .hikashop_product_code_main {
    font-size: 0.8rem;
    text-transform: uppercase;
    display: none;
    // color: var(--gray-5);
  }

  // -------------------------------------- vendeur
  .hikamarket_vendor {
    display: block;
    margin: 4px 0;
    color: var(--gray-8);
    font-weight: 300;
    line-height: 1;

    .vendor__title {
      display: none;
    }

    path {
      fill: var(--gray-8);
    }

    a {
      font-family: var(--font-titre);
      font-size: rem(22);
      font-weight: normal;
      color: var(--color-2);
      pointer-events: none;
      &:hover {
        border-bottom: 1px solid var(--color-2);
      }
    }
  }

  // -------------------------------------- Infos / vote

  .hika__vote-infos {
    @include flexbox;
    @include align-items(center);
    gap: 2rem;
    margin: 1rem 0;
  }

  // -------------------------------------- vote

  // -----> components/hika_vote

  // -------------------------------------- social

  .social__share {
    display: none;
  }

  // -------------------------------------- poids

  .hikashop_product_weight_main {
    display: block;
    font-size: rem(20);
    margin: 2rem 0 1rem 0;
  }

  // -------------------------------------- prix

  .hikashop_product_price {
    display: block;
    font-size: rem(30);
    font-weight: 600;
    line-height: 1;
    margin-top: 1rem;
  }

  .hikashop_product_price_per_unit {
    display: none;
  }

  .hikashop_product_price_per_weight_unit {
    color: var(--color-3);
    font-size: 1rem;
  }

  .hika__price__infos {
    font-weight: 700;
    font-size: rem(14);
    @include flexbox;
    @include align-items(center);
    gap: 4px;
    display: none !important;
  }

  // -------------------------------------- quantité / prix

  .hikashop_product_quantity_div {
    display: none;
  }

  .hika__stock-delivery--empty {
    display: none !important;
  }

  .hika__delivery {
    // display: none !important;
  }

  .hikashop_product_quantity_main {
    // margin: 2rem 0 0 0;
    margin-top: 2rem;
  }

  .hika__quantity__price {
    position: relative;
    @include flexbox;
    @include flex-flow(row wrap);
    gap: 15px;
    margin: 2rem 0;

    .notifyjs-wrapper,
    .notifyjs-container {
      // display: none;
      position: relative;
      width: unset;
      height: unset;
      left: unset !important;
      bottom: unset !important;
    }
  }

  // -------------------------------------- quantité

  .hikashop_product_quantity_div {
    position: relative;

    .hika__qty__title {
      display: block;
      text-align: center;
      margin-bottom: 4px;
      font-weight: 500;
    }
    .hika__qty__wrapper {
      @include flexbox;
      align-items: center;
    }

    // ------------- suppresion du pop up quantité mini
    .notifyjs-wrapper {
      display: none !important;
    }

    .add-on {
      width: 35px;
      height: 35px;
      line-height: 35px;
      color: red;
      text-align: center;
      color: var(--color-2);
      font-size: 1.5rem;
      border: 1px solid var(--gray-4);
      border-radius: 7px;

      a {
        display: block;
      }
    }

    input {
      border: none;
      // margin: 0 8px;
      // border-bottom: 1px solid var(--gray-4);
      padding: 0;
      font-size: rem(20);
      font-weight: bold;
      height: initial !important;
      max-width: 40px !important;
      text-align: center;
    }
  }

  .hikashop_product_quantity_div {
    // margin-right: 8px;
  }

  // =============================================== OPTIONS TAILLE / DIMENSION

  .hikashop_product_custom_item_info {
    margin: 1.5rem 0;
  }

  #hikashop_item_product_taille_bracelet,
  #hikashop_item_product_taille_bague {
    display: block;
    padding: 0.5rem 1rem;
    background-color: var(--gray-1);
    border-radius: 8px;
    td {
      display: block;
    }
    .hkradio-inline {
      padding: 0;
      margin: 0 0.5rem 0.5rem 0;
    }

    label {
      display: block;
      margin: 0;

      cursor: pointer;
    }
    input,
    .hikashop_field_required {
      display: none;
    }
    label span {
      display: block;
      padding: 2px 12px;
      border: 1px solid var(--gray-2);
      background-color: var(--light);
      font-size: 1.2rem;
      border-radius: 4px;
      &:hover {
        background-color: var(--color-1);
        color: var(--white);
      }
    }
    input:checked ~ span {
      background-color: var(--color-1);
      color: var(--white);
    }
  }

  #hikashop_product_custom_item_name_40,
  #hikashop_product_custom_item_name_41 {
    label {
      font-size: 1.3rem;
      font-weight: 500;
      color: var(--color-1);
    }
    &::after {
      display: block;
      content: "La longueur tient compte du fermoir en position fermée";
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
  }

  // =============================================== EO TAILLE / DIMENSION

  // -------------------------------------- bouton ajouter au panier

  .btn--add-cart.cart-invalid {
    pointer-events: none;
    // border: 2px solid var(--light) !important;
    &::before {
      content: "Merci de choisir une taille";
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      border: none;
      font-size: 1.1rem;
      // text-decoration: underline;
      background-color: var(--color-1);
      // border: 2px solid var(--light);
      color: var(--white);
    }
  }

  .btn--add-cart {
    background-color: var(--color-1);
    color: var(--white);

    &::before,
    &::after {
      content: none;
    }
    span {
      @include flexbox;
      align-items: center;
      padding: 0;
      font-weight: 400;
      font-size: rem(22);
      padding-top: 2px;

      svg {
        position: relative;
        bottom: 2px;
        margin-left: 12px;
      }
    }
  }

  // -------------------------------------- stock / delivery

  .hika__stock-delivery {
    @include inline-flex;
    @include align-items(center);
    gap: 1rem;
  }

  .hikashop_product_stock_count,
  .hika__delivery {
    // background-color: var(--gray-2);
    // color: var(--color-2);
    // padding:0 0.5rem 1rem 0.5rem;
    // height: 65px;
    @include inline-flex;
    @include align-items(center);

    svg {
      margin-right: 8px;
      width: auto;
    }
    #Tracé_4597,
    #Tracé_4598,
    #Tracé_4599,
    #icon--shipped path {
      stroke: var(--dark);
    }
    #Ellipse_277 {
      fill: var(--color-1);
    }
  }

  .hika__stock {
    position: relative;
    font-size: rem(16);
    font-weight: 500;
    margin-right: 4px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -1rem;
      height: 200%;
      width: 1px;
      background-color: var(--gray-2);
      content: none;
    }

    &.hika__stock--danger span {
      // color: var(--error) !important;
    }
  }

  .hikashop_product_stock_count {
  }

  .hika__stock--empty {
    display: block;
    // background-color: var(--error);
    // color: var(--white);
    font-size: 1.4rem;
  }

  .hika__delivery {
    // display: none;
  }

  // ---------------------------------------------------------------------------------- VOTE

  #avis-clients {
    padding-top: 3rem;
  }

  .hikashop_vote_notification {
    // padding: 10px;
    margin: 1rem 0;
    // background-color: var(--error);
    // color: var(--white);
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    border-radius: 5px;
    // background-color: var(--color-2);
    // color: var(--white);
    // text-decoration: underline;
  }

  // ------------------------------------------- form

  .hikashop_vote_comment_not_allowed {
    text-align: center;
    padding: 1rem;
    border: 1px solid var(--gray-3);
    border-radius: 5px;
  }

  .hika__vote__registered__title {
    display: block;
    margin-bottom: 1rem;
    font-size: clamp(1rem, 1.3vw, 1.3rem);
  }

  .hikashop_vote_form {
    text-align: center;
    border: 1px solid var(--gray-3);
    // box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.08);
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 15px;

    .hikashop_vote_stars {
      margin: 1rem 0 0 0;

      .vote__title {
        display: block;
        font-size: 1.3rem;
        font-weight: 500;
      }
      .hk-rating {
        @include flexbox;
        @include justify-content(center);
        gap: 5px;
      }
      .hk-rate-star {
        background: none !important;
        font-size: 18px;
        color: var(--gray-5);
        &::before {
          @include icon("\f005");
        }
        &.state-hover,
        &.state-full {
          color: var(--star);
        }
      }
    }

    label {
      // margin: 0;
      // padding: 0;
    }

    input,
    textarea {
      min-width: 380px;
      margin: auto;
    }
  }

  // ------------------------------------------- list

  .hikashop_listing_comment {
    .pagination {
      display: none !important;
    }
  }

  .hika_comment_listing {
    position: relative;
    border: 1px solid var(--gray-2);
    // box-shadow: 5px 10px 21px var(--gray-1);
    border-radius: 15px;
    margin-bottom: 2rem;
    text-align: center;
    padding: 10px;

    &.hika_comment_listing_empty {
      // display: none !important;
      box-shadow: none;
      border: 1px solid var(--gray-6);
    }

    .fa-quote-right {
      position: absolute;
      left: 10px;
      top: 10px;
      color: var(--gray-6);
      font-size: 2rem;
    }

    .hk-rating {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);

      i {
        color: var(--star);
        font-size: 18px;
      }
    }

    .hika_comment_listing_notification {
      display: none;
    }

    // cette personne a acheté cet article
    .hikashop_vote_listing_useful_bought {
      display: none;
    }

    .hika_comment_listing_content {
      margin: 1.5rem 0 1rem 0;
    }

    .hika_vote_listing_username {
      text-transform: uppercase;
      font-size: rem(14);
      font-weight: 500;
    }

    .hika__vote__date {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: rem(13);
      color: var(--gray-8);
    }
  }
}
