.page-checkout {
  position: relative;
  padding: 0 var(--full-width);

  .header__shipping {
    display: none;
  }

  .header__top {
    padding: 0;
    display: block;
  }

  .main {
    padding: 0;
    margin: 0;
  }

  #maincontent {
    max-width: 1180px;
    margin: auto;
  }

  .main__center {
    padding: 0 var(--full-width);
  }

  #system-message {
    display: none !important;
  }

  .header,
  .footer {
    display: none;
  }

  .checkout__logo {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.hikashop_checkout_page {
  padding-bottom: 5rem;
  .title__h2 {
    text-align: left;
  }
  .hk-row-fluid {
    &::before,
    &::after {
      content: none;
    }
  }
  .hkc-md-6 {
    width: 100% !important;
    padding: 0;
  }

  .hika__checkout__wrapper {
    // padding: 0 1rem;
  }

  //  --------------------------------------------- BUTTONS CONTINUER ET SUIVANT

  .hikashop_checkout_buttons {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    padding: 0 1rem;

    .buttons_right,
    .buttons_left {
      @include flexbox;
      @include align-items(center);
    }
  }

  .buttons__top {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--gray-6);
    width: 100%;

    .hikabtn_checkout_continue {
      display: none;
    }
  }
  .buttons__bottom {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid var(--gray-6);
    width: 100%;
  }

  .hikabtn_checkout_continue {
    font-size: rem(16);
    text-transform: uppercase;
    color: var(--gray-8);

    i {
      margin-right: 7px;
    }
  }

  .paiement__secure {
    @include inline-flex;
    @include align-items(center);
    margin-right: 10px;
    text-transform: uppercase;
    font-size: rem(12);
    font-weight: bold;
    color: #016fd0;
    svg {
      max-width: 30px;
      height: auto;
      margin-right: 0.3rem;
      path {
        fill: #016fd0;
      }
    }
    i {
      margin-right: 4px;
      font-size: 1.2rem;
      color: var(--color-3);
    }
  }

  //  --------------------------------------------- EO BUTTONS CONTINUER ET SUIVANT
}

.hika__checkout__block--active,
.hikashop_checkout_page_step1 .hika__checkout__block,
.hikashop_checkout_page_step3 .hika__checkout__block {
  display: grid;
  grid-template-columns: minmax(700px, 1fr) minmax(220px, 270px);
  gap: 50px;
  margin: 2rem 0 4rem 0;
  @include align-items(flex-start);
}

// ------------------------------- RESPONSIVE

@include breakpoint(1600) {
  .page-checkout .checkout__logo svg {
    max-width: 175px;
    height: auto;
  }
}

@include breakpoint(1180) {
  .page-checkout .checkout__logo svg {
    max-width: 150px;
  }
}

@include breakpoint(1160) {
  .hika__checkout__block--active,
  .hikashop_checkout_page_step1 .hika__checkout__block,
  .hikashop_checkout_page_step3 .hika__checkout__block {
    gap: 25px;
  }
}

@include breakpoint(980) {
  .page-checkout .checkout__logo {
    position: relative;
    top: unset;
    left: unset;
    text-align: center;
    margin: 1rem 0;
  }
  .page-checkout .checkout__logo svg {
    max-width: 200px;
  }
  .hika__checkout__block--active,
  .hikashop_checkout_page_step1 .hika__checkout__block,
  .hikashop_checkout_page_step3 .hika__checkout__block {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

@include breakpoint(780) {
  .hikashop_checkout_page_step1 {
    .hika__checkout__block {
      padding: 0 0.5rem;
    }
  }
}

@include breakpoint(650) {
  .buttons__top {
    // display: none;
  }
  .hikashop_checkout_page .hikashop_checkout_buttons {
    @include flex-direction(column);
    text-align: center;
  }
  .buttons_left {
    @include order(2);
    margin-top: 1rem;
  }
  .hikashop_checkout_page .hikabtn_checkout_continue {
    font-size: 0.8rem;
  }
  .buttons_right {
    @include order(10.05rem);
  }
}

@include breakpoint(550) {
  .page-checkout .main__center {
    padding: 0;
  }
  .page-checkout .checkout__logo {
    left: 50%;
    transform: translateX(-50%);
  }
  .page-checkout .checkout__logo svg {
    // max-width: 90px;
  }
}
