/*
************* BTN PRIMARY
* ===================================
*/

.btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
  border: 2px solid var(--color-1);
  color: var(--color-1);
  height: initial;
  background-color: transparent;
  // box-shadow: var(--shadow-1);
  font-size: rem(20);
  border-radius: 50px;
  line-height: 1;
  font-weight: 500;
  padding: 14px 26px;
  transition: background-color 0.15s, color 0.15s;
  // margin-top: 2rem;

  &::before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 4px;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-1);
    border-radius: 50px;
    transition: all 0.15s;
  }

  // &:active,
  // &:focus,
  &:hover {
    background-color: var(--color-1);
    color: var(--white);
    i,
    path {
      color: var(--white);
      fill: var(--white);
    }
    &::before {
      left: 0px;
      bottom: 0px;
    }
  }
}

.btn--underline {
  color: var(--color-2);
  text-decoration: underline;
}

.btn--full {
  background-color: var(--color-1);
  color: var(--white);
  &::before {
    content: none;
  }
  &:hover {
    color: var(--color-1);
    background-color: transparent;
    i {
      color: var(--color-1);
    }
  }
  // &:focus,
  // &:active {
  //   background-color: var(--color-1);
  //   color: var(--white);
  // }
}

.btn--full--white {
  background-color: var(--white);
  color: var(--color-1);
  &::before {
    content: none;
  }
  &:hover {
    background-color: var(--white);
    color: var(--color-1);
    opacity: 0.8;
    i {
      color: var(--color-1);
    }
  }
  // &:focus,
  // &:active {
  //   background-color: var(--color-1);
  //   color: var(--white);
  // }
}

.btn--radius {
  border-radius: 7px;
}

input.btn {
  height: initial !important;
}

.btn.hikabtn-primary {
  padding: 8px 16px;
  background-color: var(--color-1) !important;
  color: var(--white) !important;
  font-size: 1rem;
  border-radius: 7px;
  border-color: transparent !important;
  &::before,
  &::after {
    content: none;
  }
}

.hikabtn-success,
.hika_j2 .btn-danger:hover,
.hikabtn-danger:hover,
.hika_j2 .btn-danger,
.hikabtn-danger {
  background-color: var(--color-1) !important;
  border: var(--color-1) !important;
  color: var(--white) !important;
  &:hover,
  &:active,
  &:focus {
    // color: var(--color-1);
    background-color: var(--color-1);
  }
  &::before,
  &::after {
    content: none;
  }
  i {
    margin-right: 7px;
  }
}

.btn--success,
.btn--success:hover,
.btn--success:active,
.btn--success:focus {
  background-color: var(--success);
}

.btn--danger,
.btn--danger:hover,
.btn--danger:active,
.btn--danger:focus {
  background-color: var(--error);
}

.btn--small {
  padding: 8px 16px;
  font-size: 0.9rem;
}

.btn--back {
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  gap: 4px;
  &::before {
    @include icon("\f30a");
    margin-right: 4px;
    font-size: 1.5rem;
    transition: all 0.15s;
  }
  &:hover::before {
    // margin-right: 7px;
  }
}

button {
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  touch-action: manipulation;
  font-family: var(--font-normal);
  color: var(--dark);
}

.hikabtn.hikabtn-mini,
.hika_j2 .btn-mini {
  font-size: 14px;
  margin: 0 5px;
}

/*
************* BTN SECONDARY
* ===================================
*/

.btn--outlined {
  background-color: transparent;
  color: var(--color-2);
  border: 1px solid var(--color-2) !important;

  &:hover {
    background-color: transparent;
    color: var(--color-2);
    border: 1px solid var(--color-2);
  }

  &:active,
  &:focus,
  &.active {
    background-color: transparent;
    color: var(--color-2);
    border: 1px solid var(--color-2);
  }
}

.btn--outlined--danger {
  background-color: transparent;
  color: var(--error);
  border: 1px solid var(--error);

  &:hover {
    background-color: transparent;
    color: var(--error);
    border: 1px solid var(--error);
  }

  &:active,
  &:focus,
  &.active {
    background-color: transparent;
    color: var(--error);
    border: 1px solid var(--error);
  }
}

/*
************* DISABLED
* ===================================
*/
// .btn[disabled] {
//   cursor: default;
//   opacity: 0.1;
//   &:hover,
//   &:active,
//   &:focus {
//     background-color: var(--color-1);
//     color: var(--light);
//   }
// }

.btn[disabled] {
  cursor: pointer !important;
  opacity: 1 !important;
  &::after {
    content: none !important;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-1);
    color: var(--light);
  }
}

/*
************* BTN TOP
* ===================================
*/

.btn__top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transform: translateY(150px);
  z-index: 1000;

  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
  .btn__top__link {
    // background-color: $gray-6;
    width: 65px;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-2);
    color: var(--white);
    box-shadow: 10px 20px 41px var(--gray-3);
    // border: 3px solid var(--light);
    font-size: 1rem;
    transition: all 0.2s;

    i {
      font-size: 1.5rem;
    }
  }
}
.btn__top--active {
  transform: translateY(0);
  opacity: 1;
}

@include breakpoint(1700) {
  // .btn {
  //   padding: 4px 8px;
  //   font-size: rem(16);
  // }
  .btn__top {
    bottom: rem(16);
  }
  .btn__top .btn__top__link {
    width: 50px;
    height: 50px;
    font-size: rem(10);
    line-height: 1;
  }
  .btn__top .btn__top__link i {
    font-size: 1.2rem;
  }
}

@include breakpoint(1550) {
  .btn {
    font-size: rem(17);
  }
}

@include breakpoint(1180) {
  .btn {
    font-size: rem(16);
    // padding: 8px 16px;
  }
  .btn__top {
    display: none;
  }
}

@include breakpoint(550) {
  .btn {
    padding: 12px 14px;
    font-size: rem(15);
  }
  .btn--small {
    font-size: rem(12);
    padding: 6px 12px;
  }
  .btn__top .btn__top__link {
  }
  .btn__top .btn__top__link i {
    font-size: 1.5rem;
  }
}
