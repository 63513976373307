.hikamarket_user_name_title,
.hikamarket_user_email_title {
  text-align: left;
}

.hikamarket_user_login_title,
.hikamarket_user_login_value,
.hikamarket_user_username {
  display: none;
}

.hikamarket_order_status_value,
.hikamarket_order_date_value,
.hikamarket_order_total_value {
  text-align: center;
}

#hikamarket_user_form {
  .hikam_options {
    @include flexbox;
    gap: 15px;
  }

  dt.hikamarket_user_name,
  dt.hikamarket_user_email {
    width: 80px;
  }

  h2 {
    margin-top: 2rem;
    margin-left: 3rem;
    // text-align:center;
  }

  .hikam_options {
    // text-align: center;
  }
  // ----------------------------------------- address
  #hikamarket_user_billing_addresses_show,
  #hikamarket_user_shipping_addresses_show {
    // text-align: center;
    width: auto;
    min-width: 300px;
    border: 1px solid var(--gray-7);
    padding: 1rem;
    margin: 1rem 1rem 1rem 0;
    border-radius: 7px;
  }

  .hikamarket_address_title {
    font-size: 1.2rem;
  }

  .hikamarket_user_address {
    margin: 1rem 0;
    background-color: var(--gray-6);
    padding: 1rem;
  }
  // ----------------------------------------- eo address
  tfoot {
    display: none;
  }
}
