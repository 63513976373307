.home__hero--unique {
  .items {
    position: relative;
    @include flexbox;
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    height: calc(100vw * 450 / 1535);
    min-height: 450px;
    border-radius: 15px;
    box-shadow: 10px 30px 61px var(--opacity-1);
    overflow: hidden;
  }

  img {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 300px;
    object-position: center;
    object-fit: cover;
    // image-rendering: pixelated;
  }

  .item--content {
    position: relative;
    border-left: 2px solid var(--white);
    overflow: hidden;
    @include flexbox;
    @include justify-content(center);
    @include flex-flow(column wrap);
    gap: 1rem;
    padding: 1rem;
    padding: clamp(1rem, 2vw, 2rem) clamp(0.5rem, 2vw, 2rem);
    color: var(--black);
    text-align: center;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/images/bg-fete-mere.jpg");
      background-size: cover;
      background-position: center;
      opacity: 0.9;
    }
  }

  .hero__item__title {
    display: block;
    font-family: var(--font-titre);
    line-height: 1.3;
    font-size: 2rem;
    font-size: clamp(1.3rem, 2.3vw, 2.3rem);

    // transform: translateY(40px);
    text-shadow: 3px 6px 18px var(--gray-1);
  }
  .hero__item__slogan {
    display: block;
    line-height: 1.7;
    font-size: 1rem;
    font-size: clamp(0.9rem, 1.1vw, 1.1rem);
    font-weight: 500;
    margin: 1rem 0 2rem 0;
    // transform: translateX(40px);
    text-shadow: 1px 3px 9px var(--gray-1);
  }

  .hero__item__delivery {
    display: block;
    font-size: 1rem;
    font-size: clamp(0.9rem, 1.1vw, 1.1rem);
    color: var(--color-1);
    font-weight: 600;
    animation: smile 2.5s linear infinite;
  }

  @keyframes smile {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  .btn {
    margin-top: 2rem;
    color: var(--white);
    border-color: var(--black);
    background-color: var(--black);
    // transform: translateX(-40px);
    // box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.45);
    &::before {
      content: none;
      // border-color: var(--black);
    }
    &:hover {
      background-color: var(--black);
      color: var(--color-1);
    }
  }
}

@include breakpoint(880) {
  .home__hero--unique .items {
    grid-template-columns: 1fr;
    height: auto;
    box-shadow: none;
    border-radius: 0px;

    .item--media {
      order: 2;
    }
    .item--media img {
      border-radius: 10px;
    }
  }
  .home__hero--unique .item--content {
    order: 1;
    text-align: center;
    // background-color: var(--opacity-1);
    background-color: transparent;
    border-radius: 10px;
    // border: 1px solid var(--color-1);
    // color: var(--dark);
    margin: 1rem 0;
  }
  .home__hero--unique .hero__item__slogan {
    margin-bottom: 0;
  }
  .home__hero--unique .btn {
    margin-top: 1rem;
  }
}

// ============================================================= AVANTAGES

.hero__avantages {
  margin-top: 1.5rem;
  .items {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    gap: 1rem;
    max-width: 1180px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .item {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    gap: 0.8rem;

    svg {
      max-width: 65px;
      max-height: 50px;
    }
  }
  .item__title {
    display: block;
    font-weight: bold;
    font-size: 1.09rem;
    font-size: clamp(0.85rem, 1vw, 1rem);
  }
  .item__desc {
    display: block;
    font-size: 0.85rem;
    font-size: clamp(0.7rem, 0.85vw, 0.85rem);
    // max-width: 275px;
    // min-width: 225px;
  }
}

@include breakpoint(880) {
  .hero__avantages {
    display: none;
  }
}

// ============================================================= HERO AVEC SWIPER

// .home__hero {
//   position: relative;
//   // margin-top: 1rem;
// }

// .carte-cadeau__wrapper {
//   position: absolute;
//   width: calc(100% - 200px);
//   z-index: 10;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   display: grid;
//   grid-template-columns: minmax(250px, 400px) minmax(500px, 1fr);
//   @include align-items(center);
//   gap: 50px;

//   img {
//     margin: auto;
//   }

//   .carte-cadeau__content {
//     color: var(--white);
//     font-family: var(--font-chalk);

//     .title {
//       display: block;
//       font-size: rem(60);
//       margin-bottom: 1rem;
//       line-height: 1;
//     }
//     p {
//       line-height: 1;
//       font-size: rem(50);
//     }
//     a {
//       display: inline-block;
//       margin-top: 2rem;
//       font-size: rem(45);
//       font-weight: bold;
//       border-bottom: 2px solid white;
//     }
//   }
// }
// // ------------------------------------------------------- SLIDE

// .home__slide {
//   position: relative;
//   z-index: 10;
//   // overflow: hidden;
//   //   ------------------------- background paint border inactif
//   //   &::after {
//   //     // content: "";
//   //     position: absolute;
//   //     background-image: url("/images/paint-border.png");
//   //     background-size: cover;
//   //     background-position: center;
//   //     width: 100%;
//   //     height: 120px;
//   //     z-index: 1;
//   //     bottom: rem(-10);
//   //     left: 0;
//   //   }
//   &::after {
//     // width: 102%;
//     // left: -1%;
//   }
// }

// .swiper-wrapper {
// }

// .swiper__slide {
//   position: relative;
//   width: 100%;
//   height: calc(100vw * 450 / 1535);
//   min-height: 300px;
//   border-radius: 15px;
//   box-shadow: var(--shadow-1);

//   .slide__items {
//     @include flexbox;
//     display: grid;
//     grid-template-columns: 1fr 0.6fr;
//     height: 100%;
//   }

//   .hero__item__img {
//     --pos-img: 40px;
//     display: block;
//     position: relative;
//     width: 100%;
//     height: calc(100% + var(--pos-img));
//     top: calc(var(--pos-img) * -1);
//     object-position: center;
//     object-fit: cover;
//     // image-rendering: pixelated;
//   }

//   &::before {
//     // content: "";
//     // position: absolute;
//     // pointer-events: none;
//     // top: 20px;
//     // left: 20px;
//     // z-index: 10;
//     // width: calc(100% - 40px);
//     // height: 100%;
//     // border: 1px solid white;
//   }

//   .swiper-slide {
//     position: relative;
//     z-index: 10;
//     box-shadow: 10px 20px 41px var(--gray-2);

//     // ---------------------------- OVERLAY
//     &::before {
//       content: "";
//       position: absolute;
//       pointer-events: none;
//       z-index: 10;
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       background-color: rgba($color: #000000, $alpha: 0.1);
//     }
//     // ------------------------------------------------------- CONTENT
//     .slide__content {
//       // position: absolute;
//       // z-index: 10;
//       // left: 9rem;
//       // padding-right: 1rem;
//       // top: calc(50% - 30px);
//       // transform: translateY(-50%);
//       background-color: var(--background-dark);
//       position: relative;
//       @include flexbox;
//       @include justify-content(center);
//       @include flex-flow(column wrap);
//       gap: 1rem;
//       padding: 2rem;
//     }

//     .hero__item__title {
//       display: block;
//       font-family: var(--font-titre);
//       line-height: 1.3;
//       font-size: 3rem;
//       color: var(--white);
//       transform: translateY(40px);
//       text-shadow: 3px 6px 18px var(--gray-1);
//     }
//     .hero__item__slogan {
//       display: block;
//       font-size: rem(25);
//       font-weight: 500;
//       padding-right: 2rem;
//       color: var(--white);
//       margin: 1rem 0 2rem 0;
//       transform: translateX(40px);
//       text-shadow: 1px 3px 9px var(--gray-1);
//     }
//     .btn {
//       margin-top: 2rem;
//       transform: translateX(-40px);
//       // box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.45);
//     }

//     .slide__overlay {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//     // ------------------------------------------------------------- ANIMATIONS
//     .hero__item__title,
//     .hero__item__slogan,
//     .btn {
//       opacity: 0;
//       transition: transform 1s, opacity 1s;
//     }
//   }

//   .swiper-slide-active {
//     .hero__item__title,
//     .hero__item__slogan,
//     .btn {
//       opacity: 1;
//       transform: translateY(0) translateX(0);
//     }
//     .hero__item__title {
//       transition-delay: 0.5s;
//     }
//     .hero__item__slogan {
//       transition-delay: 0.8s;
//     }
//     .btn {
//       transition-delay: 1s;
//     }
//   }

//   // ------------------------------------------------------------- NAVIGATION

//   .swiper-button-prev,
//   .swiper-button-next {
//     display: none !important;
//     color: var(--white);
//     font-weight: bold;
//     transform: scale(1.1);

//     &:hover {
//       color: var(--color-2);
//       // text-shadow: 0 15px 15px rgba($color: #000000, $alpha: 1);
//     }
//   }

//   .swiper-button-prev,
//   .swiper-container-rtl .swiper-button-next {
//     left: 30px;
//   }
//   .swiper-button-next,
//   .swiper-container-rtl .swiper-button-prev {
//     right: 30px;
//   }
// }

// // ------------------------------------------------------- MEDAILLES

// .medailles {
//   position: relative;
//   z-index: 10;
//   text-align: center;
//   font-size: rem(16);
//   margin-top: 1rem;
//   padding: 0 1rem;
//   display: flex;
//   justify-content: center;

//   img {
//     max-height: 60px;
//     min-height: 60px;
//     object-fit: contain;
//   }
// }

// // ------------------------------------------------------- CITATION

// .citation {
//   position: absolute;
//   z-index: 10;
//   text-align: right;
//   right: 0;
//   bottom: rem(16);
//   p {
//     font-family: var(--font-titre);
//     font-size: rem(45);
//     line-height: 1;
//   }
//   q {
//     display: block;
//     color: var(--color-3);
//     font-size: rem(14);
//     font-weight: 400;
//     text-transform: uppercase;
//     &::before,
//     &::after {
//       content: none;
//     }
//   }
// }

// // ------------------------------------------------------- AVANTAGES

// .hero__avantages {
//   margin-top: 1.5rem;
//   .items {
//     @include flexbox;
//     @include align-items(center);
//     @include justify-content(center);
//     gap: 1rem;
//     max-width: 1180px;
//     margin: auto;
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;
//   }
//   .item {
//     @include flexbox;
//     @include align-items(center);
//     @include justify-content(center);
//     gap: 0.8rem;

//     svg {
//       max-width: 65px;
//       max-height: 50px;
//     }
//   }
//   .item__title {
//     display: block;
//     font-weight: bold;
//     font-size: 1.09rem;
//   }
//   .item__desc {
//     display: block;
//     font-size: 0.85rem;
//     // max-width: 275px;
//     // min-width: 225px;
//   }
// }

// // ============================================================================================================== RESPONSIVE
// @include breakpoint(1700) {
//   // ======================================================= CARTE CADEAU
//   .carte-cadeau__wrapper {
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content .title {
//     font-size: 3.2rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content p {
//     font-size: 2.8rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content a {
//     font-size: 2.4rem;
//   }
//   // ======================================================= EO CARTE CADEAU

//   .swiper__slide .swiper-slide .hero__item__title {
//     font-size: 2rem;
//   }
//   .swiper__slide .swiper-slide .hero__item__slogan {
//     font-size: rem(24);
//     margin: 1.5rem 0;
//   }
//   .citation p {
//     font-size: rem(40);
//   }
//   .citation q {
//     font-size: rem(12);
//   }
// }

// @include breakpoint(1600) {
//   // ======================================================= CARTE CADEAU
//   .carte-cadeau__wrapper {
//     gap: 30px;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content .title {
//     font-size: 2.8rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content p {
//     font-size: 2.4rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content a {
//     font-size: 2.2rem;
//   }
//   // ======================================================= EO CARTE CADEAU

//   .swiper__slide .swiper-slide .hero__item__title {
//     font-size: 1.8rem;
//   }
//   .swiper__slide .swiper-slide .hero__item__slogan {
//     font-size: rem(20);
//     margin: 1rem 0;
//   }
//   .citation {
//     bottom: 130px;
//     right: 25px;
//   }
//   .citation p,
//   .citation q {
//     color: var(--white);
//     text-shadow: 3px 6px 18px black;
//   }
// }

// @include breakpoint(1440) {
//   .swiper__slide {
//     max-height: 400px;
//   }
//   .swiper__slide .swiper-slide .slide__content {
//     // left: 6rem;
//   }
//   .swiper__slide .swiper-slide .hero__item__title {
//     font-size: 1.7rem;
//   }
//   .swiper__slide .swiper-slide .hero__item__slogan {
//     font-size: rem(18);
//   }
//   .medailles {
//     margin-top: 0.5rem;
//   }
//   .medailles img {
//     max-height: 50px;
//   }
//   .citation p {
//     font-size: rem(35);
//   }
//   .citation q {
//     font-size: rem(11);
//   }

//   // ======================================================= AVANTAGES

//   .hero__avantages .item__title {
//     font-size: 0.9rem;
//   }

//   .hero__avantages .item__desc {
//     font-size: 0.8rem;
//   }

//   // ======================================================= EO AVANTAGES
// }

// @include breakpoint(1280) {
//   // ======================================================= CARTE CADEAU
//   .carte-cadeau__wrapper {
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content .title {
//     font-size: 2.4rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content p {
//     font-size: 2rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content a {
//     font-size: 2rem;
//   }
//   // ======================================================= EO CARTE CADEAU
// }

// @include breakpoint(1180) {
//   .home__slide::after {
//     width: 100%;
//     left: 0;
//   }
//   .home__hero {
//     margin-top: 0;
//     width: calc(100% + var(--full-width) * 2);
//     margin-left: calc(-1 * var(--full-width));
//     padding: 0 var(--full-width);
//     // ------------ voir grid.scss
//   }
//   .swiper__slide .swiper-slide .slide__content {
//     // left: 7rem;
//   }
//   .swiper__slide::before {
//     top: 10px;
//     left: 10px;
//     width: calc(100% - 20px);
//   }
//   .swiper__slide .swiper-button-prev,
//   .swiper__slide .swiper-button-next {
//     transform: scale(0.7);
//   }
//   .swiper__slide .swiper-button-prev,
//   .swiper__slide .swiper-container-rtl .swiper-button-next {
//     left: 15px;
//   }
//   .swiper__slide .swiper-button-next,
//   .swiper__slide .swiper-container-rtl .swiper-button-prev {
//     right: 15px;
//   }
// }

// @include breakpoint(880) {
//   .swiper__slide .swiper-slide {
//     grid-template-columns: 1fr 0.7fr;
//   }

//   // ======================================================= CARTE CADEAU
//   .carte-cadeau__wrapper {
//     width: calc(100% - 150px);
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content .title {
//     font-size: 2rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content p {
//     font-size: 1.8rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content a {
//     font-size: 2rem;
//   }
//   // ======================================================= EO CARTE CADEAU

//   // ======================================================= AVANTAGES

//   .hero__avantages {
//     display: none;
//   }
// }

// @include breakpoint(780) {
//   .swiper__slide {
//     min-height: 325px;
//   }
//   .swiper__slide .slide__items {
//     display: block;
//   }

//   .swiper__slide .hero__item__img {
//     position: absolute;
//     left: 0;
//   }

//   .swiper__slide .swiper-slide .slide__content {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     padding: 0;
//     // display: none;

//     div {
//       padding: 0.5rem 1rem;
//     }
//   }

//   .swiper__slide .swiper-slide .btn {
//     display: none;
//   }

//   .swiper__slide .swiper-button-prev,
//   .swiper__slide .swiper-container-rtl .swiper-button-next {
//     left: 15px;
//   }
//   .swiper__slide .swiper-button-next,
//   .swiper__slide .swiper-container-rtl .swiper-button-prev {
//     right: 15px;
//   }
//   .swiper__slide .swiper-button-prev,
//   .swiper__slide .swiper-button-next {
//     transform: scale(0.7);
//   }

//   .swiper__slide .swiper-slide .slide__content {
//     // left: 5rem;
//     background-color: rgba(46, 33, 33, 0.4);
//     box-shadow: -5px -15px 30px var(--gray-2);
//   }

//   // ======================================================= CARTE CADEAU
//   .carte-cadeau__wrapper {
//     width: calc(100% - 90px);
//     grid-template-columns: minmax(200px, 400px) minmax(300px, 1fr);
//   }

//   .carte-cadeau__wrapper .carte-cadeau__content .title {
//     font-size: 1.8rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content p {
//     font-size: 1.6rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content a {
//     margin-top: 1rem;
//     font-size: 1.8rem;
//   }
//   // ======================================================= EO CARTE CADEAU

//   .swiper__slide .swiper-slide .hero__item__title {
//     font-size: 1.3rem;
//   }
//   .swiper__slide .swiper-slide .hero__item__slogan {
//     font-size: rem(16);
//     margin: 0.8rem 0;
//   }
//   .citation {
//     display: none;
//   }
// }

// @include breakpoint(550) {
//   .home__hero {
//     margin-top: 0;
//   }

//   .swiper__slide {
//     min-height: 250px;
//   }

//   //   ---------- decoration slide rectangle border white
//   .swiper__slide::before {
//     display: none;
//   }
//   .btn.slide__link {
//     // display: none;
//   }
//   .swiper__slide .swiper-slide .slide__content {
//   }
//   .swiper__slide .swiper-slide .hero__item__slogan {
//     font-weight: 500;
//   }
//   //   --------------------------------- boutons prev et next slide
//   .swiper__slide .swiper-button-prev,
//   .swiper__slide .swiper-container-rtl .swiper-button-next,
//   .swiper__slide .swiper-button-next,
//   .swiper__slide .swiper-container-rtl .swiper-button-prev {
//     // display: none;
//   }

//   .swiper__slide .swiper-button-prev,
//   .swiper__slide .swiper-container-rtl .swiper-button-next {
//     left: 8px;
//   }
//   .swiper__slide .swiper-button-next,
//   .swiper__slide .swiper-container-rtl .swiper-button-prev {
//     right: 8px;
//   }
//   .swiper__slide .swiper-button-prev,
//   .swiper__slide .swiper-button-next {
//     transform: scale(0.6);
//   }

//   // ======================================================= CARTE CADEAU
//   .carte-cadeau__wrapper {
//     width: calc(100% - 1rem);
//     gap: 1rem;
//     grid-template-columns: minmax(150px, 200px) minmax(210px, 1fr);
//   }

//   .carte-cadeau__wrapper .carte-cadeau__content .title {
//     font-size: 1.5rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content p {
//     font-size: 1.3rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content a {
//     font-size: 1.6rem;
//   }
//   // ======================================================= EO CARTE CADEAU

//   .medailles {
//     margin-top: 8px;
//   }
// }

// @include breakpoint(380) {
//   // ======================================================= CARTE CADEAU
//   .carte-cadeau__wrapper {
//     width: calc(100% - 1rem);
//     gap: 1rem;
//     grid-template-columns: minmax(120px, 150px) 1fr;
//   }

//   .carte-cadeau__wrapper .carte-cadeau__content .title {
//     font-size: 1.3rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content p {
//     font-size: 1.1rem;
//   }
//   .carte-cadeau__wrapper .carte-cadeau__content a {
//     font-size: 1.4rem;
//   }
//   // ======================================================= EO CARTE CADEAU
//   .swiper__slide .swiper-slide .slide__content {
//     // left: 1.5rem;
//   }
//   .swiper__slide .swiper-slide .slide__content {
//     div {
//       padding: 0.2rem 0.5rem;
//     }
//   }
//   .swiper__slide .swiper-slide .hero__item__title {
//     font-size: 1.1rem;
//   }
//   .swiper__slide .swiper-slide .hero__item__slogan {
//     font-size: rem(13);
//     margin: 0.1rem 0;
//   }
// }
