// .filter__wrapper .hikashop_filter_main {
//   label::before {
//     width: 36px;
//     height: 36px;
//   }
// }

// ================================================================= MONTURE / COMPOSANTS SUPPLEMENTAIRE / COULEURS

.hikashop_filter_main_Monture_34,
.hikashop_filter_main_Composantssupplementaires_35,
.hikashop_filter_main_Couleurdelaperle_36 {
  label::before {
    width: 36px;
    height: 36px;
  }
}

.hikashop_filter_Monture_34 {
  input[value="or"] + label::before {
    background-image: url("../../../images/configurator/matiere/gold.png");
  }
  input[value="argent"] + label::before {
    background-image: url("../../../images/configurator/matiere/silver.png");
  }
  input[value="cuir"] + label::before {
    background-image: url("../../../images/configurator/matiere/leather.png");
  }
  input[value="fil-de-soie"] + label::before {
    background-image: url("../../../images/configurator/matiere/fil-soie.png");
  }
  input[value="cable-acier"] + label::before {
    background-image: url("../../../images/configurator/matiere/cable-acier.png");
  }
  input[value="elastique"] + label::before {
    background-image: url("../../../images/configurator/matiere/fil-elastique.png");
  }
  input[value="fil-de-laine"] + label::before {
    background-image: url("../../../images/configurator/matiere/fil-laine.png");
  }
}

//  --------------------- COMPOSANTS SUPPLEMENTAIRES

.hikashop_filter_Composantssupplementaires_35 {
  input[value="comp-pierre-naturelle"] + label::before {
    background-image: url("../../../images/configurator/matiere/stone.png");
  }
  input[value="comp-coquillage"] + label::before {
    background-image: url("../../../images/configurator/matiere/shellfish.png");
  }
}

//  --------------------- COULEURS

.hikashop_filter_Couleurdelaperle_36 {
  input[value="color-multi"] + label::before {
    background-image: url("../../../images/configurator/couleur/multicouleur.png");
  }
  input[value="color-green"] + label::before {
    background-image: url("../../../images/configurator/couleur/vert.png");
  }
  input[value="color-blue"] + label::before {
    background-image: url("../../../images/configurator/couleur/bleu.png");
  }
  input[value="color-aubergine"] + label::before {
    background-image: url("../../../images/configurator/couleur/aubergine.png");
  }
  input[value="color-gold"] + label::before {
    background-image: url("../../../images/configurator/couleur/gold.png");
  }
  input[value="color-silver"] + label::before {
    background-image: url("../../../images/configurator/couleur/argente.png");
  }
  input[value="color-gray"] + label::before {
    background-image: url("../../../images/configurator/couleur/gris.png");
  }
  input[value="color-white"] + label::before {
    background-image: url("../../../images/configurator/couleur/blanc.png");
  }
}

// ================================================================= PRIX
.hikashop_filter_main_Prix_38 {
  label::before {
    width: 28px;
    height: 28px;
    margin: 4px 0;
  }
}

.hikashop_filter_main_Prix_38 {
  label::before {
    content: "";
    border: 1px solid var(--opacity-1);
    border-radius: 50px;
  }
  input:checked + label::before {
    background-color: var(--color-1);
    @include icon("\f00c");
  }
  input:checked + label::after {
    display: none;
  }
  a[title="Supprimer cette sélection"] {
    display: none;
  }
}

// ================================================================= FORME DE LA PERLE

.hikashop_filter_main_Formedelaperle_37 {
  label::before {
    width: 36px;
    height: 36px;
  }
}

.hikashop_filter_Formedelaperle_37 {
  input[value="form-round"] + label::before {
    background-image: url("../../../images/configurator/forme/round.png");
  }
  input[value="form-semi-round"] + label::before {
    background-image: url("../../../images/configurator/forme/semi-round.png");
  }
  input[value="form-drop"] + label::before {
    background-image: url("../../../images/configurator/forme/drop.png");
  }
  input[value="form-baroque"] + label::before {
    background-image: url("../../../images/configurator/forme/baroque.png");
  }
  input[value="form-semi-baroque"] + label::before {
    background-image: url("../../../images/configurator/forme/semi-baroque.png");
  }
  input[value="form-cerclee"] + label::before {
    background-image: url("../../../images/configurator/forme/cercle.png");
  }
  input[value="form-gravee"] + label::before {
    background-image: url("../../../images/configurator/forme/grave.png");
  }
  input[value="form-keishi"] + label::before {
    background-image: url("../../../images/configurator/forme/keishi.png");
  }
}
