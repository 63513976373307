.espace-client--profil {
  // text-align: center;
  #jform_username-lbl,
  #jform_username {
    display: none;
  }

  #member-profile {
    max-width: 380px !important;
    // margin: auto;
  }
}
