.hikashop_cart {
  position: relative;
  top: 0;

  // --------------------- messsage erreur / stock

  #hikashop_messages_notice {
    display: none !important;
  }
}

.hikashop_cart_module {
  position: relative;
}

// ================================================================================== PANIER ALL
.btn__cart {
  position: relative;

  #icon-panier {
    position: relative;
    top: -6px;
    width: 45px;
    max-height: 45px;
    height: auto;
    path {
      fill: var(--dark);
    }
  }
}

.cart__qty {
  position: absolute;
  z-index: 10;
  top: 23px;
  right: -7px;
  width: 26px;
  height: 26px;
  background-color: var(--color-1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 4px 8px rgba($color: #000000, $alpha: 0.25);

  .qty__title {
    font-size: rem(12);
    color: var(--white);
  }
}

.cart__qty__wrapper {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
}

.cart__title {
  display: block;
  font-size: 1rem;
  font-weight: 500;
}

// ================================================================================== PANIER REMPLI

.btn__cart--full {
  path {
    // fill: var(--gradient-gold);
  }
}

// ================================================================================== PANIER FIXED

.btn__cart--fixed .btn__cart {
  position: fixed;
  z-index: 1001;
  right: 16px;
  top: 0;
  width: 90px;
  height: 100px;
  border-radius: 0 0 50px 50px;
  background-color: var(--color-1);
  box-shadow: -10px 20px 41px var(--gray-3);
  // border-style: solid;
  // border-color: var(--light);
  // border-width: 0 0 3px 3px;
  transform: translateY(-150px);
  svg {
    display: block;
    margin: auto;
    width: 40px;
    path {
      fill: var(--white) !important;
    }
  }
  .cart__qty {
    width: 26px;
    height: 26px;
    right: 15px;
    top: 36px;
    .qty__title {
      // font-size: rem(14);
    }
  }
  .cart__title {
    // font-size: rem(16);
    // color: var(--color-2);
    color: var(--white);
  }
}

.btn__cart--visible .btn__cart {
  transform: translateY(0px);
  transition: transform 0.2s;
}

// ================================================================================== PANIER VIDE

.cart__empty {
  position: fixed !important;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transform: translateY(-20px);
  top: 115px;
  right: 2rem;
  border-radius: 7px;
  padding: 1rem;
  background-color: var(--color-2);
  width: 300px;
  color: var(--white);
  box-shadow: 20px 40px 81px var(--gray-2);

  font-size: 1rem;

  span {
    display: block;
  }

  .cart__empty__close {
    cursor: pointer;
    position: absolute;
    font-size: 1.5rem;
    right: rem(6);
    top: 0;
  }
}

.btn__cart--empty .cart__qty {
  // background-color: var(--gray-7);
  // display: none;
}

.cart__empty--active {
  visibility: visible;
  transition: all 0.2s;
  opacity: 1;
  transform: translateY(0);
  z-index: 1000;
}
.cart__empty__title {
  font-family: var(--font-titre);
  font-size: rem(30);
}
.btn--empty {
  display: block;
  margin: rem(6) 0;
  padding: rem(5);
  background-color: rgba($color: #ffffff, $alpha: 1);
  color: var(--color-2);
  text-transform: uppercase;
  border: 2px solid rgba($color: #ffffff, $alpha: 1);
  transition: all 0.2s;
  &:hover {
    background-color: rgba($color: #ffffff, $alpha: 0.9);
    border-color: transparent;
  }
}

// ================================================================================== CONTAINER
.hikashop_cart_dropdown_content {
  position: fixed !important;
  // overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: -1 !important;
  display: block !important;
  z-index: -1;
  width: 90%;
  max-width: 330px;
  height: calc(85vh - 40px);
  max-height: 80vh;
  top: 50%;
  transform: translateY(-40%);
  right: 20px;
  border-radius: 15px;
  padding: 4px 8px;
  // transition: opacity 0.2s, transform 0.2s;
  transition: all 0.2s ease-in-out;
  background-color: var(--light);
  box-shadow: -20px 40px 81px var(--gray-2);
}

.cart--active .hikashop_cart_dropdown_content {
  visibility: visible;
  opacity: 1;
  transform: translateY(-50%);
  z-index: 1000 !important;
  pointer-events: initial;
}

// ------------------------------------------------------------------- CONTENT
.cart__content {
  position: relative;
  height: calc(100% - 230px);
  overflow-y: auto;
  margin-top: 1rem;
  // max-width: 330px;
}

.cart__content__title {
  display: block;
  line-height: 50px;
  font-family: var(--font-titre);
  font-size: rem(40);
  padding: rem(8) 0 rem(4) 0;
}

.cart__body {
  // margin: 1rem 0;
  background-color: var(--light);
}

// ------------------------------------------------------- ITEM
.cart__item {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  gap: 10px;
  text-align: left;
  padding: 6px 8px;
  margin-bottom: 4px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: var(--gray-4);
  border-radius: 7px;
  &:last-child {
    margin-bottom: 0;
  }
}
.cart__left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart__left,
.cart__right {
  position: relative;
}

// ------------------------------------------------------- CLOSE
.cart__close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  padding: 0 rem(6);
  background: var(--background-dark);
  text-transform: uppercase;
  color: var(--white);
  width: 50px;
  height: 50px;
  border-radius: 0 0 15px 0;
  transition: background 0.2s;

  &:hover {
    background-color: var(--color-1);
  }
  i {
    font-size: rem(25);
  }
}

// ------------------------------------------------------- IMG

.cart__img {
  display: flex;
  img {
    border-radius: 50%;
  }
}

// ------------------------------------------------------- NOM PRODUIT
.cart__name {
  display: block;
  // font-weight: 500;
  font-size: rem(13);
  line-height: 1.4;
  margin-right: 25px;
  color: var(--color-1);
  // text-decoration: underline;
  // color: var(--color-2);
}

// ------------------------------------------------------- PRODUCTEUR
.cart__producteur {
  // display: none;
  font-family: var(--font-titre);
  font-size: rem(20);
  line-height: 1;
  margin-bottom: 0.3rem;
  color: var(--color-1);
}

// ------------------------------------------------------- QUANTITE

.cart__qty__container {
  // display: none !important;
  display: flex;
  align-items: center;
}

.qty__value__title {
  white-space: nowrap;
  font-size: rem(12);
  // color: var(--color-2);
  // text-transform: uppercase;
  margin-right: rem(2);
  font-weight: 500;
}
.qty__value__result {
  font-size: rem(14);
}

.cart__qty__container input {
  border: 1px solid var(--gray-7);
  padding: 0;
  text-align: center;
  width: 30px;
  height: 25px;
  font-size: rem(14);
}

.hikashop_product_quantity_change_div_default,
.hikashop_cart_product_quantity_refresh {
  display: none !important;
}

.cart__qty__price {
  display: grid;
  grid-template-columns: 1fr 1fr;
  //   justify-items: flex-end;
  align-items: center;
  margin-top: rem(4);
}

.hika__cart__qty__price {
  display: none;
}

.cart__qty__input {
  // width: 25px;
  // height: auto;
  // font-size: rem(15);
  // padding: 0;
  // border: 0;
  // text-align: center;
  // margin: 0 0px;
  // font-weight: bold;
  // &:hover,
  // &:focus {
  //   border: 0;
  //   background-color: transparent;
  // }
}

.cart__qty__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 7px;
  border: 1px solid var(--gray-7);
  color: var(--gray-8);
  font-size: 1.5rem;
}

// ------------------------------------------------------- PRIX ITEM
.cart__qty__price .cart__price {
  text-align: right;
  .hikashop_product_price {
    text-align: right;
    margin-left: rem(0);
    font-weight: bold;
    font-size: rem(15);
  }
}

// ------------------------------------------------------- PRIX TOTAL

.cart__price__total {
  position: relative;
  // margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: right;
  // text-transform: uppercase;
  font-size: rem(20);
  font-weight: 500;
  padding: 5px 0;
  // color: var(--color-2);
  // border-top: 1px solid var(--gray-7);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 1px;
    // background-color: var(--gray-7);
  }
  .price__total__title {
    opacity: 0;
  }
  .hikashop_product_price {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.price__total__title {
  // text-align: right;
}

.hikashop_product_price_full {
  // text-align: right;
  // padding-right: 1rem;
}
// ------------------------------------------------------- DELETE
.cart__delete {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  color: var(--gray-8);
}

// ------------------------------------------------------- BOUTON PAIEMENT

.btn--cart--top,
.btn--cart--bottom {
  font-size: 1rem;
  width: 100%;
  padding: 14px;
}

.cart__checkout__mobile {
  display: none;
}

// ------------------------------------------------------- SPINNER & ANIMATIONS
// #hikashop_cart_module .hikashop_checkout_loading_elem,
// #hikashop_cart_module .hikashop_checkout_loading_spinner {
//   display: none !important;
//   background-color: transparent !important;
// }
// #hikashop_cart_module .hikashop_checkout_loading_spinner.small_spinner {
//   background-image: none !important;
//   background-color: transparent !important;
// }

// .hikashop_checkout_loading .qty__title {
//   opacity: 0;
//   transform: translateY(-3px);
//   transition: all 0.15s;
//   // transform: scale(2);
// }

// ------------------------------------------------------- EO SPINNER & ANIMATIONS

// ------------------------------------------------------- popup notify / Display none
.notifyjs-container {
  display: block !important;
  min-width: 300px;
}

.notifyjs-corner {
  display: none !important;
  .notifyjs-wrapper {
    animation: 0.15s scale;
  }

  @keyframes scale {
    0% {
      transform: scale(0, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .notifyjs-metro-base,
  .notifyjs-metro-lite-base {
    min-height: auto;
  }

  .notifyjs-arrow {
  }

  .notifyjs-metro-base.notifyjs-metro-info,
  .notifyjs-metro-base.notifyjs-metro-error {
    color: var(--light) !important;
    cursor: default;
    border: none !important;
    border-radius: 7px;
    box-shadow: 10px 20px 41px var(--gray-2);

    .image,
    .title {
      display: none !important;
    }
    .text-wrapper {
      display: block;
      text-align: center;
      margin: 0 !important;
      padding: 1rem 1rem;
    }
    .text {
      display: inline-flex;
      align-items: center;
      font-size: rem(22);
      font-weight: 500;
    }
    i {
      margin-right: rem(8);
      font-size: 2rem;
    }
  }
  .notifyjs-metro-base.notifyjs-metro-info {
    background-color: var(--color-2) !important;
  }
  .notifyjs-metro-base.notifyjs-metro-error {
    background-color: var(--error) !important;
  }
}

// ================================================================================== POPUP ADD TO CART

.add__cart__modal {
  position: fixed;
  visibility: hidden;
  z-index: 1001;
  transform-origin: center;
  top: 1rem;
  left: 50%;
  margin-left: calc(330px / 2);
  transform: translateX(-50%);
  width: 90%;
  max-width: 340px;

  .add__cart__modal__item {
    position: relative;
    background-color: var(--color-2);
    transform: scale(0, 1);
    box-shadow: 10px 20px 41px var(--gray-2);
    border-radius: 5px;
    color: var(--white);
    font-size: rem(20);
    transition: all 0.35s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin-bottom: 1rem;
    opacity: 0;
  }
  svg {
    margin-right: 0.7rem;
  }
  path {
    fill: white;
  }

  .add__cart__modal__item--active {
    visibility: visible;
    z-index: 1001;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    opacity: 1;
  }
}

// ======================================================= RESPONSIVE

// -------------------> header-top.scss
// -------------------> header.scss

@include breakpoint(1700) {
  .add__cart__modal {
    margin-left: calc(275px / 2);
  }
}

@include breakpoint(1550) {
  .btn--cart--top {
    display: none;
  }
  .hikashop_cart_dropdown_content {
    height: calc(95vh - 40px);
  }
  .cart__content {
    height: calc(100% - 170px);
    margin-top: 0;
  }
}

@include breakpoint(1440) {
  .add__cart__modal {
    margin-left: calc(250px / 2);
  }
}

@include breakpoint(1180) {
  // ------ Suppression du pop, lors du clic sur l'ajout au panier, descendre le header
  .btn__cart--fixed .btn__cart svg path {
    fill: var(--dark) !important;
  }
  .add__cart__modal {
    display: none;
    // margin-left: 0;
    //   right: 0;
    //   top: 110px;
    //   left: initial;
    //   transform: initial;
    //   width: 270px;
    //   // width: 100%;
    //   .add__cart__modal__item {
    //     font-size: rem(16);
    //   }
    //   svg {
    //     width: 40px;
    //     height: auto;
    //     margin-right: rem(8);
    //   }
  }
  .notifyjs-corner {
    top: 110px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info .text-wrapper,
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-error .text-wrapper {
    padding: 1rem;
  }
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info svg,
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-error svg {
    height: 35px;
  }

  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info .text,
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-error .text {
    font-size: 1.1rem;
  }
}

@include breakpoint(1180) {
  .hikashop_cart_dropdown_content {
    transform: translate(0, 100%);
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
    overflow: hidden;
    visibility: hidden;
    opacity: 1;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;
    transition: all 0.4s;
    padding: 0;
  }
  .cart--active .hikashop_cart_dropdown_content {
    right: initial;
    transform: translate(0, 0);

    // transition: transform 0.5s ease-in-out;
  }
  .cart__close {
    height: 63px;
    border-radius: 0;
  }
  .cart__content__title {
    border-bottom: 1px solid var(--color-2);
    background-color: var(--color-2);
    color: var(--white);
  }
  .cart__content {
    min-height: 280px;
  }
  .body--cart--active {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    // ------------- suppression du bouton Haut si panier ouvert
    .btn__cart--full {
      // display: none;
    }
  }
  .cart__item {
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding: 6px 12px;
  }
  .cart__delete {
    font-size: 1.3rem;
  }
  .cart__price__total {
    display: none;
  }
  .cart__price__total--mobile {
    display: block;
    font-size: rem(16);
    .price__total__title {
      margin-right: rem(16);
    }
  }
  .cart__price__total::before {
    content: none;
  }
  .cart__checkout__mobile {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 100;
    background-color: var(--light);
    padding: 0 1rem 1rem 1rem;
    border-top: 2px solid var(--gray-7);
  }
  .cart__checkout__mobile__btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .btn--cart--bottom {
    display: none;
  }
  .hikashop_cart_dropdown_content .btn,
  .hikashop_cart_dropdown_content .edit-icon a,
  .edit-icon .hikashop_cart_dropdown_content a {
    padding: 10px;
  }

  // ------------------------- pop up
  .notifyjs-container {
    min-width: 260px;
  }

  .notifyjs-corner {
    top: 100px !important;
  }

  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info .text-wrapper,
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-error .text-wrapper {
    padding: 1rem 0;
  }

  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info i,
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-error i {
    font-size: 1.8rem;
  }

  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-info .text,
  .notifyjs-corner .notifyjs-metro-base.notifyjs-metro-error .text {
    font-size: 0.9rem;
  }
}
