// ====================================== HEADER

#hikashop_order_listing {
  .hk-row-fluid {
    // display: block;
  }
  .hkc-md-6 {
    width: 100%;
  }
  .hikashop_search_block {
    @include flexbox;
    @include align-items(center);
    gap: 2rem;
  }

  .hikashop_order_sort {
    @include flexbox;
    @include align-items(center);
    // @include flex-flow(row wrap);
    gap: 0.5rem;
    margin: 1rem 0;

    .hikashop__order__count {
      min-width: 230px;
    }
  }
}

@include breakpoint(880) {
  #hikashop_order_listing .hikashop_search_block {
    display: block;
    .btn {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
  #hikashop_order_listing .hikashop_order_sort {
    @include flex-flow(row wrap);

    .hikashop__order__count {
      display: block;
      width: 100%;
    }
    #filter_order_status {
      display: none;
    }
  }
}

// ====================================== EO HEADER

.hikashop_order_listing {
  // ------------------- no order
  .hika_no_orders {
    background-color: var(--light);
    // border: none;
    box-shadow: none;
  }

  // ------------------- EO no order
}

#hikashop_order_listing {
  .hikashop_search_zone {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    display: grid;
    grid-template-columns: 450px 1fr;
    gap: 25px;
    padding: 0;
    margin-bottom: 2rem;
    display: block;

    input,
    select {
      height: 50px !important;
      //   max-width: 550px !important;
    }

    .btn--search {
      display: none;
    }

    .hikashop_search_block {
      @include flexbox;
      @include align-items(baseline);
      gap: 10px;
      margin-bottom: 1rem;
    }

    .hikashop_order_sort {
      @include flexbox;
      @include justify-content(flex-start);
      @include align-items(baseline);
      @include flex-flow(row wrap);
      gap: 10px;
      select {
        max-width: 250px !important;
      }
    }
  }
}

// --------------------------------------------- card
.hk-card-order {
  .hika_cpanel_date {
    span {
      margin-right: 1rem;
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  // --------------------------- listes des produits
  .hk-list-group-item {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-flow(row wrap);
    display: grid;
    grid-template-columns: 50px 1fr 200px;
    gap: 10px;
    border-color: var(--gray-6) !important;
    background-color: transparent;

    div {
      display: none;
    }
  }
  .hk-list-group-item:last-of-type {
    border-bottom: none !important;
    border-radius: 0 0 10px 10px;
  }
  .hika_order_product_more {
    display: block;
    color: var(--dark) !important;

    &:hover {
      background-color: initial !important;
    }
  }

  .hika_cpanel_product_price_quantity {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-right: 7px;
    &::before {
      content: "Quantité";
      font-size: 0.7rem;
    }
  }
  // --------------------------- quantité / prix
  .hika_order_product_price {
    text-align: right;
  }

  .hika_cpanel_product_price_times {
    display: none;
  }

  .hika_cpanel_product_price_amount {
    font-weight: bold;
    font-size: 1.4rem;
  }
}

@include breakpoint(1700) {
  #hikashop_order_listing .hikashop_search_zone {
    grid-template-columns: 1fr;
    input {
      max-width: 550px !important;
    }
  }
  #hikashop_order_listing .hikashop_search_zone .hikashop_order_sort {
    @include justify-content(flex-start);
    margin-left: 8px;
  }
}

@include breakpoint(768) {
  .hk-card-order .hika_cpanel_date span {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .hk-card-order .hk-list-group-item {
    @include flexbox;
    @include justify-content(flex-start);
    gap: 0;
    .hika_order_product_image_link {
      margin-right: 7px;
    }
    .hika_order_product_name {
      font-size: 0.85rem;
    }
    .hika_order_product_price {
      width: 100%;
      text-align: right;
    }
  }
}

@include breakpoint(550) {
  #hikashop_order_listing .hikashop_search_zone .hikashop_order_sort {
    @include justify-content(center);
    gap: 0;
  }
  .hk-card-order .hika_order_number_value,
  .hk-card-order .hika_invoice_number_value {
    display: block;
  }
  #hikashop_order_listing .hikashop_search_zone .hikashop_order_sort select {
    max-width: 100% !important;
    margin: 5px 0;
  }
}

@include breakpoint(360) {
  .hk-card-order .hk-card-header {
    display: block;
    // text-align: center;
    .hika_cpanel_date {
      text-align: left;
    }
  }
}
