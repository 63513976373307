.header__search {
  position: relative;

  form {
    min-width: 200px;
  }
}

.header__search__result {
  position: absolute;
  left: 15px;
  top: calc(60px + 0.5rem);
  z-index: -1;
  background: var(--light);
  width: calc(100% - 10px);
  padding: 0.5rem 1rem;
  box-shadow: 0 10px 21px var(--gray-3);
  border: 1px solid var(--gray-3);
  border-radius: 10px;
  opacity: 0;

  &.active {
    opacity: 1;
    z-index: 1000;
  }

  .header__search__body {
    max-height: 250px;
    overflow: auto;
  }

  .item {
    border-bottom: 1px dashed var(--gray-1);

    &:nth-child(5) {
      border-bottom: none;
    }

    a {
      // display: block;
      padding: 0.65rem 0;
      @include flexbox;
      @include justify-content(space-between);
    }

    .search_result_cat {
      // background-color: var(--color-1);
      color: var(--dark);
      font-size: 0.8rem;
      font-size: clamp(0.7rem, 0.85vw, 0.85rem);
      padding: 0.1rem 0.5rem;
      border-radius: 50px;
      border: 1px solid var(--dark);
      font-weight: 500;
    }
  }

  .search_result_name {
    font-size: clamp(0.8rem, 1vw, 1rem);
  }

  .search_result_price {
    text-align: right;
    display: none;
  }

  .highlighted {
    font-weight: 500;
    color: var(--color-1);
  }

  .header__search__footer {
    margin: 0.5rem 0 0.2rem 0;

    .btn {
      width: 100%;
      border-radius: 10px;
    }
  }
}

@include breakpoint(1440) {
  .header__search__result {
    top: calc(50px + 0.5rem);
  }
}

@include breakpoint(1180) {
  .header__search__result {
    left: 0;
    width: 100%;
  }
}

@include breakpoint(650) {
  .header__search__result .item a {
    display: block;
    padding: 0.2rem 0;
    .search_result_name {
      display: block;
      margin-bottom: 0.3rem;
    }
  }
}

@include breakpoint(550) {
  .header__search__result .item {
    padding: 0.3rem 0;
  }
}
