html {
  // scroll-behavior: smooth; / Remplacé par le smooth JS compatible IOS
  height: 100%;
}

body {
  word-break: break-word;
  background-color: var(--light);
  text-rendering: optimizeLegibility;
  // height: 4000px;
}

.body--active {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

// ------------------------ not exist
// #wrapper {
//   position: relative;
//   margin: auto;
//   min-height: 100vh;
// }

#wrapper {
  max-width: 125rem;
  margin: auto;
}

.main {
  margin-left: var(--header-width);
  padding: 0 var(--full-width);
  // overflow: hidden;
}

#maincontent {
  // max-width: 1200px;
  // margin: auto;
}

.page-home {
  .main {
    overflow: hidden;
  }
}

.container {
  max-width: 1200px;
  margin: auto;
}

.section {
  padding: rem(200) 0;
}

.content__section {
  padding: 1.5rem 0;
}

.full-width {
  width: calc(100% + var(--full-width) * 2);
  margin-left: calc(-1 * var(--full-width));
}

// ====================================================================== RESPONSIVE

@include breakpoint(1800) {
  .main {
  }
  .footer {
    margin-left: var(--header-width);
  }
  .container {
    // margin: 0 rem(45);
  }
}

@include breakpoint(1440) {
  .main {
  }

  .containter {
    // margin: 0 rem(32);
  }
}

@include breakpoint(1180) {
  .main {
    margin-left: 0;
    margin-top: 80px;
  }

  .containter {
    // margin: 0 rem(26);
  }
}

@include breakpoint(750) {
}

@include breakpoint(550) {
  .container {
    margin: 0;
    padding: 0;
  }
  .main {
    margin-top: 80px;
  }
}

@include breakpoint(440) {
  .main {
    margin-top: 70px;
  }
}
