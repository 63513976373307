// ------ Module

.home__category {
  // @extend %bg-home;

  .title__main {
  }
}

.hikashop_categories_listing_main {
  --category-height: 300px;

  //   -------------------- container
  .hk-row-fluid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    max-width: 980px;
    padding: 0 1rem;
    margin: auto;
    &::before,
    &::after {
      content: none;
    }
  }

  .hikashop_subcontainer {
    background-color: transparent !important;
  }

  //   -------------------- category

  .hikashop_category {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    height: var(--category-height);
    width: 100% !important;
    border-radius: 10px;
    overflow: hidden;
    .hikashop_category_image {
      position: relative;
      height: var(--category-height) !important;

      &::before {
        // display: none;
        content: "";
        position: absolute;
        pointer-events: none;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.15);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .hikashop_category_name {
      position: absolute;
      width: 100%;
      //   background: rgba($color: #c769bd, $alpha: 0.7);
      left: 0;
      text-align: left !important;
      font-family: var(--font-titre);
      z-index: 10;
      bottom: 0;
      padding: 5px 0 5px 15px;
      color: var(--white);
      //   text-transform: uppercase;
      font-weight: 500;
      font-size: clamp(1.5rem, 2.2vw, 2.2rem);
      text-shadow: 0 2px 4px rgba($color: #000000, $alpha: 1);
      // text-shadow: var(--shadow-text);
    }
  }
}

@include breakpoint(1280) {
  .hikashop_categories_listing_main {
    .hk-row-fluid {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
  }
}

@include breakpoint(550) {
  .hikashop_categories_listing_main {
    --category-height: 225px;
    .hk-row-fluid {
      display: grid;
      gap: 0.5rem;
      padding: 0 0.5rem;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }
}
