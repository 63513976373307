.header {
  position: fixed;
  width: var(--header-width);
  height: 100vh;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: var(--light);
  box-shadow: 20px 40px 80px var(--gray-1);
  transition: width 0.3s;
}

.header__container {
  @include flexbox;
  @include flex-flow(column wrap);
}

.header__services {
  @include hidden;
  display: none !important;
}

//==================================================================== LOGO
.header__logo {
  text-align: center;
  margin: 0.5rem 0 2rem 0;
  svg,
  img {
    display: block;
    margin: 1rem auto;
    max-width: 100%;
    height: auto;
    padding: 0 1rem;
    // width: 190px;
    // filter: drop-shadow(5px 15px 5px rgb(0 0 0 / 0.35));

    path {
      //   animation: stroke 7s;
    }
  }

  .logo__sigle {
    margin-bottom: 1rem;
  }

  .logo__sigle svg {
    max-width: 75px;
  }
  .logo__texte svg {
    max-width: 190px;
  }
  .logo a {
    path {
      // transition: fill 300ms;
    }
    &:hover path {
      // fill: var(--color-2);
    }
  }
}

//==================================================================== SLOGAN

.header__slogan {
  // display: none;
  display: block;
  padding: 2px 0;
  font-family: var(--font-titre);
  font-size: rem(24);
  background-color: var(--gray-6);
}

//==================================================================== MENU

.header__menu {
  .menu__main {
    position: relative;
    z-index: 10;
    margin-left: rem(28);
    background-color: var(--light);

    //------------------------------------------------------ li
    .item-101 {
      display: none;
    }
    li {
      position: relative;
      @include flexbox;
      @include align-items(center);
    }

    .menu__img {
      @include flexbox;
      @include align-items(center);
      width: 40px;
      height: 40px;
      margin-right: 1rem;
      text-align: center;
    }

    img {
    }

    //------------------------------------------------------ a
    .menu__item--link {
      display: flex;
      align-items: center;
      font-size: rem(18);
      font-weight: 500;
      // text-transform: uppercase;
      padding: var(--gap) 0;
      transition: transform 200ms;
      width: 100%;
      cursor: pointer;
      // padding: 1rem 0;

      //------------------------------------------------------ icons
      &::before {
        // content: "";
        // display: block;
        // width: 65px;
        // height: 40px;
        // background-color: #e3e3e3;
        // border-radius: 50%;
        // margin-right: rem(16);
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center;
        // border: 3px solid transparent;
        // transition: transform 200ms, border 200ms;
        // box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.3);
      }
    }
    //------------------------------------------------------ hover
    .menu__item--link:hover,
    li.active .menu__item--link {
      // transform: translateX(3px);
      // color: var(--color-2);

      &::before {
        // transform: translateX(-3px);
        border-color: var(--color-2);
      }
    }
  }

  //---------------------------- icons images
  // .item-107 a::before {
  //   background-image: url("/images/icons/menu/bague.svg");
  // }
  // .item-108 a::before {
  //   background-image: url("/images/icons/menu/icon-confit.png");
  // }
  // .item-109 a::before {
  //   background-image: url("/images/icons/menu/icon-charcuterie.png");
  // }
  // .item-110 a::before {
  //   background-image: url("/images/icons/menu/icon-plats.png");
  // }
  // .item-111 a::before {
  //   background-image: url("/images/icons/menu/icon-coffrets.png");
  // }
  // .item-112 a::before {
  //   background-image: url("/images/icons/menu/icon-carte.png");
  // }
  // .item-349 a::before,
  // .item-347 a::before {
  //   background-image: url("/images/icons/menu/icon-vegetables.png");
  // }
}

//==================================================================== BOUTON MENU
#menu__btn {
  display: none;
  // position: absolute;
  // top: 45%;
  // transform: translateY(-50%);
  // left: 30px;
  cursor: pointer;
  z-index: 100;
  outline: 0;
  background-color: transparent;
  border: 0;
  background-color: var(--color-1);
  color: var(--white);
  width: 55px;
  height: 55px;
  border-radius: 50px;
  .menu__mobile__title {
    @include inline-flex;
    line-height: 1;
    font-family: var(--font-titre);
    font-size: 1.1rem;
    // margin-top: rem(15);
    // letter-spacing: rem(1.3);
    color: var(--white);
  }
}

.menu__bar {
  position: relative;
  display: block;
  width: 30px;
  height: 1px;
  background-color: var(--white);
  margin-top: 7px;
  border-radius: 2px;
  // transition: all 150ms;
  &:first-of-type {
    margin-top: 10px;
  }
}

#menu__btn.menu__btn--active {
  // position: relative;
  .menu__bar--1 {
    opacity: 0;
  }
  .menu__bar--2 {
    transform: rotate(45deg);
  }
  .menu__bar--3 {
    transform: rotate(-45deg);
    top: -8px;
  }
}

//==================================================================== SOCIAL

.header__social {
  @include deco-before(30px);
  position: absolute;
  bottom: 0;
  padding: 0 0 1.5rem 0;
  background-color: var(--color-1);
  color: var(--white);
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1;

  &::before {
    opacity: 1;
  }

  .header__reseaux {
    padding: 4px 0;
  }

  a {
    margin: 0 2px;
  }
}

//==================================================================== EO SOCIAL

.header__mentions {
  font-size: 0.8rem;
  font-size: clamp(0.7rem, 0.9vw, 0.9rem);
}

//==================================================================== BY

.header__by {
  position: absolute;
  z-index: 10;
  width: 100%;
  left: 0;
  bottom: rem(10);
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: rem(11);
  color: var(--white);
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
  }
  svg {
    width: auto;
    height: 12px;
    margin-left: 0.5rem;
    path {
      fill: white;
    }
  }
}

//============================================================================================= 1800

@include breakpoint(1800) {
  .header {
    width: var(--header-width);
  }

  //==================================================================== LOGO
  .header__logo {
    margin-bottom: 2rem;
    svg,
    img {
      // width: 180px;
      margin-bottom: 0;
    }
    .logo__sigle {
      margin-bottom: 0.5rem;
    }

    .logo__sigle svg {
      max-width: 60px;
    }
    .logo__texte svg {
      max-width: 150px;
    }
  }
  //==================================================================== SLOGAN

  .header__slogan {
    font-size: rem(22);
    margin-bottom: 0;
  }

  //==================================================================== MENU
  .header__menu {
    .menu__main {
      margin-left: rem(12);

      li {
        &::after {
          width: 200px;
        }
      }
      .menu__img {
        margin-right: 0.7rem;
        img {
          width: 30px;
          max-height: 30px;
        }
      }

      .menu__item--link {
        font-size: 1rem;
        padding: 0.7rem 0;
        &::before {
          width: 55px;
          height: 55px;
        }
      }
    }
  }
}

//============================================================================================= 1440

@include breakpoint(1440) {
  //==================================================================== LOGO
  .header__logo {
    svg,
    img {
      // width: 130px;
      // margin-bottom: rem(8);
      // filter: drop-shadow(3px 9px 3px rgba(0, 0, 0, 0.25));
    }
    .logo__sigle svg {
      max-width: 50px;
    }
    .logo__texte svg {
      max-width: 130px;
    }
  }
  .header {
    width: var(--header-width);
  }

  //==================================================================== SLOGAN

  .header__slogan {
    font-size: rem(18);
  }

  //==================================================================== MENU
  .header__menu {
    .menu__main {
      margin-left: rem(12);

      li {
        &::after {
          width: 180px;
        }
      }
      .menu__img {
        margin-right: 0.5rem;
        img {
          max-height: 25px;
        }
      }
      .menu__item--link {
        font-size: rem(14);
        padding: 0.5rem 0;
        &::before {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  //==================================================================== RESEAUX
  .header__social .header__reseaux {
    font-size: 1.3rem;
  }

  //==================================================================== BY

  .header__by {
    bottom: rem(7);
    font-size: rem(8);
    img,
    svg {
      height: rem(9);
      margin-left: rem(5);
    }
  }
}

//============================================================================================= 1280
@include breakpoint(1280) {
  .header__logo {
    margin: 0.5rem auto 1.5rem auto;
    @include flexbox;
    @include align-items(center);
  }
  .header__logo svg,
  .header__logo img {
    // margin: 0.5rem auto;
    // width: 110px;
  }

  .header__social .header__reseaux {
    font-size: 1.2rem;
    padding: 2px 0 0 0;
  }
}

//============================================================================================= 1180

@include breakpoint(1180) {
  .header {
    width: 100%;
    overflow: initial;
    max-height: var(--header-height);
    box-shadow: 10px 20px 41px var(--gray-1);
    padding: 0 1rem;
  }
  #logo--full {
    // display: none;
  }

  .header__container {
    @include flex-flow(row wrap);
    @include align-items(center);
    @include justify-content(space-between);
    gap: 2rem;
    height: var(--header-height);
  }

  .header__logo {
    margin: 0;
    svg,
    img {
      max-height: var(--header-height);
      width: auto;
      margin: 0;
      padding: 0.8rem 0;
    }
  }

  .header,
  .header__top__services {
    transition: transform 0.4s ease-in-out;
  }

  .header--hidden {
    transform: translateY(-140px);
  }

  .header__social {
    display: none;
  }

  // ================================================================================== PANIER FIXED

  .btn__cart--fixed .btn__cart {
    position: initial;
    z-index: initial;
    right: initial;
    top: initial;
    width: initial;
    height: initial;
    border-radius: initial;
    background-color: initial;
    box-shadow: initial;
    transform: initial;
    svg {
      display: initial;
      margin: initial;
      width: initial;
    }
    .cart__qty {
      top: 23px;
      width: 24px;
      height: 24px;
      right: -7px;
      .qty__title {
        // font-size: initial;
      }
    }
    .cart__title {
      // font-size: initial;
      // color: var(--color-2);
    }
  }

  .btn__cart--visible .btn__cart {
    transform: initial;
    transition: initial;
  }

  //==================================================================== LOGO

  .header__logo {
    // position: absolute;
    // padding: 0;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // margin: 0;

    .logo a {
      @include flexbox;
      @include align-items(center);
      gap: 0.5rem;
    }

    .logo__sigle {
      // @include hidden;
      margin: 0;
    }
    .logo__texte {
      max-width: 160px;
    }
    svg,
    img {
      // position: relative;
      // top: 7px;
      // height: 130px;
      // width: auto;
      // margin: 0;
      // filter: drop-shadow(3px 9px 3px rgba(0, 0, 0, 0.15));
    }
  }

  //==================================================================== SLOGAN

  .header__slogan {
    display: none;
  }

  //==================================================================== MENU
  #menu__btn {
    @include flexbox;
    @include flex-flow(column wrap);
    @include align-items(center);
  }
  .header__menu {
    // position: absolute;
    // z-index: -1;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    .menu__main {
      position: fixed;
      top: var(--header-height);
      right: -300px;
      margin: 0;
      width: 280px;
      height: 100vh;
      max-height: calc(100vh - 80px);
      overflow-y: auto;
      padding: rem(16) 0 0 rem(16);
      padding-left: rem(16);
      background-color: var(--light);
      box-shadow: 10px 20px 41px var(--gray-1);
      transition: all 200ms;
      z-index: -1;
      border-top: 1px solid var(--gray-6);
      // border-right: 1px solid var(--gray-6);

      li {
      }

      .menu__item--link {
        font-size: rem(15);
        // text-transform: lowercase;
      }
      .menu__item--link::before {
        // width: 40px;
        // height: 40px;
        border-width: 2px;
        box-shadow: 2px 5px 11px var(--gray-2);
      }
    }
  }

  // -------------------------- menu active
  .header__menu--active .menu__main {
    right: 0;
    z-index: 101;
    padding-bottom: 2rem;
  }

  //==================================================================== BY

  .header__by {
    position: fixed;
    z-index: 1001;
    width: 280px;
    left: -100%;
    transition: all 0.2s;

    a,
    svg path {
      color: var(--dark);
      fill: var(--dark);
    }
  }
  .header__menu--active .header__by {
    left: 0;
    background: var(--gray-6);
    color: var(--dark);
    padding: 8px 0;
    bottom: 0;
    border-top: 1px solid var(--gray-6);
    box-shadow: -10px -10px 21px var(--gray-1);
  }

  //==================================================================== LOGIN / CART
  .header__top {
    .header__top__services {
      position: fixed;
      top: 0;
      right: 120px;
      z-index: 101;
      height: var(--header-height);
    }
    .header__connexion {
      // margin-right: 1.5rem;
    }
  }

  // .header__services {
  //   position: absolute;
  //   top: 7px;
  //   right: 30px;
  //   display: inline-flex;
  //   text-align: center;

  //   .header__connexion {
  //     margin-right: 1.5rem;
  //   }
  // }
}

//============================================================================================= 1024

@include breakpoint(1024) {
  .header {
    box-shadow: 5px 10px 21px var(--gray-1);
  }

  //==================================================================== LOGO

  .header__logo {
    svg,
    img {
      // height: 120px;
      // filter: none;
    }
  }
}

//============================================================================================= 750

@include breakpoint(750) {
  // .menu__body--active {
  //   height: 100vh;
  //   overflow: hidden;
  // }

  .header {
    box-shadow: 5px 10px 21px var(--gray-1);
  }

  //==================================================================== LOGO

  .header__logo {
    svg,
    img {
      // height: 100px;
      // top: 4px;
      // height: 76px;
    }
  }
  .header__logo::after {
    width: 125%;
    height: 123%;
    left: 55%;
  }

  //==================================================================== MENU
  .header__menu {
    .menu__main {
      position: fixed;
      top: var(--header-height);
      right: -100%;
      margin: 0;
      padding: rem(32) 0 0 0;
      width: 100%;
      height: 100vh;
      background-color: var(--background-dark);
      border-top: 1px solid var(--gray-6);
      border-right: 0;
      box-shadow: none;
      color: var(--white);

      li {
        padding-left: 1rem;
      }
      li::after {
        // content: none;
        width: 100%;
        background-color: rgba($color: white, $alpha: 0.1);
      }
      li:last-child {
        margin-bottom: 28px;
      }
      .menu__item--link {
        font-size: rem(15);
        padding: 8px 0;
      }
      .menu__item--link::before {
        // width: 40px;
        // height: 40px;
        border-width: 2px;
        box-shadow: 2px 5px 11px var(--gray-2);
      }
    }
  }
  .header__menu--active .menu__main {
    right: 0;
  }
  //==================================================================== LOGIN / CART
  .header__top .header__top__services {
    right: 90px;
    .header__connexion {
    }
    .header__connexion .item__title,
    .cart__title {
      font-size: rem(12);
    }
  }
  //==================================================================== BY

  .header__menu--active .header__by {
    // display: none;
    width: 100%;
    background-color: var(--light);
    border: 0;
    box-shadow: none;
    a,
    path {
      color: var(--dark);
      fill: var(--dark);
    }
  }
}

//============================================================================================= 550

@include breakpoint(550) {
  .header__container {
    gap: 0.5rem;
  }
  .header__logo svg,
  .header__logo img {
    max-width: 35vw;
    height: auto;
  }
  .header__logo .logo__sigle svg {
    max-width: 30px;
  }
  .header__logo .logo__texte svg {
    max-width: 100px;
  }
  .header__menu .menu__main .menu__item--link {
    font-size: rem(14);
    // padding: 6px 0;
  }
  .header__menu .menu__main .menu__item--link::before {
    width: 45px;
    height: 45px;
  }
  .header__menu #menu__btn {
    left: 1rem;
  }
  .header--hidden {
    transform: translateY(-140px);
  }

  #menu__btn {
    width: 50px;
    height: 50px;
  }

  //==================================================================== LOGIN / CART
  .header__top .header__top__services {
    // top: 14px;

    .header__connexion svg,
    .header__favoris svg,
    .btn__cart #icon-panier {
      width: 35px;
      max-height: 35px;
    }
    .header__connexion {
    }
    .header__connexion .item__title,
    .header__favoris .item__title,
    .cart__title {
      font-size: rem(12);
      // text-transform: uppercase;
    }
    .cart__qty {
      top: 18px;
      width: 20px;
      height: 20px;
    }
    .cart__qty .qty__title {
      font-size: rem(10);
    }
  }
}

@include breakpoint(440) {
  .header {
    // max-height: 70px;
  }
  .header__container {
    // height: 70px;
  }
  //==================================================================== LOGIN / CART
  .header__top .header__top__services {
    right: 85px;
    .header__connexion svg,
    .header__favoris svg,
    .btn__cart #icon-panier {
      width: 30px;
      max-height: 30px;
    }

    .header__connexion .item__title,
    .header__favoris .item__title,
    .cart__title {
      display: none;
    }
  }
  #menu__btn .menu__mobile__title {
    font-size: 1rem;
  }
  .header__menu .menu__main {
    // top: 70px;
    max-height: calc(100vh - var(--header-height));
  }
}

@include breakpoint(360) {
  //==================================================================== LOGIN / CART
  .header__top .header__top__services {
    .header__connexion svg,
    .header__favoris svg,
    .btn__cart #icon-panier {
      width: 24px;
      max-height: 24px;
    }
  }
  .header__top .header__top__services .cart__qty {
    top: 13px;
  }
  .header__top .header__top__services {
    right: 72px;
  }
  #menu__btn {
    width: 40px;
    height: 40px;
  }
  #menu__btn .menu__mobile__title {
    font-size: 0.85rem;
  }
  .menu__bar {
    margin-top: 5px;
    width: 20px;
  }
}
