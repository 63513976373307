.checkout__payement {
  padding: var(--section) 1rem;
  max-width: 980px;
  margin: auto;
  text-align: center;

  .payement__infos {
    svg {
      display: block;
      margin: auto;
      width: 150px;
      height: auto;
    }

    .payement__title {
      display: block;
      margin-top: 1rem;
      font-size: rem(35);
      font-weight: bold;
    }

    .payement__description {
      margin: 2rem 0;
      font-size: rem(25);
    }
  }

  .payement__success {
    path {
      fill: var(--success);
    }
  }
  .payement__error {
    path {
      fill: var(--gray-8);
    }
  }

  .payement__buttons {
    .btn {
      margin: 4px;
    }
  }
}

@include breakpoint(980) {
  .checkout__payement .payement__infos svg {
    width: 100px;
  }
  .checkout__payement .payement__infos .payement__title {
    font-size: 1.8rem;
  }
  .checkout__payement .payement__infos .payement__description {
    font-size: 1.3rem;
  }
}

@include breakpoint(550) {
  .checkout__payement {
    padding: var(--section) 0.5rem;
  }
  .checkout__payement .payement__infos svg {
    width: 80px;
  }
  .checkout__payement .payement__infos .payement__title {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .checkout__payement .payement__infos .payement__description {
    font-size: 1rem;
  }
}
