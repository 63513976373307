// Menu
// Images
// Modules

.page-administration {
  background-color: var(--admin-color-g1);
  .main {
    margin-left: var(--admin-header-width);
    padding: 0 var(--admin-padding);
    padding-bottom: 5rem;
  }

  .main__top {
    padding: 1rem 0;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--admin-color-g2);
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
  }

  // ---------------------------------- RIGHT

  .admin__title {
    font-size: rem(50);
    line-height: 1;
    font-weight: bold;
  }

  .admin__top__right,
  .admin__user a {
    @include flexbox;
    @include align-items(center);
  }

  .admin__user {
    margin-right: 3rem;
    transition: transform 0.4s ease-in-out;
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      box-shadow: 5px 10px 21px rgba($color: #000000, $alpha: 0.16);
      margin-right: 16px;
      display: none;
    }
    span {
      font-family: var(--font-titre);
      font-size: 1.7rem;
    }
  }

  // ---------------------------------- EO RIGHT

  .footer {
    display: none;
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  .page-administration .admin__title {
    font-size: 3rem;
  }
}

@include breakpoint(1600) {
  .page-administration .admin__title {
    font-size: 2.7rem;
  }
}

@include breakpoint(1440) {
  .page-administration .admin__title {
    font-size: 2.5rem;
  }
  .page-administration .admin__user img {
    width: 60px;
    height: 60px;
  }
}

@include breakpoint(1380) {
  .page-administration .admin__title {
    font-size: 2.2rem;
  }
  .page-administration .admin__user {
    margin-right: 2rem;
  }
  .page-administration .admin__user span {
    font-size: 1.5rem;
  }
}

@include breakpoint(1180) {
  .page-administration .admin__title {
    font-size: 2rem;
  }
  .page-administration .admin__user {
    position: fixed;
    top: 12px;
    z-index: 101;
    left: 50%;
    transform: translateX(-50%);
    min-width: 180px;
    margin-right: 0;
  }
  .page-administration .admin__user span {
    font-size: 1.3rem;
  }
  .page-administration .admin__user.header--hidden {
    transform: translate(-50%, -140px);
  }
}

@include breakpoint(980) {
  .page-administration .admin__title {
    font-size: 1.8rem;
  }
  .page-administration .admin__user img {
    width: 55px;
    height: 55px;
  }

  .page-administration .admin__user span {
    font-size: 1.2rem;
  }
}

@include breakpoint(768) {
  .page-administration .admin__title {
    font-size: 1.7rem;
  }
}

@include breakpoint(550) {
  .page-administration .admin__user img {
    width: 50px;
    height: 50px;
  }
  .page-administration .admin__user span {
    font-size: 1.1rem;
  }
  .page-administration .admin__title {
    font-size: 1.5rem;
  }
}

@include breakpoint(440) {
  .page-administration .admin__user {
    top: 16px;
  }

  .page-administration .admin__user img {
    width: 45px;
    height: 45px;
    margin-right: 7px;
  }

  .admin__top__right .logout-button {
    display: none;
  }
}

@include breakpoint(360) {
  .page-administration .admin__user {
    top: 4px;
    display: block;
    text-align: center;
    max-width: 160px;
    min-width: initial;
  }
  .page-administration .admin__user a {
    display: block;
  }
  .page-administration .admin__user span {
    display: block;
  }
}
