.cards-bijoux-matiere {
  @include flexbox;
  @include align-items(center);
  @include flex-flow(row wrap);
  display: grid;
  grid-template-columns: repeat(3, minmax(230px, 1fr));
  gap: clamp(1rem, 1.5vw, 1.5rem);

  .card {
    position: relative;
    height: 650px;
    border: 1px solid var(--gray-1);
    border-radius: 10px;
    box-shadow: 5px 10px 21px var(--gray-2);

    &::before {
      content: "";
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: rgb(45, 35, 35);
      background: linear-gradient(
        0deg,
        rgba(45, 35, 35, 1) 0%,
        rgba(45, 35, 35, 0) 100%
      );
      opacity: 0.5;
    }

    a {
      position: absolute;
      // z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      // opacity: 0.3;
    }
  }

  .card__bg {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    // display: none;
    // opacity: 0.75;
  }

  .card__title {
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 0;
    font-size: clamp(0.9rem, 2.2vw, 2.2rem);
    text-transform: capitalize;
    margin: 1rem;
    color: var(--white);
    text-shadow: 0 4px 8px #000;
    span {
      display: block;
    }

    .matiere {
      font-weight: bold;
    }
  }

  .card--or .card__bg {
    // background: radial-gradient(
    //     ellipse farthest-corner at right bottom,
    //     #fedb37 0%,
    //     #fdb931 8%,
    //     #9f7928 30%,
    //     #8a6e2f 40%,
    //     transparent 80%
    //   ),
    //   radial-gradient(
    //     ellipse farthest-corner at left top,
    //     #ffffff 0%,
    //     #ffffac 8%,
    //     #d1b464 25%,
    //     #5d4a1f 62.5%,
    //     #5d4a1f 100%
    //   );
  }
}

@include breakpoint(1600) {
  .cards-bijoux-matiere .card {
    height: 500px;
  }
}

@include breakpoint(1440) {
  .cards-bijoux-matiere .card {
    height: 450px;
  }
}

@include breakpoint(980) {
  .cards-bijoux-matiere .card {
    height: 350px;
  }
}

@include breakpoint(780) {
  .cards-bijoux-matiere {
    grid-template-columns: repeat(2, minmax(130px, 1fr));
  }
  .cards-bijoux-matiere .card {
    height: 300px;
  }
  .cards-bijoux-matiere .card__title {
    margin: 0.5rem;
  }
}

@include breakpoint(440) {
  .cards-bijoux-matiere .card {
    height: 250px;
  }
}
