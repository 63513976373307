.hikam__header {
  background-color: var(--gray-6);
  padding: 1rem 2rem;
  margin-bottom: 2rem;

  .order_status_title {
    display: block;
    margin-bottom: 10px;
  }

  .hika__header__title {
    display: block;
    margin-bottom: 10px;
    font-size: rem(25);
    font-weight: bold;
  }

  .hikam_options.large {
    margin: 1rem 0;
    dd {
      margin-bottom: 1rem;
    }
    label {
      margin-left: 0;
    }

    dd.hikamarket_order_invoicenumber,
    dd.hikamarket_order_created,
    dd.hikamarket_order_customer_name,
    dd.hikamarket_order_customer_email {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}

.hika_order_details-address {
  // @include flexbox;
  display: grid;
  grid-template-columns: 400px 1fr;
  @include align-items;

  // --------------------------------------- details prix
  #hikamarket_order_block_details {
    margin-bottom: 2rem;

    .hikam__order__item {
      display: grid;
      @include align-items(center);
      grid-template-columns: 1fr 1fr;
      max-width: 400px;
      padding: 2px 0;
      &:nth-child(odd) dt {
        background-color: var(--gray-6);
      }
      //   background-color: var(--gray-6);
      // padding: 1rem;
      // border-radius: 10px;
      // box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.08);
      dt {
        text-transform: uppercase;
        // color: var(--color-2);
        margin-right: 15px;
        padding-right: 10px;
        font-weight: 500;
        // margin-bottom: 5px;
        text-align: right;
      }
      dd {
        font-size: 1.2rem;
        // margin-left: 5px;
        text-align: right;
        margin-bottom: 0;
        span {
          @include flexbox;
          @include align-items(center);
          gap: 10px;
        }
      }
      label {
        margin: 0;
      }
      .hk-label {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 500;
      }
    }
  }
  // --------------------------------------- eo details prix

  // --------------------------------------- adresses facturation / livraison
  .hikamarket_order_addresses {
    &::before,
    &::after {
      content: none;
    }
  }
  .hkc-md-6 {
    width: auto;
  }

  .hikamarket_order_billing_address,
  .hikamarket_order_shipping_address {
    border: 1px solid var(--gray-4);
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem 2rem;

    .hikabtn {
      background-color: var(--color-2);
      border-color: var(--color-2);
    }
    dt label {
      color: var(--color-1);
    }
    dd {
      margin-bottom: 1rem;
      padding-left: 5px;
      font-size: 0.8rem;
      border-bottom: 1px solid var(--gray-4);
    }
  }

  // --------------------------------------- eo adresses facturation / livraison
}
// --------------------------------------- produits
#hikamarket_order_block_products {
  margin-bottom: 2rem;

  .hikamarket_order_item_name_value {
    div {
      @include flexbox;
      @include align-items(center);
      gap: 15px;
    }
    .product__code {
      display: block;
      font-size: 0.8rem;
      font-weight: 500;
    }
    img {
      border-radius: 7px;
    }
  }
  .hikamarket_order_item_name_title {
    text-align: left;
  }
  .hikamarket_order_item_price_title,
  .hikamarket_order_item_price_value,
  .hikamarket_order_item_quantity_title,
  .hikamarket_order_item_quantity_value {
    text-align: center;
  }
  .hikamarket_order_item_total_price_title,
  .hikamarket_order_item_total_price_value {
    text-align: right;
  }
}
// --------------------------------------- eo produits

// --------------------------------------- historique

#hikamarket_order_history_listing {
  text-align: center;

  td a {
    display: none;
  }
}
// --------------------------------------- eo historique

@include breakpoint(880) {
  h3 {
    text-align: center;
  }

  .hikamarket_order
    .hika_order_details-address
    #hikamarket_order_block_details
    .hikam__order__item {
    max-width: 100%;
  }
  .hikamarket_order .hika_order_details-address {
    grid-template-columns: 1fr;
  }
  .hikamarket_order
    .hika_order_details-address
    .hikamarket_order_billing_address,
  .hikamarket_order
    .hika_order_details-address
    .hikamarket_order_shipping_address {
    margin: 1rem 0;
  }
  .hikamarket_order_addresses {
    display: block;
    text-align: center;
  }
  .hikamarket_order
    #hikamarket_order_block_products
    .hikamarket_order_item_name_value
    div {
    display: block;
    span {
      display: block;
    }
  }
  .hikamarket_order
    #hikamarket_order_block_products
    .hikamarket_order_item_total_price_title,
  .hikamarket_order
    #hikamarket_order_block_products
    .hikamarket_order_item_total_price_value {
    text-align: center;
  }
}
