.espace-client--commandes .vex {
  //   display: none;
}
#hikashop_order_main {
  .header.hikashop_header_title {
    display: none;
  }

  .hikashop_order_right_part {
    margin-bottom: 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
  }
  .hikashop_order_left_part {
    display: none;
  }

  table {
    width: 100%;
  }

  #htmlfieldset_billing,
  #htmlfieldset_shipping {
    border: 1px solid var(--gray-7);
    padding: 0.5rem 2rem 1rem 2rem;
    text-align: center;
    margin-right: 1rem;

    legend {
      margin: 0;
      font-size: 2rem;
      padding: 0 1rem;
    }
  }

  #htmlfieldset_products {
    margin: 2rem 0;
  }

  #htmlfieldset_products {
    legend {
      display: none;
    }
    thead {
      border-bottom: 1px solid var(--gray-7);
      font-size: 0.8rem;
      text-transform: uppercase;
      th {
        padding: 10px 0;
      }
    }
    tbody td {
      padding: 10px;
    }

    .vendor__title {
      path {
        fill: var(--color-1);
      }
    }
  }

  .row0 {
    background-color: var(--opacity-1);
  }

  .hikashop_order_subtotal_title,
  .hikashop_order_subtotal_value {
    border-top: 1px solid var(--gray-2) !important;
  }

  .hikashop_order_tax_title,
  .hikashop_order_tax_value {
    display: none;
  }

  th.hikashop_order_item_total_title,
  .hikashop_order_item_total_title,
  .hikashop_order_item_price_value,
  .hikashop_order_item_quantity_value,
  .hikashop_order_item_total_value,
  .hikashop_order_subtotal_value,
  .hikashop_order_shipping_value,
  .hikashop_order_total_value,
  .hikashop_order_tax_value {
    text-align: right;
    padding: 2px 10px 2px 0 !important;
  }
  .hikashop_order_total_value {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

@include breakpoint(767) {
  #hikashop_order_main {
    table {
      tr,
      td {
        display: block;
        text-align: center;
        padding: 5px;
      }
      .hikashop_order_item_price_value,
      .hikashop_order_item_quantity_value,
      .hikashop_order_item_total_value {
        &::before {
          content: attr(data-title) ":";
          margin-right: 5px;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
  }

  #htmlfieldset_products {
    .row0 {
      background-color: transparent !important;
    }
    thead {
      display: none;
    }
    tbody tr {
      border-bottom: 1px solid var(--gray-6);
    }

    tfoot {
      background-color: var(--gray-6);
      td {
        padding: 0 !important;
      }
    }
    .hikashop_order_subtotal_title,
    .hikashop_order_subtotal_value {
      border: 0 !important;
    }

    label {
      margin: 0;
    }
  }
}
