.home-newsletter {
  position: relative;

  margin-top: var(--section);
  margin-bottom: var(--section);
  text-align: center;
  font-family: var(--font-chalk);
  color: var(--white);

  .acym_module_form {
  }

  .acym_introtext {
    font-size: rem(35);
    margin-bottom: 2rem;

    .icon--check {
      font-size: rem(35);
    }
  }

  .acym_form,
  .acysubbuttons {
    display: inline-block;
    margin: 0 6px;
  }

  // ------------------------------- label

  // .acym__users__creation__fields__title {
  //   text-align: left;
  //   padding-left: 10px;
  //   font-size: rem(25);
  // }

  // ------------------------------- input

  input.acym__user__edit__email {
    background-color: transparent;
    border-color: var(--white);
    color: var(--white);
    width: 400px !important;
    cursor: inherit;
    // font-family: var(--font-chalk);
    font-size: rem(20) !important;

    &::placeholder {
      color: var(--white);
      font-size: rem(28);
      line-height: 1;
      font-family: var(--font-chalk);
    }
  }
  // ------------------------------- message
  .responseContainer {
    background-color: transparent;
    font-size: rem(40);
    svg {
      display: block;
      margin: 0 auto 1rem auto;
      width: 50px;
      height: auto;
    }
  }

  .acym_module_error {
    border: 3px solid var(--error);
    color: var(--error);
    padding: 0 !important;
  }

  .acym_module_success {
    color: var(--white);
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
}

@include breakpoint(980) {
}

@include breakpoint(780) {
  .home-newsletter .acym_introtext {
    ul {
      margin-top: 1rem;
    }
    .icon--check {
      font-size: rem(26);
    }
  }
  .home-newsletter input.acym__user__edit__email {
    width: 350px;
  }
}

@include breakpoint(550) {
  .home-newsletter input.acym__user__edit__email {
    width: 270px;
  }
  .home-newsletter .responseContainer {
    font-size: rem(30);
    svg {
      width: 40px;
    }
  }
}

@include breakpoint(440) {
  .home-newsletter .acym_form,
  .home-newsletter .acysubbuttons {
    display: block;
    margin: 0;
  }

  .acysubbuttons .btn,
  .home-newsletter input.acym__user__edit__email {
    width: 100%;
  }
  .home-newsletter input.acym__user__edit__email {
    font-size: 1rem !important;
  }
}

@include breakpoint(360) {
}
