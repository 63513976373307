/*Simple css to style it like a toggle switch*/
.mode__light-dark {
  //   display: flex;
  //   align-items: center;
  position: absolute;
  top: 15px;
  text-align: center;

  em {
    margin-left: 10px;
    font-size: 1rem;
  }

  .theme-switch {
    display: block;
    height: 44px;
    position: relative;
    width: 90px;
    margin: auto;
  }

  .theme-switch input {
    display: none;
  }

  span {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.45rem;
  }

  #checkbox {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .slider {
    background-color: var(--light);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    border: 2px solid transparent;
  }

  .slider:before {
    content: "";
    left: 4px;
    position: absolute;
    box-shadow: 0 4px 8px var(--gray-2);
    border: 4px solid transparent;
    opacity: 0;
  }

  .slider:before,
  .slider:after {
    height: 34px;
    width: 34px;
    transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
    bottom: 3px;
  }

  .slider:after {
    // content: "\f185";
    @include icon("\f185");
    position: absolute;
    left: 48px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    font-size: 1.5rem;
  }

  .slider {
    border: 2px solid #ffbb52;
  }
  .slider::before {
    background-color: rgba($color: #ffbb52, $alpha: 0.3);
    border: 4px solid #ffbb52;
    opacity: 1;
  }
  .slider::after {
    color: #ffbb52;
  }

  .dark {
    .slider {
      border: 2px solid var(--dark);
    }
    .slider::before {
      background-color: rgba($color: #ffe1e1, $alpha: 0.3);
      border: 4px solid var(--dark);
      transform: translateX(44px);
      opacity: 1;
    }
    .slider::after {
      content: "\f186";
      transform: translateX(-44px);
      color: var(--dark);
    }
  }

  input:checked + .slider {
    // background-color: var(--light);
  }

  input:checked + .slider:before {
    box-shadow: 0 4px 8px var(--gray-2);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
@include breakpoint(1180) {
  .mode__light-dark span {
    display: none;
  }
  .mode__light-dark {
    position: fixed;
    z-index: 10000;
    transition: all 0.2s;
    top: 15px;
    left: 13rem;
    transition: 0.4s ease-in-out;
  }
}
@include breakpoint(550) {
  .mode__light-dark {
    position: fixed;
    z-index: 10000;
    transition: all 0.2s;
    top: 15px;
    left: 13rem;
    transition: 0.4s ease-in-out;
    position: unset;
    z-index: unset;
  }
  .mode__light-dark .theme-switch {
    width: 70px;
    height: 36px;
  }
  .mode__light-dark .slider:before,
  .mode__light-dark .slider:after {
    width: 28px;
    height: 28px;
    bottom: 2px;
  }
  .mode__light-dark .slider:before {
    border-width: 3px;
  }
  .mode__light-dark .slider:after {
    left: 36px;
    font-size: 1.3rem;
  }
  .mode__light-dark input:checked + .slider:after {
    transform: translateX(-33px);
  }
  .mode__light-dark input:checked + .slider:before {
    transform: translateX(30px);
    border-width: 3px;
  }
}
