//   ----------------------------------------------------------- ALL CART
.hikashop_checkout_cart {
  .notifyjs-wrapper {
    // display: none !important;
  }

  .hikashop_checkout_coupon {
    display: none;
  }
}

//   ----------------------------------------------------------- SECONDARY CART

.hikashop_checkout_page_step1 .hika__checkout__block .hk-row-fluid {
  @include align-items(flex-start);
  position: sticky;
  top: 20px;
}

#hikashop_checkout_cart_1_3,
#hikashop_checkout_cart_2_3,
#hikashop_checkout_cart_3_3 {
  // position: sticky;
  // top: 20px;
  position: relative;
  .checkout__cart__header,
  .row0,
  .row1 {
    display: none !important;
  }
}

.checkout__total__wrapper {
  // margin-top: 34px;
  padding: 1.5rem 1rem;
  background-color: var(--light);
  border-radius: 15px;
  border: 1px solid var(--gray-6);

  // border: 6px solid var(--gray-6);

  .checkout__total__title {
    display: block;
    padding-bottom: 10px;
    font-size: rem(20);
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid var(--gray-6);
    margin-bottom: 1rem;
  }

  .checkout__subtotal,
  .checkout__shipping__total .shipping__content,
  .checkout__shipping__tva,
  .checkout__total {
    font-size: rem(18);
    padding: 0.5rem 0;
    @include flexbox;
    @include justify-content(space-between);
    .hikashop_cart_empty_footer {
      display: none;
    }
  }

  .checkout__shipping__total {
    .btn {
      margin-left: 0.5rem;
      // color: var(--white);
      background-color: var(--light);
    }
  }

  .checkout__shipping__tva {
    display: none !important;
  }

  .hikashop_cart_title {
    font-weight: 300;
  }

  .hikashop_cart_total_title {
    font-weight: bold;
  }

  .checkout__total {
    margin-top: 1rem;
    padding-top: 1rem;
    font-size: rem(20);
    font-weight: bold;
    border-top: 1px solid var(--gray-6);
  }
}

//   ----------------------------------------------------------- MAIN CART

#hikashop_checkout_cart_1_0 {
  text-align: left;
  // max-height: 400px;
  // overflow: auto;

  .checkout__total__wrapper {
    display: none;
  }

  .checkout__cart__header {
    text-align: center;
    margin-bottom: 10px;
  }

  .hikashop_cart_title {
    font-weight: 500;
  }

  .item {
    position: relative;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 4px 8px 16px var(--gray-1);
    margin-bottom: 1rem;
    border-radius: 7px;
  }

  .checkout__cart__header,
  .item {
    display: grid;
    grid-template-columns: 1fr 150px 150px;
    gap: 18px;
    padding-right: 40px;
    padding-left: 8px;
  }

  .item__img-title {
    @include flexbox;
    @include align-items(center);
  }

  //   ------------------------------------------ IMAGE

  .hikashop_cart_product_image_title {
    opacity: 0;
    visibility: hidden;
  }

  .hikashop_product_checkout_cart_image {
    display: block;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    border-radius: 7px;
    image-rendering: pixelated;
  }

  //   ------------------------------------------ NOM & PRODUCTEUR
  .hikashop_cart_product_name_value {
    margin-left: 15px;
    .hikashop_cart_product_name {
      font-size: rem(14);
      line-height: 1.5;
      // color: var(--color-1);
      font-weight: 500;
      margin-bottom: 8px;
    }

    .cart__producteur {
      font-family: var(--font-titre);
      // color: var(--color-2);
    }
    .hikashop_cart_product_name,
    .cart__producteur {
      &:hover {
        // text-decoration: underline;
      }
    }
    #edit_cart_product {
      display: none;
    }
    .hikashop_cart_item_product_taille_bracelet {
      font-weight: 600;
      font-size: 0.8rem;
      label {
        display: none;
      }
      &::before {
        content: "Taille du bracelet (en cm)";
        margin-right: 0.2rem;
        font-weight: normal;
      }
    }
  }

  //   ------------------------------------------ QTY

  .hikashop_product_quantity_div {
    position: relative;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    height: 50px;
  }

  .hikashop_product_quantity_field_change {
    display: block;
    font-size: rem(20);
    color: var(--gray-8);
    width: 35px;
    line-height: 35px;
    height: 35px;
    text-align: center;
    border: 1px solid var(--gray-7);
    border-radius: 7px;
  }

  .hikashop_product_quantity_field {
    padding: 0;
    margin: 0;
    border: 0;
    width: 45px !important;
    height: 30px !important;
    text-align: center;
    font-size: rem(20) !important;
    font-weight: bold;
  }

  //   ------------------------------------------ PRIX
  .hikashop_cart_product_total_value {
    text-align: center;
    font-size: rem(20);
    font-weight: 500;
  }

  //   ------------------------------------------ DELETE
  .hikashop_cart_product_quantity_delete {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-7);
    font-size: 1.3rem;
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
  .checkout__total__wrapper .checkout__total__title {
    font-size: 1.3rem;
  }
  .checkout__total__wrapper .checkout__subtotal,
  .checkout__total__wrapper .checkout__shipping__total,
  .checkout__total__wrapper .checkout__total {
    font-size: 1.1rem;
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  #hikashop_checkout_cart_1_0 .checkout__cart__header,
  #hikashop_checkout_cart_1_0 .item {
    grid-template-columns: 1fr 130px 130px;
    gap: 15px;
  }
  #hikashop_checkout_cart_1_0 .hikashop_product_checkout_cart_image {
    // max-width: 100px;
    // max-height: 70px;
  }
  #hikashop_checkout_cart_1_0 .hikashop_product_quantity_field_change {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  #hikashop_checkout_cart_1_0 .hikashop_product_quantity_field {
    font-size: 1.15rem !important;
  }
  #hikashop_checkout_cart_1_4,
  #hikashop_checkout_cart_2_3 {
    position: relative;
    top: 0;
  }
}

@include breakpoint(1040) {
  #hikashop_checkout_cart_1_0 .items,
  #hikashop_checkout_cart_1_0 .checkout__cart__header {
    margin: 0;
  }
  #hikashop_checkout_cart_1_3 {
    position: relative;
    top: 0;
  }
  .checkout__total__wrapper .checkout__total__title {
    text-align: left;
  }
  .checkout__total__wrapper .checkout__subtotal,
  .checkout__total__wrapper .checkout__shipping__total,
  .checkout__total__wrapper .checkout__total {
    padding: 0.5rem 0;
  }
}

@include breakpoint(768) {
  #hikashop_checkout_cart_1_0 .checkout__cart__header,
  #hikashop_checkout_cart_1_0 .item {
    grid-template-columns: 1fr 100px 100px;
    gap: 15px;
  }
  #hikashop_checkout_cart_1_0 .hikashop_product_checkout_cart_image {
    max-width: 70px;
    min-width: 70px;
    max-height: 70px;
  }
  #hikashop_checkout_cart_1_0
    .hikashop_cart_product_name_value
    .hikashop_cart_product_name {
    font-size: 0.9rem;
  }
  #hikashop_checkout_cart_1_0 .hikashop_product_quantity_field {
    width: 40px !important;
  }
}

@include breakpoint(650) {
  #hikashop_checkout_cart_1_0 .item {
    grid-template-columns: 1fr;
    display: block;
    padding: 1rem;
  }
  .checkout__cart__header {
    display: none !important;
  }
  #hikashop_checkout_cart_1_0 .hikashop_product_checkout_cart_image {
    // max-width: 90px;
    // max-height: 90px;
    // margin: auto;
  }
  .hikashop_cart_product_name_value {
    margin: 1rem 0 0.5rem 0;
    text-align: left;
  }
  #hikashop_checkout_cart_1_0 .hikashop_cart_product_quantity_delete {
    transform: translateY(0);
    top: 5px;
    right: 5px;
  }
  #hikashop_checkout_cart_1_0 .item {
    text-align: right;
    padding: 1rem 1rem 0 1rem;
  }
  .hikashop_cart_product_quantity_value,
  .hikashop_cart_product_total_value {
    @include inline-flex;
    margin: 5px 10px;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
