.notifyjs-wrapper {
  //   position: relative !important;
  .notifyjs-container {
    // position: relative !important;
    // z-index: 1000;
    // left: calc(50% - (var(--header-width) / 2)) !important;
    // pointer-events: none;
    // top: 0 !important;
    // transform: translateX(-50%);
    // top: 50% !important;
    // left: 0 !important;
  }
  .notifyjs-metro-info {
    display: none;
  }
  .notifyjs-metro-base {
    background-color: var(--color-1);
    color: var(--white) !important;
    min-height: auto;
    box-shadow: 5px 10px 21px var(--gray-2);
    border-radius: 8px;
    border: 0 !important;
  }
  .text-wrapper {
    margin: 0 !important;
  }
  .image,
  .title {
    display: none !important;
  }
  .text {
    position: relative;
    font-size: 1.2rem !important;
    font-size: clamp(1rem, 1.1vw, 1.1rem) !important;
    padding: 0.5rem 1.2rem;
    min-width: 360px;
    margin-right: 0.5rem;
    &::before {
      position: absolute;
      top: 0.1rem;
      right: -0.2rem;
      @include icon("\f057");
    }
  }
}

@include breakpoint(650) {
  .notifyjs-wrapper .text {
    min-width: unset;
  }
}
