.home-content {
  .content__intro {
    p {
      font-family: var(--font-titre);
      font-size: clamp(1.3rem, 1.9vw, 1.9rem);
      max-width: 750px;
      line-height: 1.3;
      text-align: center;
      margin: auto;
    }
  }

  .items {
    margin: 5rem 0;
    @include flexbox;
    @include align-items(stretch);
    @include flex-flow(row wrap);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .item {
    position: relative;
    box-shadow: var(--shadow-2);
    border: 1px solid var(--bg-deco);
    border-radius: 15px;
    padding: 2rem;
    overflow: hidden;

    h2 {
      padding-right: 5rem;
      min-height: 70px;
    }

    p {
      font-size: clamp(0.8rem, 1vw, 0.9rem);
      line-height: 1.5;
      padding-bottom: 0.5rem;
    }
  }
  .item__icon {
    position: absolute;
    top: 1rem;
    right: 1rem;

    svg {
      width: clamp(3rem, 5vw, 5rem);
      height: auto;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 200%;
      height: 185%;
      top: -60%;
      left: -50%;
      background-color: var(--opacity-1);
      border-radius: 50%;
    }
  }
}

@include breakpoint(780) {
  .home-content .items {
    grid-template-columns: 1fr;
  }
  .home-content .item {
    padding: 1rem;
  }
  .home-content .item h2 {
    padding-right: 3rem;
  }
}

// .home-content {
//   .items {
//     margin-top: 4rem;
//     display: grid;
//     grid-template-columns: minmax(650px, 1fr) 1fr;
//     grid-gap: 50px;
//     // align-items: center;
//   }
//   .items__left {
//     grid-template-columns: minmax(650px, 1fr) 1fr;
//   }
//   .items__right {
//     grid-template-columns: 1fr minmax(650px, 1fr);
//   }
//   .item__intro {
//     font-weight: 600;
//   }
//   p {
//     margin-bottom: calc(var(--gap) * 2);
//   }
//   strong {
//     font-weight: normal;
//   }
//   img {
//     box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.26);
//     border-radius: var(--radius);
//   }
// }

// @include breakpoint(1440) {
//   .home-content .items__left {
//     grid-template-columns: minmax(550px, 1fr) 1fr;
//   }
//   .home-content .items__right {
//     grid-template-columns: 1fr minmax(550px, 1fr);
//   }
// }

// @include breakpoint(980) {
//   .home-content .items {
//     grid-template-columns: 1fr;

//     img {
//       display: block;
//       margin: auto;
//     }
//     figcaption {
//       text-align: center;
//     }
//   }
// }

// @include breakpoint(550) {
//   .home-content .items {
//     margin-top: 2rem;
//   }
// }
