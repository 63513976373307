.page-social-media {
  .social-media__wrapper {
    margin-top: 2rem;
  }

  h1 {
    text-align: center;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 225px));
    gap: 2rem;
    @include justify-content(center);
    text-align: center;
    margin: 3rem auto 4rem auto;
  }

  .card {
    border: 1px solid var(--gray-2);
    box-shadow: 5px 10px 21px var(--gray-3);
    padding: 1rem;
    border-radius: 10px;
  }

  .card__title {
    display: block;
    padding: 1.5rem 0 2rem 0;
  }

  .btn {
    background-color: var(--black);
    border: 0;
    &:hover {
      color: var(--white);
    }
  }

  .social-media__content {
    max-width: 780px;
    margin: auto;
    text-align: center;
    p {
      padding: 0.4rem 0;
    }
  }
}
