// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  .hikashop_product_page {
    --details-width: 630px;
  }
  .hikashop_product_page .hikashop_product_name_main {
    font-size: 1.5rem;
  }
  .hikashop_product_page .hikamarket_vendor a {
    font-size: 1.5rem;
  }
  .hikashop_product_page .hikashop_product_weight_main {
    font-size: 1.2rem;
  }
  .hikashop_product_page .hikashop_product_price {
    font-size: 2.5rem;
  }
  .hikashop_product_page .hikashop_product_price_per_weight_unit {
    font-size: 0.9rem;
  }
}

@include breakpoint(1600) {
  .hikashop_product_page {
    --details-width: 600px;
  }
}

@include breakpoint(1440) {
  .hikashop_product_page .hikashop_product_name_main {
    font-size: 1.4rem;
  }
  .hikashop_product_page .hikamarket_vendor a {
    font-size: 1.4rem;
  }
  .hikashop_product_page .hikashop_product_weight_main {
    font-size: 1.1rem;
  }
  .hikashop_product_page .hikashop_product_price {
    font-size: 2.3rem;
  }
  .hikashop_product_page .hikashop_product_stock_count svg,
  .hikashop_product_page .hika__delivery svg {
    height: 30px;
  }
}

@include breakpoint(1380) {
  .hikashop_product_page {
    --details-width: 500px;
  }

  .hikashop_product_page .hika__quantity__price {
    @include flex-direction(column);
    gap: 10px;
  }
  .hikashop_product_page .hikashop_product_quantity_div {
    margin-right: 0;
  }
  .hikashop_product_page .hikashop_product_quantity_div .hika__qty__title {
    text-align: left;
  }
  .hikashop_product_page .btn--add-cart {
    margin-left: 0;
  }
  .hikashop_product_page .hika__stock {
    font-size: 0.9rem;
  }
  .hikashop_product_page .hikashop_product_stock_count svg,
  .hikashop_product_page .hika__delivery svg {
    height: 26px;
  }

  .hikashop_product_page .caracteristiques__wrapper {
    padding: 1rem;
  }
}

@include breakpoint(1180) {
  .hikashop_product_page .item__title {
    font-size: 2.1rem;
  }
  .hikashop_product_page {
    --details-width: 580px;
  }
  .hikashop_product_page .hika__product__right--fixed .hika__product__infos {
    transform: translateY(0);
    // top: 100px;
    // max-height: calc(90vh - 100px);
    transform: unset;
    position: relative;
    box-shadow: none;
    max-height: unset;
  }
}

@include breakpoint(980) {
  .hikashop_product_page .caracteristiques__wrapper li {
    display: block;
    span {
      display: block;
      padding: 0 0.5rem;
    }
  }
}

@include breakpoint(850) {
  // hika_details.js
  // if window width <= 780
  .notifyjs-wrapper .notifyjs-metro-base {
    margin-bottom: 1.5rem;
  }
  .left__tablet__scroll-help {
    @include flexbox;
    @include align-items(center);
  }
  .hikashop_product_page {
    --details-width: 100%;
  }
  .hikashop_product_page .hk-row-fluid {
    grid-template-columns: 1fr;
  }
  .hikashop_product_right_part--mobile {
    display: block !important;
  }

  .hikashop_product_right_part--big {
    display: none !important;
  }

  .hikashop_product_custom_item_info {
    table,
    tbody {
      display: block;
    }
  }

  .hika__product__left__tablet {
    display: grid;
    grid-template-columns: minmax(350px, 1fr) 1fr;
    @include align-items(flex-start);
    gap: 15px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray-6);
  }
  .hikashop_product_page .hikashop_product_left_part,
  .hikashop_product_page #hikashop_comment_form {
    padding: 0;
    border: 0;
  }
  .hikashop_product_page .hikashop_product_name_main {
    font-size: 1.2rem;
  }
  .hikashop_product_page .hikamarket_vendor a {
    font-size: 1.2rem;
  }
  .hikashop_product_page .hikashop_product_weight_main {
    font-size: 1rem;
  }
  .hikashop_product_page .hikashop_product_price {
    font-size: 1.8rem;
  }

  // ================== change
  .hikashop_product_page
    .hikashop_product_left_part
    .hikashop_product_description_main
    .item__description {
    display: block;
    text-align: center;
  }
  .hikashop_product_page .item__title {
    font-size: 2rem;
  }
  .hikashop_product_page .hika__price__infos {
    text-align: center;
    display: block;
    margin-top: 1rem;
    svg {
      display: inline-block;
      vertical-align: sub;
    }
  }
  .hika__product__left__tablet {
    display: block;
    grid-template-columns: 1fr;
    margin: 0.5rem 0 1rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray-6);
    text-align: center;
  }
  .hikashop_product_page .hikashop_product_right_part {
    margin: 1rem 0 0 0;
  }
  // ---------------------------------------------------------------- qty display none
  .hika__quantity__price .hikashop_product_quantity_div {
    display: none !important;
  }
  .hikashop_product_page .hika__quantity__price,
  .hikashop_product_page .hikashop_product_quantity_div .hika__qty__wrapper {
    @include justify-content(center);
    margin: 0;
  }
  .hikashop_product_page .hikashop_product_quantity_div .hika__qty__title {
    text-align: center;
  }
  .hikashop_product_quantity_main .btn {
    position: fixed;
    box-shadow: 5px 10px 21px var(--gray-1);
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    bottom: 1rem;
    width: 90%;
    margin-left: 0;
  }
  .notifyjs-wrapper .notifyjs-container {
    // left: 50% !important;
  }
}

@include breakpoint(550) {
  .left__tablet__scroll-help a {
    padding: 0.5rem;
  }

  .hikashop_product_page .hika__stock {
    font-size: 1rem;
  }
  .hikashop_product_page .hikashop_product_stock_count,
  .hikashop_product_page .hika__delivery {
    padding: 8px 10px;
    // height: 45px;
  }

  .hikashop_product_page .hikashop_product_stock_count svg,
  .hikashop_product_page .hika__delivery svg {
    height: 20px;
    width: auto;
  }
  .hikashop_product_page
    .hikashop_product_left_part
    #hikashop_small_image_div
    img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 3px;
  }
}

@include breakpoint(440) {
  .hikashop_product_page .hikashop_vote_form input,
  .hikashop_product_page .hikashop_vote_form textarea {
    width: 100%;
    min-width: auto;
  }

  .hikashop_product_page .hikashop_product_stock_count,
  .hikashop_product_page .hika__delivery {
    // @include flex-flow(row wrap);
    // @include justify-content(center);
    font-size: 0.8rem;
    padding: 8px 5px;
  }
}

@include breakpoint(360) {
}
