/*
************* COLORS
* ==================
*/
:root {
  --color-1: #dd7474;
  --color-2: #dd7474;
  --color-3: #af9175;
  --color-4: #dcd0c4;
  --white: #ffffff;
  --black: #393e3d;
  --light: #ffffff;
  --dark: #393e3d;
  --bg-deco: #fcf1f1;

  // --color-light: #ffffff;
  // --color-dark: #393e3d;
  --gray-1: rgba(221, 116, 116, 0.1);
  --gray-2: rgba(221, 116, 116, 0.2);
  --gray-3: rgba(221, 116, 116, 0.3);
  --gray-4: rgba(221, 116, 116, 0.4);
  --gray-5: rgba(221, 116, 116, 0.5);
  --gray-6: rgba(221, 116, 116, 0.6);
  --gray-7: rgba(221, 116, 116, 0.7);
  --gray-8: rgba(221, 116, 116, 0.8);
  --error: var(--color-1);
  --warning: var(--color-1);
  --success: var(--color-1);
  --star: #ffbb00;
  // --background-light: #ffffff;
  --background-dark: #2e2121;
  --opacity-1: rgba(221, 116, 116, 0.16);
  --opacity-2: rgba(221, 116, 116, 0.08);
  --opacity-3: rgba(221, 116, 116, 0.04);
  --opacity-deco: 0.1;

  --gradient-1: linear-gradient(90deg, var(--color-2), var(--color-1));
  --gradient-2: linear-gradient(90deg, var(--color-1), var(--color-2));
  --gradient-gold: linear-gradient(
    90deg,
    rgba(180, 143, 96, 1) 0%,
    rgba(143, 106, 67, 1) 21%,
    rgba(254, 197, 115, 1) 55%,
    rgba(143, 106, 67, 1) 100%
  );
  --shadow-1: 20px 40px 80px rgba(46, 33, 33, 0.25);
  --shadow-2: 0px 20px 40px rgba(129, 60, 61, 0.13);
  --full-width: 60px;
  --container: 1180px;
  --gap: 8px;
  --section: 8rem;
  --font-normal: "Montserrat", sans-serif;
  --font-titre: "Playball", cursive;
  --font-chalk: "whatever it takes", Arial, sans-serif;
  --font-awe: "Font Awesome 5 Free";
  --header-width: 330px;
  --header-height: 0px;
  --h1: clamp(1.8rem, 2.7vw, 2.7rem);
  --h2: clamp(1.7rem, 2.3vw, 2.3rem);
  --h3: clamp(1.5rem, 2.1vw, 2.1rem);
  --h4: 18px;
  --h5: 18px;
  --h6: 18px;
  --text: 17px;
  --radius: 8px;

  // --card-bg: rgba(255, 255, 255, 0.7);
  // --bluegray: #b2bfcd;
  // --lightbluegray: #f6f9fc;
  // --toolbar-bg: white;
  // --success-border: var(--success);
  // --info-border: var(--info);
  // --warning-border: var(--warning);
  // --danger-border: var(--error);
  // --border: #ced4da;
  // --white: white;
  // --white-offset: #fefefe;
  // --focus: #39f;
  // --focus-shadow: 0 0 0 0.2rem #eaeaea;
  // --toggle-color: var(--white);
  // --template-sidebar-bg: var(--template-bg-dark-80);
  // --template-sidebar-font-color: var(--white);
  // --template-sidebar-link-color: var(--white);
  // --template-bg-light: #f0f4fb;
  // --template-text-light: white;
  // --template-special-color: #132f53;
  // --template-link-color: #2a69b8;
  // --template-link-hover-color: #173a65;
  // --template-contrast: #2a69b8;
  // --template-bg-dark: hsl(var(--hue), 40%, 20%);
  // --template-bg-dark-3: hsl(var(--hue), 40%, 97%);
  // --template-bg-dark-5: hsl(var(--hue), 40%, 95%);
  // --template-bg-dark-7: hsl(var(--hue), 40%, 93%);
  // --template-bg-dark-10: hsl(var(--hue), 40%, 90%);
  // --template-bg-dark-15: hsl(var(--hue), 40%, 85%);
  // --template-bg-dark-20: hsl(var(--hue), 40%, 80%);
  // --template-bg-dark-30: hsl(var(--hue), 40%, 70%);
  // --template-bg-dark-40: hsl(var(--hue), 40%, 60%);
  // --template-bg-dark-50: hsl(var(--hue), 40%, 50%);
  // --template-bg-dark-60: hsl(var(--hue), 40%, 40%);
  // --template-bg-dark-65: hsl(var(--hue), 40%, 35%);
  // --template-bg-dark-70: hsl(var(--hue), 40%, 30%);
  // --template-bg-dark-75: hsl(var(--hue), 40%, 25%);
  // --template-bg-dark-80: hsl(var(--hue), 40%, 20%);
  // --template-bg-dark-90: hsl(var(--hue), 40%, 10%);
  // --gray-100: #f9fafb;
  // --gray-200: #eaedf0;
  // --gray-300: #dfe3e7;
  // --gray-400: #ced4da;
  // --gray-500: #adb5bd;
  // --gray-600: #6d757e;
  // --gray-700: #484f56;
  // --gray-800: #353b41;
  // --gray-900: #22262a;
  // --primary: #010156;
  // --secondary: #6d757e;
  // --success: #448344;
  // --info: #30638d;
  // --warning: #ad6200;
  // --danger: #a51f18;
  // --light: #f9fafb;
  // --dark: #353b41;
  // --primary-rgb: 1, 1, 86;
  // --secondary-rgb: 109, 117, 126;
  // --success-rgb: 68, 131, 68;
  // --info-rgb: 48, 99, 141;
  // --warning-rgb: 173, 98, 0;
  // --danger-rgb: 165, 31, 24;
  // --light-rgb: 249, 250, 251;
  // --dark-rgb: 53, 59, 65;
  // --white-rgb: 255, 255, 255;
  // --black-rgb: 0, 0, 0;
  // --body-color-rgb: 34, 38, 42;
  // --body-bg-rgb: 255, 255, 255;
  // --font-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  // --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  // --body-font-family: var(--cassiopeia-font-family-body, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  // --body-font-size: 1rem;
  // --body-font-weight: 400;
  // --body-line-height: 1.5;
  // --body-color: #22262a;
  // --body-bg: white;
}

@media (prefers-color-scheme: dark) {
  :root {
    --light: #161414;
    --dark: #fff1f1;
    --bg-deco: #2b1d1d;
  }
}

[data-theme="light"] {
  --light: #ffffff;
  --dark: #393e3d;
  --bg-deco: #fcf1f1;
  input {
    color-scheme: light;
  }
}

[data-theme="dark"] {
  --light: #161414;
  --dark: #fff1f1;
  --bg-deco: #2b1d1d;
  input {
    color-scheme: dark;
  }
  body {
    background-color: var(--light);
  }
}

.bg--chalk {
  // background-image: url("/images/bg-chalk_cd4e6d_c_scale,w_1580.jpg");
  // background-size: cover;
  // background-position: center;
  // padding: 6rem 1rem 10rem 1rem;
}

.icon--check::before {
  content: url("/images/icons/icon-check.svg");
  display: inline-flex;
  transform: scale(0.75);
  margin-right: 0.875rem;
}

.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-2);
}

/*
************* FONT-SIZE
* ===================================
*/

$font-size-base: 16px;

/*
************* TIPS
* ===================================
*/

.link {
  color: var(--color-1);
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

img {
  // image-rendering: -webkit-optimize-contrast;
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  :root {
    --header-width: 275px;
  }
}

@include breakpoint(1600) {
  :root {
    --section: 5rem;

    --h4: 16px;
    --text: 15px;
  }
}

@include breakpoint(1440) {
  :root {
    --header-width: 250px;
    --full-width: 28px;
  }
}

@include breakpoint(1380) {
  .bg--chalk {
    background-image: url("/images/bg-chalk_cd4e6d_c_scale,w_1314.jpg");
  }
}

@include breakpoint(1180) {
  :root {
    --header-width: 0px;
    --header-height: 80px;
    --full-width: 26px;
  }
}

@include breakpoint(980) {
  :root {
    --h3: 30px;
  }
}

@include breakpoint(780) {
  :root {
    --section: 4rem;

    --h4: 15px;
    --text: 15px;
  }
  .bg--chalk {
    padding: 4rem 1rem 6rem 1rem;
  }
}

@include breakpoint(550) {
  :root {
    --full-width: 16px;
    --section: 3rem;
    // --h2: 30px;
    --h3: 26px;
    --h4: 13px;
    --text: 14px;
  }
  .bg--chalk {
    background-image: url("/images/bg-footer--mobile.jpg");
    padding: 3rem 1rem 5rem 1rem;
  }
}

@include breakpoint(440) {
  :root {
    --section: 2rem;
    --header-height: 70px;
  }
}

@include breakpoint(360) {
}
