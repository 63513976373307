/*
************* RESPONSIVE
* ===================================
*/

@mixin breakpoint($media) {
  @media screen and (max-width: #{$media}px) {
    @content;
  }
}

// $breakpoints: (
//   "xxs": 550px,
//   "xs": 768px,
//   "s": 980px,
//   "m": 1200px,
//   "l": 1440px,
//   "xl": 1600px,
//   "xxl": 1800px,
// );
// @mixin breakpoint($_key) {
//   @media screen and (max-width: map-get($breakpoints, $_key)) {
//     @content;
//   }
// }

%bg-home {
  position: relative;
  margin-top: clamp(8rem, 15vw, 15rem);
  margin-bottom: clamp(8rem, 15vw, 15rem);
  //   padding: 2rem 0;
  background-color: var(--bg-deco);
  padding-top: clamp(1rem, 3vw, 3rem);
  padding-bottom: clamp(1rem, 3vw, 3rem);
}

// ----------------------------------- decoration section

@mixin deco-before($height: 138px) {
  &::before {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -1;
    left: 0;
    width: 100%;
    height: $height;
    background-image: url("../images/deco-before.svg");
    background-size: cover;
    background-position: center;
    top: calc((#{$height} * -1) + 2px);
    opacity: var(--opacity-deco);
    // transform: rotate(180deg);
  }
}

@mixin deco-after($height: 86px) {
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -1;
    left: 0;
    width: 100%;
    height: $height;
    background-image: url("../images/deco-after.svg");
    background-size: cover;
    background-position: center;
    bottom: calc((#{$height} * -1) + 2px);
    opacity: var(--opacity-deco);
  }
}

.section__deco--before {
  @include deco-before;
}
.section__deco--after {
  @include deco-after;
}
.section__deco--all {
  @include deco-before;
  @include deco-after;
}

// ================================================================= RESPONSIVE

@include breakpoint(1380) {
  .section__deco--before,
  .section__deco--after,
  .section__deco--all {
    --deco-height: 138px;
  }
  .section__deco--all {
    @include deco-before(var(--deco-height));
    @include deco-after(var(--deco-height));
  }
  .section__deco--before {
    @include deco-before(var(--deco-height));
  }
  .section__deco--after {
    @include deco-after(var(--deco-height));
  }
}

@include breakpoint(1180) {
}

@include breakpoint(980) {
}

@include breakpoint(780) {
  .section__deco--before,
  .section__deco--after,
  .section__deco--all {
    --deco-height: 70px;
  }
  .section__deco--all {
    @include deco-before(var(--deco-height));
    @include deco-after(var(--deco-height));
  }
  .section__deco--before {
    @include deco-before(var(--deco-height));
  }
  .section__deco--after {
    @include deco-after(var(--deco-height));
  }
}

@include breakpoint(550) {
  .section__deco--before,
  .section__deco--after,
  .section__deco--all {
    --deco-height: 40px;
  }
  .section__deco--all {
    @include deco-before(var(--deco-height));
    @include deco-after(var(--deco-height));
  }
  .section__deco--before {
    @include deco-before(var(--deco-height));
  }
  .section__deco--after {
    --deco-height: 45px;
    @include deco-after(var(--deco-height));
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}

// ----------------------------------- fontawesome
@mixin icon($name: "") {
  content: $name;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

@mixin arrow($size, $color, $color-hover: blue) {
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: $size;
    height: $size;
    background-color: $color;
    left: calc(-#{$size} / 2);
    top: calc(50% - (#{$size} / 2));
    transform: rotate(45deg);
    border-radius: 2px;
  }
  &:hover::after {
    background-color: $color-hover;
  }
}

// ---------- Gradient

@mixin gradient-1 {
  background: rgb(191, 33, 24);
  background: linear-gradient(
    90deg,
    rgba(191, 33, 24, 1) 0%,
    rgba(191, 24, 24, 1) 25%,
    rgba(196, 62, 37, 1) 60%,
    rgba(227, 76, 45, 1) 85%,
    rgba(227, 49, 45, 1) 100%
  );
}

@mixin gradient-2 {
  background: rgb(9, 23, 25);
  background: linear-gradient(
    90deg,
    rgba(9, 23, 25, 1) 0%,
    rgba(29, 54, 58, 1) 100%
  );
}

// ---------- Shadow

@mixin shadow-1 {
  box-shadow: 0 16px 32px var(--gray-2);
}

@mixin shadow-2 {
  box-shadow: 0 26px 52px var(--gray-2);
}

/*
************* VISIBILITY
* ===================================
*/

@mixin hidden {
  display: block !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

@mixin visible {
  border: 0 !important;
  height: auto !important;
  width: auto !important;
  padding: 0 !important;
  overflow: visible !important;
  clip: auto !important;
}
