.mod__login {
  text-align: left;
  margin: 2rem auto 0 auto;
  max-width: 380px;

  .btn {
    width: 100%;
  }
}

.wishlist__login {
  max-width: 500px;
  margin: auto;

  .mod__login {
    width: 100%;
    max-width: 100%;
  }
}
