@charset 'utf-8';

@import "utils/functions";
@import "utils/mixins";
@import "utils/flexbox";
@import "utils/variables";
@import "utils/scrollbar";

@import "base/reset";
@import "base/responsive";
@import "base/typography";

@import "administration/variables";
@import "administration/layout";
@import "administration/header";
@import "administration/navigation";
@import "administration/navigation_top";
@import "administration/dashboard";
@import "administration/order";
@import "administration/order_details";
@import "administration/product_edit";
@import "administration/product_list";
@import "administration/customers";
@import "administration/hika_table";
@import "administration/hika_vendor_page";
@import "administration/livraisons_list";
@import "administration/livraisons_details";
@import "administration/guides";

@import "components/breadcrumb";
@import "components/buttons";
@import "components/form";
@import "components/pagination";
@import "components/modal";
@import "components/message";
@import "components/social_share";
@import "components/login";
@import "components/loading";
@import "components/reviews";
@import "components/mode_light_dark";
@import "components/search";

// ------------------------------------ Hikashop

@import "hikashop/template";

// @import "components/hika_filter";
// @import "components/hika_vote";
// @import "components/hika_loading";
@import "components/hika_toolbar";
// @import "components/hika_pagination";
// @import "components/hika_waitlist";
// @import "components/hika_popup_vex";

// @import "layout/hika_list";
// @import "layout/hika_details";
// @import "layout/hika_details_responsive";
@import "layout/hika_card_order_product";
// @import "layout/hika_vendor_list";
// @import "layout/hika_vendor_details";

@import "checkout/hika_checkout";
@import "checkout/hika_step";
@import "checkout/hika_cart";
@import "checkout/hika_shipping";
@import "checkout/hika_paiement";
@import "checkout/hika_paiement_confirm";
@import "checkout/hika_paiement_wait";
@import "checkout/end_thank_you";
@import "checkout/end_cancel";

// ------------------------------------ eo Hikashop

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/header-top";
@import "layout/header_configurator";

@import "pages/404";
@import "pages/sitemap";
@import "pages/rgpd";
@import "pages/home-1-hero";
@import "pages/home-2-categories";
@import "pages/home-3-carte-cadeau";
@import "pages/home-4-moment";
@import "pages/home-5-how";
@import "pages/home-6-8-content";
@import "pages/home-7-newsletter";
@import "pages/home";
@import "pages/blog";
@import "pages/page3";
@import "pages/page4";
@import "pages/page5";
@import "pages/page6";
@import "pages/page7";
@import "pages/page8";
@import "pages/contact";
@import "pages/nous-rejoindre";
@import "pages/bijoux_matiere";
@import "pages/les_bijoux";
@import "pages/social_media";
@import "pages/plan_de_site";

@import "espace-client/login";
@import "espace-client/login_jfb";
@import "espace-client/layout";
@import "espace-client/profil";
@import "espace-client/navigation";
@import "espace-client/hika_order";
@import "espace-client/hika_order_details";
@import "espace-client/hika_order_contact";
@import "espace-client/address";

@import "vendors/swiper-bundle.scss";
@import "vendors/nice-select.scss";

// ---------------------------------------------- FONTAWESOME
// @import "vendors/fontawesome/fontawesome";
// @import "vendors/fontawesome/solid";
// @import "vendors/fontawesome/regular";
@import "vendors/nouislider";

// @import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playball&display=swap");

body {
  //   background-color: yellow;
}
