.page-nous-rejoindre {
  .join__content {
    @include flexbox;
    @include align-items(flex-start);
    display: grid;
    grid-template-columns: minmax(430px, 1fr) minmax(400px, 1fr);
    gap: 50px;
  }

  .join__title {
    display: block;
    font-family: var(--font-titre);
    font-size: rem(35);
    margin-bottom: 1.5rem;
  }

  .join__media {
    text-align: center;
    margin: 2rem 0;
  }

  .join__avantages {
    margin-bottom: 2rem;
  }

  .join__infos {
    background-color: var(--gray-6);
    padding: 2rem;
    border-radius: 25px;
    li {
      @include flexbox;
      @include align-items(center);
      font-size: rem(18);
      padding-bottom: 1rem;
      &::before {
        @include icon("\f00c");
        font-size: 1.3rem;
        color: var(--color-2);
        margin-right: 7px;
      }
    }
  }

  .join__contact {
    #fox-container-m189 {
      margin: auto;
      padding: 2rem;
      border-radius: 15px;
      max-width: 500px;
      box-shadow: 10px 20px 41px var(--gray-1);
    }

    .join__title {
      text-align: center;
    }
  }
}

@include breakpoint(1600) {
  .page-nous-rejoindre .join__media img {
    max-width: 150px;
  }
}

@include breakpoint(1440) {
  .page-nous-rejoindre .join__title {
    font-size: 1.8rem;
  }
  .page-nous-rejoindre .join__infos li {
    font-size: 1rem;
  }
}

@include breakpoint(980) {
  .page-nous-rejoindre .join__content {
    display: block;
  }
  .page-nous-rejoindre .join__infos {
    margin-bottom: 3rem;
  }
  .page-nous-rejoindre .join__title {
    margin-bottom: 1rem;
  }
}

@include breakpoint(550) {
  .page-nous-rejoindre .join__contact #fox-container-m189 {
    padding: 1rem 0.5rem;
  }
}
