.jmapcolumn {
  margin-top: 2rem;
}

#jmap_sitemap div.jmapcolumn > ul > li > span.folder {
  background-color: var(--color-1) !important;
  border: none !important;
  &:hover {
    color: white !important;
  }
}
