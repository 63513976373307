.all__hero {
  padding: 0.5rem 1rem;
  background-color: var(--color-1);
  // background-color: transparent;
  text-align: center;
  border-radius: 8px;
  color: var(--white);
  // color: var(--color-1);
  font-size: 1rem;
  font-size: clamp(0.9rem, 1.4vw, 1.4rem);
  border-bottom: 1px solid var(--gray-2);
  // border: 1px solid var(--color-1);
  margin-bottom: 0.5rem;

  .hero__title {
    // display: block;
  }
  .hero__search {
    // font-family: var(--font-titre);
    font-weight: 600;
    text-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.26);
  }

  .hero__modify {
    background-color: var(--white);
    color: var(--color-1);
  }
}

.listing__certificat {
  // text-align: center;
  margin-bottom: 2rem;
  color: var(--color-1);
  font-weight: 500;
  text-align: center;
  font-size: 1rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  i {
    margin-right: 0.3rem;
  }
}

.page-produits {
  .listing__certificat {
    text-align: left;
    margin-bottom: 1rem;
    color: var(--color-1);
    font-weight: 500;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 60px;
    margin-right: 300px;
  }
}

.page-produits {
  .hikashop_products_listing .hk-row-fluid {
    // margin-top: 80px;
  }
}

.hikashop_products_listing {
  margin-bottom: var(--section);

  .hikashop_products {
    overflow: hidden;
    position: relative;
    // z-index: -1;
  }

  .hk-row-fluid {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    gap: 1rem;
    // margin-top: 80px;
    // padding: 1rem;

    &::before,
    &::after {
      content: none;
    }
  }
  // ====================================== HEADER LISTING
  .hika__listing__header {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-flow(row wrap);
    margin-bottom: 1rem;
    gap: 0.5rem;

    .listing__header__title {
      font-weight: 600;
      text-transform: uppercase;
      font-size: clamp(0.8rem, 1vw, 1rem);
    }

    select {
      width: auto !important;
    }
  }
  // ====================================== EO HEADER LISTING

  .hika__price__infos {
    display: none;
  }
}

// ======================================================================================= PAGE HOME

.page-home {
}

.title__main--hika {
  margin-top: var(--section);
}

#hikashop_module_167,
#hikashop_module_213 {
  max-width: var(--container);
  margin: auto;
  .hk-row-fluid {
    @include justify-content(center);
    // @include flexbox;
    // gap: 1rem;
  }

  .hikashop_product {
    max-width: 325px;
  }
}

// ======================================================================================= EO PAGE HOME

// ======================================================================================= PAGE LISTING

.list__hero {
  margin-top: 0.5rem;
}

.list__hero__container {
  position: relative;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  &::before {
    content: "";
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.16);
  }

  // --------------------------------- image
  .list__hero__img {
    display: block;
    position: relative;
    width: 100%;
    top: -250px;
    height: 600px;
    object-fit: cover;
    object-position: center;
  }
  // --------------------------------- titre
  .hero__title {
    position: absolute;
    z-index: 100;
    font-size: rem(60);
    font-family: var(--font-titre);
    color: var(--white);
    text-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// ------------------------------------------------ CONTAINER

// --------------- Sur page Home
// #hikashop_category_information_menu_101.hikashop_products_listing_main {
//   display: block;
// }
// #hikashop_filter_main_div_hikashop_category_information_menu_101.hikashop_filter_main_div {
//   display: none !important;
// }

.page-produits .hikashop_products_listing_main {
  --filter-width: 280px;
  position: relative;
  display: grid;
  grid-template-columns: minmax(240px, var(--filter-width)) minmax(500px, 1fr);
  padding: 0;
  gap: 0 2rem;
  align-items: flex-start;

  .category__filter {
    // position: sticky;
    // top: 20px;
  }
}

// ========================================================== LOADING
// ----------------- > hikashop / components / loading

.hikashop_products_listing_main.hikashop_checkout_loading {
  > .hikashop_checkout_loading_elem {
    position: absolute !important;
    // left: 0;
    left: var(--filter-width) !important;
  }
  > .hikashop_checkout_loading_spinner {
    position: absolute !important;
    top: 200px !important;
    transform: translateY(0%);
  }
}

// ------------------------------------------------ FILTRE

// commponents -> filter

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
  .list__hero__container .hero__title {
    font-size: 5rem;
  }
  .page-produits .hikashop_products_listing_main {
    --filter-width: 230px;
    grid-template-columns: minmax(200px, var(--filter-width)) minmax(500px, 1fr);
  }
}

@include breakpoint(1440) {
  .list__hero__container .hero__title {
    font-size: 4.5rem;
  }
}

@include breakpoint(1380) {
  .list__hero__container .hero__title {
    font-size: 4rem;
    width: 100%;
    text-align: center;
  }
  .list__hero__container {
    // height: 250px;
  }
}

@include breakpoint(1310) {
  // .hikashop_products_listing_main {
  //   display: grid;
  //   grid-template-columns: 200px 1fr;
  //   // padding: 0;
  //   gap: 0 1rem;
  // }
}

@include breakpoint(1280) {
}

@include breakpoint(1180) {
  .list__hero {
    margin-top: 0;
  }
  .list__hero__container .list__hero__img {
    top: -100px;
    height: 400px;
  }
}

@include breakpoint(1080) {
  .page-produits .listing__certificat {
    position: relative;
    height: initial;
    display: block;
    text-align: center;
    margin: 1rem 0;
  }
  .hikashop_products_listing_main.hikashop_checkout_loading {
    > .hikashop_checkout_loading_elem {
      // display: none !important;
    }
    > .hikashop_checkout_loading_spinner {
      // display: none !important;
    }
  }
  .page-produits .hikashop_products_listing_main {
    --filter-width: 0px;
    grid-template-columns: 1fr;

    .hk-row-fluid {
      justify-content: center;
      margin-top: 1rem;
    }
  }
  .page-produits .hikashop_products_listing .hika__listing__header {
    // display: none;
  }
  .hikashop_products_listing .hk-row-fluid {
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@include breakpoint(780) {
  .hikashop_products_listing .hk-row-fluid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@include breakpoint(550) {
  .list__hero__container {
    height: 100px;
  }
  .list__hero__container .hero__title {
    font-size: 3rem;
    // left: 1rem;
    top: calc(50% - 10px);
  }
  .hikashop_products_listing .hk-row-fluid {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 0.5rem;
    // padding: 0.5rem;
  }
  .list__hero__container .list__hero__img {
    height: 250px;
  }
}

@include breakpoint(440) {
  .list__hero__container .hero__title {
    font-size: 2rem;
  }
  .hikashop_products_listing .hika__listing__header {
    // @include justify-content(center);
    gap: 0;

    .listing__header__title {
      display: block;
      width: 100%;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .nice-select.right .list {
      left: 0;
      right: auto;
    }
  }
}

@include breakpoint(320) {
  .hikashop_products_listing .hk-row-fluid {
    display: block;
  }
}
