// -------------------------------------- RECHERCHER / STATUS
.hikam_search_zone {
  display: grid;
  grid-template-columns: 1fr 300px;
  margin-bottom: 1rem;
  gap: 20px;
  &::before,
  &::after {
    content: none;
  }

  .hkc-md-12 {
    margin: 0;
    padding: 0;
  }
}
.hikamarket_search_block {
  .hk-input-group {
    width: 100%;
  }
  // ------------------- boutons rechercher / close
  .hk-input-group-append {
    display: none !important;
  }
}
// ------------------- trier
.hikam_sort_zone {
  //   display: none;
}

#hikamarket_order_listing_search {
  width: 100% !important;
}

// -------------------------------------- EO RECHERCHER / STATUS

#hikam_order_main_listing {
  margin-top: 2rem;
}

// -------------------------------------- CARD

.hk-card {
  // border: none !important;
  // box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.08);
  box-shadow: none;
  border: 1px solid var(--gray-4);
  border-radius: 10px !important;
  transition: all 0.2s;
  background-color: var(--light) !important;
  //   overflow: hidden;

  &:hover {
    box-shadow: 0 16px 32px var(--gray-1);
  }
}

.hk-card-header {
  // @include flexbox;
  // @include align-items(center);
  // @include justify-content(space-between);
  // display: grid;
  // grid-template-columns: 1fr 100px;
  border: none !important;
  border-radius: 10px 10px 0 0 !important;
  background-color: var(--gray-1) !important;
  color: var(--dark) !important;

  .hk-row-fluid {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
  }
  .hika_cpanel_price {
    text-align: right;
  }

  .hika__header__left {
    @include flexbox;
    @include align-items(center);
    gap: 20px;
  }

  .hkm_order_price,
  .hkm_product_price {
    font-weight: bold;
  }
}

.hk-card-body .hk-row-fluid {
  @include flex-flow(row wrap);
  @include align-items(center);

  .hika_order_number_value,
  .hika_invoice_number_value,
  .hkm_order_number a {
    font-weight: 500;
    color: var(--color-2);
  }

  .hkm_order_status,
  .hika_order_status {
    text-align: center;
  }

  .btn i {
    margin-right: 4px;
  }

  .hkm_product_code {
    display: none;
  }
}

// -------------------------------------- EO CARD

// --------------------------------------------- STATUS
.hika_order_status {
  .order-label {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    padding: 2px 7px;
    font-size: 0.8rem;
    //   text-transform: uppercase;
    border-radius: 50px;
    font-weight: normal;
  }
  .order-label-created {
    border-color: var(--success);
    background-color: var(--success);
  }

  .order-label-confirmed {
    border-color: var(--success);
    background-color: var(--success);
    color: var(--white);
  }

  .order-label-cancelled,
  .order-label-refunded {
    border-color: var(--error);
    background-color: var(--error);
    color: var(--white);
  }

  .order-label-shipped {
    border-color: #ffe900;
    background-color: #ffe900;
    color: #7a7a7a;
  }

  .order-label-pending {
    border-color: var(--warning);
    background-color: var(--warning);
    color: var(--white);
  }
}
// --------------------------------------------- EO STATUS

@include breakpoint(767) {
  .hikam_search_zone {
    grid-template-columns: 1fr;
    gap: 5px;
  }
  .hk-card-body .hk-row-fluid {
    text-align: center;
    gap: 15px;
  }
  #hikam_product_listing .hk-card-header {
    grid-template-columns: 1fr 90px;

    .btn {
      font-size: 0.8rem;
    }
  }
}

@include breakpoint(550) {
  .hk-card-product .hika__header__left {
    display: block;
  }
}

@include breakpoint(440) {
  .hk-card-header {
    display: block;

    .btn {
      margin-top: 5px;
    }
  }
}
