// ============================================= LISTING
#hikamarket_plugin_listing {
  margin: 2rem 0;

  .hk-row-fluid {
    display: grid;
    grid-template-columns: 1fr 300px;
    &::before,
    &::after {
      content: none;
    }
  }

  #hikam_shipping_main_listing {
    .hk-row-fluid {
      display: flex;
      &::before,
      &::after {
        content: "";
      }
    }
  }
  .hkm_plugin_name {
    color: var(--color-2);
    font-size: 1.1rem;
  }
  // ---------------------- type de méthode
  .hkm_plugin_type {
    display: none;
  }
  // ---------------------- nom du vendeur
  .hkm_plugin_detail_vendor {
    display: none;
  }
}

// ---------------------- input recherche
#hikamarket_plugins_listing_search {
  display: none;
}

#hikam_shipping_main_listing {
  margin-top: 2rem;
}

.adminlist.table.table-striped {
  th.titlenum,
  th.title {
    display: none;
  }
  tr td:first-child {
    padding: 10px;
    display: none;
  }
  td a {
    padding: 10px;
    background-color: var(--color-2);
    color: var(--white);
    border-radius: 5px;
  }
}

@include breakpoint(768) {
  #hikamarket_plugin_listing .hk-row-fluid {
    display: block;
  }
}
