#hikamarket_products_form,
#hikamarket_section_product_description {
  max-width: 780px;
}

.hika_product_edit_header {
  max-width: 980px;
}

.admin--product {
  .hikam_toolbar {
    position: sticky;
    z-index: 10;
    top: 0;
    background-color: var(--admin-color-g1);
    border-bottom: 2px solid var(--gray-7);
    margin-bottom: 1rem;
    // width: calc(100% + var(--admin-padding) * 2);
    // margin-left: calc(-1 * var(--admin-padding));
  }

  .hikabtn[href="#apply"] {
    // display: none;
  }
}

#hikamarket_price_0_price,
#hikamarket_price_0_with_tax,
#product_sale_start,
#product_sale_end,
.hikamarket_product_weight input,
.hikamarket_product_weight select {
  max-width: 150px !important;
  @include inline-flex;
}

#hikamarket_products_form {
  dt {
    margin-bottom: 4px;
  }
  dd,
  .hikamarket_section_container {
    margin-bottom: 1.5rem;
  }

  .hika_product_edit_message {
    display: block;
    font-size: 0.8rem;
    margin-left: 5px;
    font-weight: 400;
  }

  // ---------------------------------------------- IMAGE

  #hikamarket_product_image_main {
    margin-bottom: 1.5rem;
  }

  .hikamarket_main_image_div,
  .hikashop_main_image_div {
    padding: 5px;
    margin-bottom: 1rem;
    div.hikashop_uploader_image_add,
    div.hikashop_uploader_file_add {
      background-color: var(--gray-7);
      border-radius: 10px 10px 0 0;
      position: relative;
      display: block;
      top: 0;
      right: initial;
      padding: 10px 5px;
      margin-bottom: 10px;
    }
    .hika_j2 .btn-primary,
    .hikabtn-primary,
    .hika_j2 .btn-primary:hover,
    .hikabtn-primary:hover {
      background-color: var(--color-2) !important;
      border: 0 !important;
      margin-left: 0;
    }

    // Bouton ajouter photo via Url et liste images
    #hikamarket_product_image_addpopup,
    #hikamarket_product_image_urlpopup {
      display: none;
    }

    #hikamarket_product_image_uploadpopup {
      &::before {
        content: "Télécharger l'image";
        margin-right: 7px;
      }
    }

    .hikashop_uploader_multiimage_content {
      img {
        width: 100px;
        height: 60px;
        object-fit: cover;
        object-position: center;
        image-rendering: pixelated;
      }
    }

    #hikamarket_product_image_content {
      @include flexbox;
      @include align-items(center);
      gap: 5px;
    }

    .deleteImg {
      display: block !important;
      top: 2px !important;
      right: 2px !important;
      width: 25px;
      opacity: 1 !important;
      color: var(--color-2) !important;
      text-shadow: 0 2px 4px rgba($color: #ffffff, $alpha: 0.5);
      background-color: var(--light);
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      font-size: 0.8rem;
    }
  }

  // ---------------------------------------------- EO IMAGE

  //   --------------------------- h3 (prix, description, ...)
  .hikamarket_section_toggle {
    display: none;
  }

  //   --------------------------- qty
  .hkm_quantity_input {
    @include inline-flex;
    @include align-items(center);

    .hikabtn {
      height: 60px;
    }
    .hikabtn:focus,
    .hika_j2 .btn:focus {
      border-color: transparent;
    }
  }

  //   --------------------------- eo qty

  //   --------------------------- price

  #hikamarket_section_product_prices {
    @include flexbox;
    @include flex-flow(row wrap);
  }

  #hikamarket_price_0_price,
  .icon__currency {
    // display: none;
  }

  dd.hikamarket_product_price {
    width: 200px;
    display: none;
  }

  .hikamarket_section_product_prices {
    .hika_product_edit_price_details_tax {
      // display: none;
    }
  }

  .hika_product_price_ttc {
    @include inline-flex;
    // width: 150px;
    @include align-items(center);
    border: 1px solid var(--gray-7);
    padding: 5px 10px;
    margin-top: 4px;
  }

  .hika_product_price_ttc_title {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-right: 6px;
  }

  #hikamarket_price_0_with_tax_span {
    font-weight: bold;
  }
  .hikamarket_product_tax {
    width: 150px;
  }

  //   --------------------------- eo price

  //   --------------------------- catégory

  dd.hikamarket_product_category {
    max-width: 550px;
    border-bottom: 3px solid var(--gray-7);
    background-color: var(--gray-7);
    padding: 1rem;
  }

  // --------------------- produit du terroir caché
  #data_product_categories-21 {
    display: none;
  }

  #data_product_categories {
    position: relative;

    .nametext {
      // display: inline-flex;
      max-width: 240px;
      height: 60px;
      position: relative;
      cursor: pointer;
      margin-top: var(--gap);
      &::after {
        content: "Sélectionnez une catégorie";
        background-color: var(--color-2);
        border-radius: 5px;
        color: var(--white);
        position: absolute;
        padding: 10px;
        left: 0;
        top: 0;
      }
    }

    #data_product_categories-21 {
      // display: none;
    }

    #data_product_categories_text {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 0 !important;
      height: 0 !important;
    }
  }
  #data_product_categories_otree {
    #data_product_categories_j1,
    #data_product_categories_i1,
    #data_product_categories_s1,
    img,
    .e1 {
      display: none !important;
    }
    #data_product_categories_d1 {
      @include flexbox;
      @include flex-flow(row wrap);
      // padding: 8px;
    }

    .node {
      display: block;
      font-size: 1.1rem;
      padding: 6px 12px;
      margin: 0 10px 5px 0;
      background-color: var(--light);
      font-family: var(--font-normal);
      border-radius: 7px;
      box-shadow: 0 8px 16px rgba($color: #000000, $alpha: 0.08);
      &:hover {
        text-decoration: none !important;
        background-color: var(--color-2);
        color: var(--white);
      }
    }
  }

  //   --------------------------- eo catégory

  //   --------------------------- fabricant

  .hikamarket_product_manufacturer {
    display: none;
  }

  //   --------------------------- eo fabricant

  //   --------------------------- disponibilité

  .field-calendar input {
    max-width: 200px !important;
  }

  dd.hikamarket_product_salestart {
    // @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    gap: 30px;

    // .btn {
    //   padding: 10px;
    //   width: 50px;
    // }
    .js-btn.btn {
      font-size: 0.8rem;
      padding: 4px 8px;
      margin-right: 7px;
    }
  }

  //   --------------------------- eo disponibilité

  //   --------------------------- weight

  #dataproductproduct_weight_unit {
    max-width: 70px !important;
    margin-left: 4px;
  }

  //   --------------------------- eo weight

  //   --------------------------- publication

  #data_product_product_published label {
    margin: 0;
  }

  //   --------------------------- eo publication

  //   --------------------------- description

  #hikamarket_section_product_description {
    max-width: 700px;
  }

  .hika_product_edit_body {
    #jform_articletext_ifr {
      height: 300px !important;
    }
  }

  //   --------------------------- eo description

  //   --------------------------- sélection
  .namebox {
    @include inline-flex;
    @include align-items(center);
    padding: 5px 10px;
    box-shadow: 0 4px 8px rgba($color: #000000, $alpha: 0.08);
    background-color: var(--light);
    border-radius: 7px;
    margin: 4px;

    &#data_product_categories-21 {
      // display: none;
    }

    .closebutton {
      color: var(--white);
      background-color: var(--error);
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      font-size: 0.7rem;
      font-weight: 500;
      margin-left: 6px;
    }
  }

  //   --------------------------- eo sélection
}

//   --------------------------- délai de livraison
h3.hikamarket_section_toggle {
  display: none;
}

#hikamarket_section_product_fields {
  margin-top: 1.5rem;
}

#hikashop_product_product_delay label::after {
  content: "Non obligatoire mais recommandé";
  display: block;
  font-size: 0.8rem;
  margin-left: 5px;
  font-weight: 400;
}

dd.hikamarket_product_product_delay {
  max-width: 180px;
}
//   --------------------------- eo délai de livraison

@include breakpoint(980) {
}

@include breakpoint(550) {
  #hikamarket_products_form .hkm_quantity_input .hikabtn {
    height: 50px;
  }
}

@include breakpoint(450) {
  #hikamarket_section_product_prices .hikam_options:first-of-type {
    @include order(2);
  }
}
