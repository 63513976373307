.hikashop_product_waitlist_page {
  padding: 2rem;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 10px 21px var(--gray-1);
  border-radius: 10px;
  margin: auto;
  .hikashop_product_waitlist_title {
    font-size: 1.3rem;
    margin-bottom: 2rem;

    a {
      font-weight: bold;
      color: var(--color-2);
      text-decoration: underline;
    }
  }

  .toolbar {
    text-align: center;
    // margin: auto;
    float: none !important;
  }
}
