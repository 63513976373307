.loading {
  background-color: var(--gray-4);
  width: 710px;
  min-height: 30px;
  height: 60px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.loading::before {
  content: "";
  position: absolute;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, #e3e3e3, transparent);
  transform: translateX(-100%);
  animation: loading 1s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
