html {
  font-size: $font-size-base;
}

body {
  font-family: var(--font-normal);
  line-height: 1.5;
  color: var(--dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizelegibility;
}

/*
************* TITRE
* ===================================
*/

.title__h1,
h1 {
  font-family: var(--font-titre);
  line-height: 1;
  font-size: var(--h1);
}
.title__h2,
h2 {
  display: block;
  // color: var(--color-1);
  font-family: var(--font-titre);
  font-size: 1.8rem;
  font-size: var(--h2);
  line-height: 1;
  margin-bottom: calc(var(--gap) * 2);
}
// ------------ EO H2
h3 {
  font-family: var(--font-titre);
  font-weight: normal;
  font-size: 1.5rem;
  font-size: var(--h3);
  line-height: 1;
  margin-bottom: 1rem;
}

h4 {
  font-size: var(--h4);
  line-height: 1.5;
}

h5 {
  font-size: var(--h5);
  line-height: 1.5;
}

h6 {
  font-size: var(--h6);
  line-height: 1.5;
}

.title__main {
  position: relative;
  text-align: center;
  margin-bottom: rem(48);

  .title {
    font-family: var(--font-titre);
    font-size: var(--h2);
    line-height: 1.3;
    font-weight: normal;
    text-align: center;
    &::after {
      content: "";
      display: block;
      margin: rem(0) auto rem(8) auto;
      width: 87px;
      height: 15px;
      background-image: url("/images/deco-title.svg");
      background-size: cover;
    }
  }
  .title__slogan {
    display: block;
    font-size: rem(14);
    color: var(--color-1);
    text-transform: uppercase;
  }
  .title__overlay {
    position: absolute;
    z-index: 0;
    left: -30%;
    top: clamp(1rem, 5vw, 5rem);
    font-size: clamp(5rem, 20vw, 20rem);
    color: var(--opacity-3);
    text-transform: uppercase;
    line-height: 0;
    white-space: nowrap;
  }
}
.title__chalk--h1 {
  position: relative;
  display: block;
  margin-bottom: 3rem;
  text-align: center;
  color: var(--white);
  font-size: rem(70);
  line-height: 1.1;
  font-family: var(--font-chalk);
}

.chalk--p {
  font-family: var(--font-chalk);
  color: var(--white);
  font-size: rem(27);
  line-height: 1.5;
  text-align: center;
}

/*
************* TEXTE
* ===================================
*/

p {
  font-size: var(--text);
  line-height: 1.7;
  font-weight: normal;
}

li {
  // font-size: var(--text);
  // line-height: 1.7;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    //color: var(--color-1);
    //transition: color 200ms ease-in-out;
  }
}

address {
  font-style: normal;
}

figcaption {
  color: var(--dark);
  font-weight: 300;
  font-size: rem(14);
  padding-left: 5px;
  margin-top: var(--gap);
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
  .title__main {
    margin-bottom: rem(46);
  }
}

@include breakpoint(1600) {
  .title__chalk--h1 {
    font-size: rem(65);
  }
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  .chalk--p {
    font-size: rem(24);
    line-height: 1.3;
  }
}

@include breakpoint(980) {
  .title__chalk--h1 {
    font-size: rem(55);
  }
}

@include breakpoint(780) {
  .title__chalk--h1 {
    font-size: rem(50);
  }
}

@include breakpoint(550) {
  .title__main {
    margin-bottom: rem(30);
  }
  .title__chalk--h1 {
    font-size: rem(36);
    margin-bottom: 2rem;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
