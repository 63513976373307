.hikamarket_vendor_edit_page {
  max-width: 980px;

  .hikamarket_vendor_email_line,
  #hikamarket_vendor_vendor_address_telephone {
    label::after {
      content: "(Non visible sur le site)";
      margin-left: 5px;
    }
  }

  // ================================================================================= HEADER
  .header__edit__page {
    margin: 2rem 0;
    @include flexbox;
    @include flex-flow(row wrap);
    @include align-items(center);
    gap: 10px;

    .btn {
      box-shadow: 0 10px 21px rgba($color: #000000, $alpha: 0.26);
    }

    .btn--stripe,
    .btn--my-page {
      i {
        margin-left: 1rem;
      }
    }
  }

  .shar .stripe__connexion {
    margin: 1rem 0;
  }

  .share__facebook {
    margin: 1rem 0;

    a {
      color: var(--white);
      background-color: #2962ff;

      i {
        margin-right: 7px;
      }
    }
  }

  // ================================================================================= EO HEADER

  // --------------- image

  #hikamarket_vendor_image_addpopup {
    display: none;
  }

  #hikamarket_vendor_image_uploadpopup,
  #vendor_image_exploitation_uploadpopup {
    &::before {
      content: "Télécharger une image";
      margin-right: 7px;
    }
  }
  .fa-stack {
    display: block;
    height: auto;
    line-height: 20px;
    width: auto;
  }

  .hikamarket_vendor_description_line label {
    &::after {
      content: "(Votre histoire)";
    }
  }
  #jform_articletext_ifr {
    min-height: 300px;
  }
}
