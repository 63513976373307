// ------------------------------------------------------------------------------ STEP

.hika__step {
  // padding-top: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  ul {
    @include flexbox;
    @include justify-content(center);
    gap: 10vw;
  }

  li {
    font-family: var(--font-titre);
    font-size: 1.8rem;
  }

  .step__icon {
    position: relative;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    margin: 0 auto 10px auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid var(--gray-7);
    color: var(--gray-7);
    background-color: var(--light);
    font-size: 2.2rem;
  }

  .step__icon--current::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid white;
  }

  .step__icon--current,
  .step__icon--finished {
    background-color: var(--color-2);
    color: var(--white);
    border-color: var(--color-2);
  }

  //   ---------------- line
  .step--next .step__icon::before,
  .step--finished .step__icon::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    width: 14vw;
    height: 10px;
  }

  .step--next .step__icon::before {
    right: 100%;
    background-color: var(--gray-7);
  }
  .step--finished .step__icon::before {
    left: 100%;
    background-color: var(--color-2);
  }

  //   ---------------- eo line

  .step--finished a {
    color: var(--color-2);
  }
}

@include breakpoint(1440) {
  .hika__step {
    padding-top: 1rem;
  }
  .hika__step li {
    font-size: 1.7rem;
  }
  .hika__step .step__icon {
    font-size: 1.75rem;
    margin-bottom: 4px;
    width: 70px;
    height: 70px;
  }
}

@include breakpoint(980) {
  .hika__step ul {
    // @include justify-content(flex-end);
    // margin-right: 2rem;
  }
}

@include breakpoint(550) {
  .hika__step {
    // padding-top: 7rem;
    margin-bottom: 2rem;
  }
  .hika__step ul {
    @include justify-content(center);
    margin-right: 0;
    margin-top: 1rem;
  }
  .hika__step li {
    font-size: 1.5rem;
  }
  .hika__step .step__icon {
    font-size: 1.2rem;
    margin-bottom: 4px;
    width: 50px;
    height: 50px;
  }
  .hika__step .step--next .step__icon::before,
  .hika__step .step--finished .step__icon::before {
    width: 20vw;
  }
}

// ------------------------------------------------------------------------------ EO STEP
