.product__configurator {
  position: fixed;
  background-color: var(--light);
  z-index: 100;
  top: var(--header-height);
  left: -100%;
  width: 100%;
  max-width: 680px;
  height: 100%;
  border-right: 1px solid var(--gray-3);
  box-shadow: 10px 20px 41px var(--gray-2);

  &.active {
    left: var(--header-width);
    transition: 0.15s;
  }
}

// ============================== wrapper + bouton close
.configurator__wrapper {
  position: relative;
  padding: 2rem;
  height: calc(100vh - var(--header-height));
  height: calc((var(--vh, 1vh) * 100) - var(--header-height));
  overflow: auto;

  .configurator__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.7rem;
    font-size: clamp(2rem, 2.5vw, 2.5rem);
    color: var(--color-1);
    cursor: pointer;
  }
}
// ============================== eo wrapper + bouton close

// ============================== item
.configurator__item {
  border-bottom: 1px solid var(--gray-2);

  &.item--genre,
  &.item--matiere {
    opacity: 0.15;
    transition: opacity 0.2s;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: unset;
    }
  }

  .item--lancement {
    display: none;
    position: relative;

    label {
      opacity: 0.15;
      pointer-events: none;
    }

    span.lancement__title {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.66rem;
      white-space: nowrap;
      text-align: center;
      font-weight: 500;
    }
  }

  .items {
    margin: 1rem 0;
    @include flexbox;
    // @include align-items(center);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    gap: 0.5rem;
  }

  .item {
    margin: 0.5rem 0;
    input {
      display: none;
    }
    label {
      @include flexbox;
      @include flex-flow(column wrap);
      cursor: pointer;
      padding: 0.4rem;
      border-radius: 8px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin: 0;
      &:hover {
        background-color: var(--gray-2);
      }
    }
    svg {
      display: block;
      margin: auto;
      height: 40px;
      path {
        fill: var(--dark);
      }
    }
    .item__name {
      display: block;
      margin-top: 0.3rem;
      text-align: center;
      font-size: 1rem;
      font-size: clamp(0.8rem, 0.9vw, 0.9rem);
      min-height: 40px;
      // @include flexbox;
      // @include align-items(center);
      // @include justify-content(center);
    }

    input:checked + label {
      background-color: var(--gray-7);
      box-shadow: 4px 8px 17px var(--gray-5);
      color: white;
      path {
        fill: var(--white);
      }
    }
  }

  .item__title {
    @include flexbox;
    @include align-items(center);
    gap: 0.8rem;
    margin: 1rem 0;
  }

  .title__count {
    // @include inline-flex;
    // @include align-items(center);
    // @include justify-content(center);
    display: block;
    text-align: center;
    width: 1.8rem;
    min-width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
    // width: clamp(2rem, 2vw, 2rem);
    // height: clamp(2rem, 2vw, 2rem);
    // line-height: clamp(2rem, 3vw, 2rem);
    background-color: var(--color-1);
    color: var(--white);
    // padding: 0.5rem 1rem;
    border-radius: 100px;
    font-size: 1rem;
    font-size: clamp(1rem, 1.2vw, 1.2rem);
  }

  .title__value {
    font-size: 1rem;
    font-size: clamp(1rem, 1.3vw, 1.3rem);
    font-weight: 500;
  }

  .title__choice {
    color: var(--color-1);
    text-decoration: underline;
    font-weight: 500;
  }

  .btn__modify__configurator {
    display: none;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }

  // ============================== genre
  .items--genre {
    .item__name {
      display: block;
    }
    .item__name::before {
      content: "";
      display: block;
      margin: auto;
      width: 70px;
      height: 70px;
      border-radius: 50px;
      background-color: var(--color-1);
      background-size: cover;
      margin-bottom: 0.3rem;
    }

    .item--femme .item__name::before {
      background-image: url("../../../images/categories/icons/femme.png");
    }

    .item--homme .item__name::before {
      background-image: url("../../../images/categories/icons/homme.png");
    }

    .item--enfant .item__name::before {
      background-image: url("../../../images/categories/icons/enfant.png");
    }

    input:checked + label .item__name::before {
      background-color: var(--light);
    }
  }

  // ============================== eo genre

  // ============================== matiere

  .items--matiere {
    img {
      display: block;
      margin: auto;
      height: 50px;
      width: 50px;
      object-fit: cover;
    }
  }

  // ============================== eo matiere
}

.configurator__footer {
  text-align: center;

  .configurator__result {
    // display: none;
  }

  .btn--configurator {
    // margin-top: 1rem;
  }
}

.configurator__result {
  margin: 1rem 0;
  font-size: 1.1rem;
  font-size: clamp(0.9rem, 1.3vw, 1.3rem);
  opacity: 0;

  .result__value {
    font-weight: bold;
  }

  &.active {
    opacity: 1;
    transition: opacity 0.2s;
  }
}

.btn--configurator {
  pointer-events: none;
  opacity: 0.3;

  &.active {
    pointer-events: initial;
    opacity: 1;
    transition: opacity 0.2s;
  }
}

@include breakpoint(1180) {
  .product__configurator {
    max-width: 100%;
    &.active {
      z-index: 1001;
    }
  }
}

@include breakpoint(440) {
  .configurator__wrapper {
    padding: 2rem 0.7rem 3rem 0.7rem;
  }
  .configurator__item .item__title {
    gap: 0.6rem;
  }
  .configurator__item .items {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
}
